import '../../scss/razaDetail/razaDetail.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { Modal } from '../modal'
import { getRazaByID } from '../../api/razaAPI'
import { userUpdate, decodeToken, getUserByID } from '../../api/userAPI'
import { getCookie } from '../../funciones/token'
import { FaRegHeart, FaHeart } from 'react-icons/fa'
import Swal from 'sweetalert2'

import barra1 from '../../assets/barras/1.webp'
import barra2 from '../../assets/barras/2.webp'
import barra3 from '../../assets/barras/3.webp'
import barra4 from '../../assets/barras/4.webp'
import barra5 from '../../assets/barras/5.webp'



function RazaDetail({id}) {
    const [token, setToken] = useState(getCookie('token'))
    const [userID, setUserID] = useState('')
    const [exist, setExist] = useState('')
    const [allFavsState, setAllFavsState] = useState([])
    const [favIcon, setFavIcon] = useState('')

    const [reboot, setReboot] = useState(true)
    const [modalIMG, setModalIMG] = useState(null)

    const [raza, setRaza] = useState('')
    const [descript, setDescript] = useState('')
    const [country, setCountry] = useState('')
    const [years, setyears] = useState('')
    const [weight, setWeight] = useState('')
    const [family, setFamily] = useState('')
    const [strange, setStrange] = useState('')
    const [otherPets, setOtherPets] = useState('')
    const [play, setPlay] = useState('')
    const [meow, setMeow] = useState('')
    const [intelligence, setIntelligence] = useState('')
    const [IMG1, setIMG1] = useState('')
    const [IMG2, setIMG2] = useState('')
    const [IMG3, setIMG3] = useState('')






    // useEffect para traer los datos de la raza seleccionada
    useEffect(() => {

        getRazaDetail()
        async function getRazaDetail() {
            const getRaza = await getRazaByID(id)
            
            setRaza(getRaza.data.raza)
            setDescript(getRaza.data.descript)
            setCountry(getRaza.data.country)
            setyears(getRaza.data.years)
            setWeight(getRaza.data.weight)

            setFamily(await syncBarra(getRaza.data.family_friendly))
            setStrange(await syncBarra(getRaza.data.stranger_friendly))
            setOtherPets(await syncBarra(getRaza.data.other_pets_friendly))
            setPlay(await syncBarra(getRaza.data.playfulness))
            setMeow(await syncBarra(getRaza.data.meowing))
            setIntelligence(await syncBarra(getRaza.data.intelligence))

            setIMG1(getRaza.data.img[0])
            setIMG2(getRaza.data.img[1])
            setIMG3(getRaza.data.img[2])
            
        }

    }, [id])


    

    // Funcion para sincronizar el numero de la caracteristica con el numero de la imagen de barra
    async function syncBarra(num) {
        if (num == 1) {
            return barra1
        } else if (num == 2) {
            return barra2
        } else if (num == 3) {
            return barra3
        } else if (num == 4) {
            return barra4
        } else {
            return barra5
        }
    }




    // useEffect para revisar si es un usuario logueado y por tanto que aparezca un boton de favoritos
    useEffect(() => {

        if (reboot) {

            findFavsByUser()
            async function findFavsByUser() {

                // Condicional para comprobar si hay usuario logueado y si tiene o no en fav la raza vista
                if (token != undefined) {
                    const tokenDecode = await decodeToken()
                    setUserID(tokenDecode.data.id)
                    const getUser = await getUserByID(tokenDecode.data.id)

                    const allFavs = getUser.data.favs
                    setAllFavsState(allFavs)

                    // Buscar si entre los favoritos existe ya esta raza
                    const allFavsSome = allFavs.some(data => data == id)
                    setExist(allFavsSome)
                    allFavsSome ? setFavIcon(<FaHeart />) : setFavIcon(<FaRegHeart />)
                }     
            }   
        }   
        setReboot(false)  
    }, [reboot])







    // Funcion para quitar o añadir una nueva raza a favs
    async function addFav(e) {

        if (exist) { // En el caso de que ya este y se deba quitar
            const newArrayFavs = allFavsState.filter(data => data !== id)
            const updateUserFavs = await userUpdate(userID, {'favs': newArrayFavs})

            Swal.fire({
                html: `La raza <b>${raza}</b> ha sido eliminada de favoritos`,
                icon: "success"
              })

        } else { // Si aun no esta y se quiere añadir
            allFavsState.push(id)
            const updateUserFavs = await userUpdate(userID, {'favs': allFavsState})

            Swal.fire({
                html: `La raza <b>${raza}</b> ha sido añadida a favoritos`,
                icon: "success"
              })
        }
        setReboot(true)
    }

    







    return (

        <div id="razaDetailUni-body">

            <div id='razaDetailUni-div'>

                <div id="razaDetailUni-div-headerRotulo">
                    <p id='razaDetailUni-div-rotulo'> {raza} </p>
                    <p id='razaDetailUni-div-fav' onClick={addFav}> {favIcon} </p>
                </div>

                <div id="razaDetailUni-div-img">
                    { IMG1 != '' ? <img src={IMG1} onClick={() => {setModalIMG(`${IMG1}`)}}/> : <></> }
                    { IMG2 != '' ? <img src={IMG2} onClick={() => {setModalIMG(`${IMG2}`)}}/> : <></> }
                    { IMG3 != '' ? <img src={IMG3} onClick={() => {setModalIMG(`${IMG3}`)}}/> : <></> }
                </div>

                <p id='razaDetailUni-div-descript'> Origen: {country} <br /> <br /> {descript} </p>

                <div id="razaDetailUni-div-others">
                    <div className='razaDetailUni-div-line'> <p className='razaDetailUni-div-dato'><b>Esperanza de vida:</b> {years} años</p> </div>
                    <div className='razaDetailUni-div-line'> <p className='razaDetailUni-div-dato'><b>Peso promedio:</b> {weight} Kg</p> </div>
                    <div className='razaDetailUni-div-line'> <p className='razaDetailUni-div-dato'><b>Trato con familia:</b> </p> <img className='razaDetailUni-div-barra' src={family} /> </div>
                    <div className='razaDetailUni-div-line'> <p className='razaDetailUni-div-dato'><b>Trato con extraños:</b> </p> <img className='razaDetailUni-div-barra' src={strange} /> </div>
                    <div className='razaDetailUni-div-line'> <p className='razaDetailUni-div-dato'><b>Trato con animales:</b> </p> <img className='razaDetailUni-div-barra' src={otherPets} /> </div>
                    <div className='razaDetailUni-div-line'> <p className='razaDetailUni-div-dato'><b>Jugueton:</b> </p> <img className='razaDetailUni-div-barra' src={play} /> </div>
                    <div className='razaDetailUni-div-line'> <p className='razaDetailUni-div-dato'><b>Maullador:</b> </p> <img className='razaDetailUni-div-barra' src={meow} /> </div>
                    <div className='razaDetailUni-div-line'> <p className='razaDetailUni-div-dato'><b>Inteligencia:</b> </p> <img className='razaDetailUni-div-barra' src={intelligence} /> </div>
                </div>



                {modalIMG &&
                    <Modal isOpen={true}>

                        <div id="modalIMG-div" onClick={() => {setModalIMG(null)}}>
                            <img src={`${modalIMG}`} />
                        </div>

                    </Modal>
                }



            </div>

        </div>

    )
}


export {
    RazaDetail
}