import '../../../scss/gestion/razas/listRazas.scss'

import { API_URL } from '../../../api/API'
import React from 'react'
import { useState, useEffect } from 'react'
import { getRaza, getRazaByID, razaDelete, razaUpdate } from '../../../api/razaAPI'
import { Modal } from '../../modal'
import Swal from 'sweetalert2'
import { deleteFile } from '../../../funciones/deleteMulter'
import { uploadFile } from '../../../funciones/uploadMulter'
import { validImageTypes } from '../../../funciones/resizeIMG'



function ListRazasGestion() {
    const [reboot, setReboot] = useState(true)
    const [dataRaza, setDataRaza] = useState('')

    const [final, setFinal] = useState(20)
    const [total, setTotal] = useState(0)

    const [modalID, setModalID] = useState('')
    const [modalRaza, setModalRaza] = useState('')
    const [modalDescript, setModalDescript] = useState('')
    const [modalCountry ,setModalCountry] = useState('')
    const [modalYears ,setModalYears] = useState('')
    const [modalWeight ,setModalWeight] = useState('')
    const [modalFamily ,setModalFamily] = useState('')
    const [modalStrange ,setModalStrange] = useState('')
    const [modalPet ,setModalPet] = useState('')
    const [modalPlay ,setModalPlay] = useState('')
    const [modalMeow ,setModalMeow] = useState('')
    const [modalIntelligence ,setModalIntelligence] = useState('')

    const [modalUpdate, setModalUpdate] = useState(null)






    // useEffect para sacar todas las razas, meterlas en un map y mostrarlas en el return
    useEffect(() => {
        if(reboot) {

            getAllRazas()
            async function getAllRazas() {
                const razas = await getRaza()

                // Separar la informacion en bloques de 20 para hacer scroll infinito
                setTotal(razas.data.length)
                const nextItems = razas.data.slice(0, final)

                const razasMap = nextItems.map((data) => 
                    <div id="razasMap-div" key={data._id}>
                        <p id='razasMap-name'>{data.raza}</p>
                        <input className='razasMap-button razasMap-button-id' id={data._id} type="button" value="ID" onClick={showID} />
                        <input className='razasMap-button razasMap-button-update' id={data._id} type="button" value="Modificar" onClick={ () => {razasMapUpdate(data)}} />
                        <input className='razasMap-button razasMap-button-delete' id={data._id} type="button" value="Eliminar" onClick={RazasMapDelete} />
                    </div>
                )
                setDataRaza(razasMap)
                setReboot(false)
            }

        }
    }, [reboot])





    // Funcion de scroll infinito para detectar cuando llega al final de la pagina
    window.addEventListener('scroll', () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight + 250) {
            if (total > final) {
                setFinal(prevFinal => prevFinal + 20)
                setReboot(true)
            }
        }
    })





    // Funcion para mostrar el ID de la raza seleccionada
    async function showID(e) {
        const buttonID = e.target.id
        const buttonName = e.target.previousElementSibling.textContent
        
        Swal.fire({
            text: `El ID de la raza ${buttonName} es ${buttonID}`,
            icon: "info"
          });
    }








    // Funcion para abrir el modal para modificar los datos de las razas
    async function razasMapUpdate(data){
        setModalID(data._id)
        setModalRaza(data.raza)
        setModalDescript(data.descript)
        setModalCountry(data.country)
        setModalWeight(data.weight)
        setModalYears(data.years)
        setModalFamily(data.family_friendly)
        setModalStrange(data.stranger_friendly)
        setModalPet(data.other_pets_friendly)
        setModalPlay(data.playfulness)
        setModalMeow(data.meowing)
        setModalIntelligence(data.intelligence)

        setModalUpdate(data)
    }








    // Funcion para modificar los datos de una raza
    async function updateRazaButton(data) {
        const modalIMG1 = document.getElementById('img1')
        const modalIMG2 = document.getElementById('img2')
        const modalIMG3 = document.getElementById('img3')

        let newIMG1 = modalUpdate.img[0]
        let newIMG2 = modalUpdate.img[1]
        let newIMG3 = modalUpdate.img[2]

        // Condicionales para comprobar que solo sean imagenes
        if (modalIMG1.files[0] != null && modalIMG1.files[0] != undefined) {
            if (!validImageTypes(modalIMG1.files[0].type)) {
                modalIMG1.value = ''
                Swal.fire({
                    text: "Solo se permiten adjuntar imagenes",
                    icon: "error"
                })
                return
            }
        }

        if (modalIMG2.files[0] != null && modalIMG2.files[0] != undefined) {
            if (!validImageTypes(modalIMG2.files[0].type)) {
                modalIMG2.value = ''
                Swal.fire({
                    text: "Solo se permiten adjuntar imagenes",
                    icon: "error"
                })
                return
            }
        }

        if (modalIMG3.files[0] != null && modalIMG3.files[0] != undefined) {
            if (!validImageTypes(modalIMG3.files[0].type)) {
                modalIMG3.value = ''
                Swal.fire({
                    text: "Solo se permiten adjuntar imagenes",
                    icon: "error"
                })
                return
            }
        }

        Swal.fire({
            text: "Una vez actualizado no se podra revertir al estado anterior",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Actualizar"
          }).then(async(result) => {
            if (result.isConfirmed) {

                // Condicionales con la opcion de subir una imagen con multer
                if (modalIMG1.files[0] != null && modalIMG1.files[0] != undefined) {
                    // Eliminar anterior imagen
                    try {
                        const filename = newIMG1.split('/').pop()
                        const removeFileIMG = await deleteFile(filename)    
                    } catch (error) {}

                    // Subir nueva imagen
                    let postIMG1 = await uploadFile(modalIMG1.files[0]);
                    newIMG1 = `${API_URL}${postIMG1.filePath}`
                }

                if (modalIMG2.files[0] != null && modalIMG2.files[0] != undefined) {
                    // Eliminar anterior imagen
                    try {
                        const filename = newIMG2.split('/').pop()
                        const removeFileIMG = await deleteFile(filename)    
                    } catch (error) {}   
                    // Subir nueva imagen
                    let postIMG2 = await uploadFile(modalIMG2.files[0]);
                    newIMG2 = `${API_URL}${postIMG2.filePath}`
                }

                if (modalIMG3.files[0] != null && modalIMG3.files[0] != undefined) {
                    // Eliminar anterior imagen
                    try {
                        const filename = newIMG3.split('/').pop()
                        const removeFileIMG = await deleteFile(filename)    
                    } catch (error) {}    
                    // Subir nueva imagen            
                    let postIMG3 = await uploadFile(modalIMG3.files[0]);
                    newIMG3 = `${API_URL}${postIMG3.filePath}`
                }       


                // Aqui va la API para actualizar
                const arrayUpdateRaza = {'raza': modalRaza,
                                        'descript': modalDescript,
                                        'country': modalCountry,
                                        'years': modalYears,
                                        'weight': modalWeight,
                                        'family_friendly': modalFamily,
                                        'playfulness': modalPlay,
                                        'meowing': modalMeow,
                                        'stranger_friendly': modalStrange,
                                        'intelligence': modalIntelligence,
                                        'other_pets_friendly': modalPet,
                                        'img': [newIMG1, newIMG2, newIMG3] }

                const updateSendRaza = await razaUpdate(modalUpdate._id, arrayUpdateRaza)

              Swal.fire({
                title: "Actualizado",
                text: "La raza ha sido actualizada correctamente",
                icon: "success"
              }).then(result => {
                setModalUpdate(null)
                setReboot(true)
              })
            }
          })
    }





    
    
    // Funcion para eliminar unicamente las fotos sin modificar el resto de datos de la raza
    async function deleteRazaIMG(data) {
        setModalUpdate(null)

        const getRazaIMG = await getRazaByID(modalID)
        let updateArrayIMG = getRazaIMG.data.img


        // Ternario para avisar cuando no hay ninguna imagen para eliminar
        if (updateArrayIMG[0] == "" && updateArrayIMG[1] == "" && updateArrayIMG[2] == "") {
            Swal.fire({
                html: `La raza <b>"${getRazaIMG.data.raza}"</b> no contiene ninguna imagen`,
                icon: "warning"
            })
            return
        }


        const { value: imagen } = await Swal.fire({
            title: "Selecciona foto",
            html: `<div style="display: flex; flex-wrap: wrap; justify-content: center; gap: 10px;">
                        ${getRazaIMG.data.img[0] != "" ? `<div style="display:flex; align-items:start;"> 1 <img src="${getRazaIMG.data.img[0]}" width="90px" style="border-radius: 25px; border: 2px solid black;" /> </div>` : ''}
                        ${getRazaIMG.data.img[1] != "" ? `<div style="display:flex; align-items:start;"> 2 <img src="${getRazaIMG.data.img[1]}" width="90px" style="border-radius: 25px; border: 2px solid black;" /> </div>` : ''}
                        ${getRazaIMG.data.img[2] != "" ? `<div style="display:flex; align-items:start;"> 3 <img src="${getRazaIMG.data.img[2]}" width="90px" style="border-radius: 25px; border: 2px solid black;" /> </div>` : ''}
                    </div>`,
            input: "select",
            inputOptions: {
                ...(getRazaIMG.data.img[0] != "" && { 0: "Imagen 1" }),
                ...(getRazaIMG.data.img[1] != "" && { 1: "Imagen 2" }),
                ...(getRazaIMG.data.img[2] != "" && { 2: "Imagen 3" }),
                all: "Todas"
              },
            inputPlaceholder: "Imagen a eliminar",
            showCancelButton: true,
            inputValidator: async (value) => {
              return new Promise( async (resolve) => {

                if (value === "0") { // Eliminar primera foto
                    const filename = getRazaIMG.data.img[0].split('/').pop()
                    const removeFileIMG = await deleteFile(filename) 

                    // Eliminar url del array y actualizar el tema
                    updateArrayIMG[0] = ''
                    const arrayNew = {'img': updateArrayIMG}
                    const updateForumIMG = await razaUpdate(modalID, arrayNew)
                    
                    resolve()

                } else if (value === "1") { // Eliminar segunda foto
                    const filename = getRazaIMG.data.img[1].split('/').pop()
                    const removeFileIMG = await deleteFile(filename) 
                    
                    // Eliminar url del array y actualizar el tema
                    updateArrayIMG[1] = ''
                    const arrayNew = {'img': updateArrayIMG}
                    const updateForumIMG = await razaUpdate(modalID, arrayNew)
                    
                    resolve()                    

                } else if (value === "2") { // Eliminar tercera foto
                    const filename = getRazaIMG.data.img[2].split('/').pop()
                    const removeFileIMG = await deleteFile(filename) 
                    
                    // Eliminar url del array y actualizar el tema
                    updateArrayIMG[2] = ''
                    const arrayNew = {'img': updateArrayIMG}
                    const updateForumIMG = await razaUpdate(modalID, arrayNew)
                    
                    resolve()                    

                } else if (value === "all") { // Eliminar todas las fotos y usar ternarios para evitar que de error en el caso de que no esten las tres fotos disponibles
                    let filename1
                    let filename2
                    let filename3

                    getRazaIMG.data.img[0] != "" ? filename1 = getRazaIMG.data.img[0].split('/').pop() : null
                    getRazaIMG.data.img[1] != "" ? filename2 = getRazaIMG.data.img[1].split('/').pop() : null
                    getRazaIMG.data.img[2] != "" ? filename3 = getRazaIMG.data.img[2].split('/').pop() : null

                    let removeIMG1
                    let removeIMG2
                    let removeIMG3

                    getRazaIMG.data.img[0] != "" ? removeIMG1 = await deleteFile(filename1) : null
                    getRazaIMG.data.img[1] != "" ? removeIMG2 = await deleteFile(filename2) : null
                    getRazaIMG.data.img[2] != "" ? removeIMG3 = await deleteFile(filename3) : null

                    // Eliminar url del array y actualizar el tema
                    updateArrayIMG[0] = ''
                    updateArrayIMG[1] = ''
                    updateArrayIMG[2] = ''

                    const arrayNew = {'img': updateArrayIMG}
                    const updateForumIMG = await razaUpdate(modalID, arrayNew)
                    
                    resolve()                    

                } else { // Algun error ha dado
                    resolve("Error inesperado, recargue la pagina y vuelva a intentarlo");
                }

              })
            }
          })


          if (imagen) { // Mensaje de aviso de que ya estan eliminadas las fotos
            // Ternarios para saber que mensaje ofrecer al usuario
            imagen == "0" ? Swal.fire(`La imagen numero 1 ha sido eliminada`) : null
            imagen == "1" ? Swal.fire(`La imagen numero 2 ha sido eliminada`) : null
            imagen == "2" ? Swal.fire(`La imagen numero 3 ha sido eliminada`) : null
            imagen == "all" ? Swal.fire(`Todas las imagenes han sido eliminadas`) : null

            setReboot(true)
          }

    }








    // Funcion para eliminar una raza al completo
    async function RazasMapDelete(e) {
        const buttonID = e.target.id
        const nameRaza = await getRazaByID(buttonID)

        // Sacar las rutas de las IMG para eliminar tambien
        let arrayIMG = nameRaza.data.img
                

        Swal.fire({
            title: `Se va a eliminar la raza ${nameRaza.data.raza}`,
            text: "Esta accion se se puede revertir",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Eliminar"
        }).then((result) => {
            if (result.isConfirmed) {

                // Funcion para eliminar una raza creada
                removeRaza(buttonID)
                async function removeRaza(buttonID) {
                    // Funcion map para eliminar las imagenes de multer y condicional para que no de error si no hay foto
                    const deleteIMGFromArray = arrayIMG.map( async (data) => {
                        if (data != '') {
                            const filename = data.split('/').pop()
                            const removeFileIMG = await deleteFile(filename)
                        }    
                    })

                    // Eliminar la raza de MongoBD
                    const razaDeleteByID = await razaDelete(buttonID) 
                }


                Swal.fire({
                    title: "Eliminada correctamente",
                    text: `La raza ${nameRaza.data.raza} ha sido eliminada`,
                    icon: "success"
                })

                setReboot(true)
            }
        })           
    }










    return (

        <div id="listRazasGestion-body">

                {dataRaza}


                {modalUpdate &&
                    <Modal isOpen={true}>

                        <div id="modalUpdate-razas">

                            <p>Nombre:</p> <input type="text" value={modalRaza} onChange={(e) => {setModalRaza(e.target.value)}} />
                            <p>Descripcion:</p> <textarea value={modalDescript} onChange={(e) => {setModalDescript(e.target.value)}}/>
                            <p>Pais:</p> <input type="text" value={modalCountry} onChange={(e) => {setModalCountry(e.target.value)}}/>
                            <p>Esperanza de vida:</p> <input type="number" value={modalYears} min={1} max={25} onChange={(e) => {setModalYears(e.target.value)}}/>
                            <p>Peso maximo aproximado:</p> <input type="number" value={modalWeight} min={1} max={25000} onChange={(e) => {setModalWeight(e.target.value)}}/>
                            <p>Trato con familia:</p> <input type="number" value={modalFamily} min={1} max={5} onChange={(e) => {setModalFamily(e.target.value)}}/>
                            <p>Trato con extraños:</p> <input type="number" value={modalStrange} min={1} max={5} onChange={(e) => {setModalStrange(e.target.value)}}/>
                            <p>Trato con animales:</p> <input type="number" value={modalPet} min={1} max={5} onChange={(e) => {setModalPet(e.target.value)}}/>
                            <p>Jugueton:</p> <input type="number" value={modalPlay} min={1} max={5} onChange={(e) => {setModalPlay(e.target.value)}}/>
                            <p>Maullador:</p> <input type="number" value={modalMeow} min={1} max={5} onChange={(e) => {setModalMeow(e.target.value)}}/>
                            <p>Inteligencia:</p> <input type="number" value={modalIntelligence} min={1} max={5} onChange={(e) => {setModalIntelligence(e.target.value)}}/>

                            <input type="file" id="img1" />
                            <input type="file" id="img2" />
                            <input type="file" id="img3" />

                            <div id="modalUpdate-razas-buttons">
                                <input type="button" value="Actualizar" onClick={(e) => {updateRazaButton(modalUpdate)}} />
                                <input type="button" value="Eliminar fotos" onClick={(data) => {deleteRazaIMG(data)}} />
                                <input type="button" value="Cerrar" onClick={() => {setModalUpdate(null)}} />
                            </div>
                        </div>

                    </Modal>
                }

        </div>

    )
}





export {
    ListRazasGestion
}