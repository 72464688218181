import '../../../scss/gestion/user/formUser.scss'

import React from 'react'
import { getUserByProperty, userCreate } from '../../../api/userAPI'
import { uploadFile } from '../../../funciones/uploadMulter'
import { API_URL } from '../../../api/API'
import { dateFormat } from '../../../funciones/fecha'
import Swal from 'sweetalert2'
import { sha256 } from 'js-sha256'



function FormUserGestion() {

    // Expresiones regulares para controlar la validez de lo que se escribe en el formulario
    // !regexEmail.test(formEmail)
    const regexUsername = /^[A-Za-z0-9]+$/
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#%&_])[a-zA-Z0-9@#%&_]{8,}$/



    async function dataAcept(e) {
        const dataInputUser = document.getElementById('inputUser')
        const dataInputEmail = document.getElementById('inputEmail')
        const dataInputRol = document.getElementById('inputRol')
        const dataInputPassword1 = document.getElementById('inputPassword1')
        const dataInputPassword2 = document.getElementById('inputPassword2')
        const dataInputIMG1 = document.getElementById('inputIMG1')

        let routeFile1 = `${API_URL}/uploads/none.png`


        // Condicionales para evitar que si no hay una imagen cargada no haga nada con multer
        if (dataInputIMG1.files[0] != null && dataInputIMG1.files[0] != undefined) {
            let postIMG1 = await uploadFile(dataInputIMG1.files[0]);
            routeFile1 = `${API_URL}${postIMG1.filePath}`
        }
      

        const arrayNewUser = {'username': dataInputUser.value,
                            'email': dataInputEmail.value,
                            'password': sha256(dataInputPassword1.value),
                            'dateString': await dateFormat(Date.now()),
                            'rol': dataInputRol.value,
                            'img': routeFile1,
                            'validateEmail': true,
                            'favs': [] }


        // Condicional para evitar que se guarde una user sin escribir todos los datos (Imagenes exentas)                            
        if (dataInputUser.value.length > 3 && dataInputEmail.value.length > 7 && dataInputPassword1.value.length > 0) {

            
            if (dataInputPassword1.value === dataInputPassword2.value && regexPassword.test(dataInputPassword1.value)) {

                    // Condicional para evitar que se pueda crear una nueva user con un nombre o email ya utilizado
                    const findNameUser = await getUserByProperty('username', dataInputUser.value)
                    const userSome = findNameUser.data.some( data => data.username === dataInputUser.value)
                    const emailSome = findNameUser.data.some( data => data.email === dataInputEmail.value)
                    
                    if (!userSome || !emailSome) {
                        const newDataUser = await userCreate(arrayNewUser)
                        
                        // Condicional para que depende de como se ha creado el registro aparezca un mensaje u otro
                        if(newDataUser != undefined && newDataUser != null && newDataUser.data._id.length > 10 ) {
                            Swal.fire({
                                title: "Registro creado",
                                text: `El usuario ${dataInputUser.value} ha sido creado correctamente`,
                                icon: "success"
                            });

                            // Vaciar todos los campos para evitar crear una user por error
                            dataInputUser.value = ''
                            dataInputEmail.value = ''
                            dataInputPassword1.value = ''
                            dataInputPassword2.value = ''
                            dataInputIMG1.value = ''
                            dataInputRol.value = 'User'

                        } else {
                            Swal.fire({
                                title: "Error de registro",
                                text: `El usuario ${dataInputUser.value} no ha podido ser creado, revise sus datos y vuelva a intentarlo`,
                                icon: "error"
                            });
                        }

                    } else {
                        Swal.fire({
                            title: "Envio de validacion",
                            text: `Se ha enviado un email a ${dataInputEmail.value} para validar el usuario ${dataInputUser.value}`,
                            icon: "warning"
                        });
                    }



            } else if (dataInputPassword1.value != dataInputPassword2.value) {
                Swal.fire({
                    title: "Passwords erroneos",
                    text: `Los password introducidos no coinciden entre ellos, vuelva a intentarlo`,
                    icon: "error"
                });
                dataInputPassword1.value = ''
                dataInputPassword2.value = ''

            } else {
                Swal.fire({
                    title: "Requisitos de password",
                    text: `La password introducida no cumple los requisitos minimos de seguridad. Debe tener al menos 8 caracteres entre letras minusculas, mayusculas, numeros y al menos uno de los siguientes simbolos @ # % & _`,
                    icon: "error"
                });
            }



        } else {
            Swal.fire({
                title: "Faltan datos",
                text: `Es necesario que rellene todos los campos para poder crear una nuevo usuario`,
                icon: "error"
            });
        }
        
    }





    return (

        <div id="formUserGestion-body">

            <div id="formUserGestion-data">

                <input type="text" className='formUserGestion-input' placeholder='Nombre' id="inputUser" />
                <input type="text" className='formUserGestion-input' placeholder='Correo electronico' id='inputEmail' />
                <select className='formUserGestion-input' id="inputRol">
                    <option value="user" selected>User</option>
                    <option value="admin">Admin</option>
                </select>
                <input type="password" className='formUserGestion-input' placeholder='Password' id='inputPassword1' />
                <input type="password" className='formUserGestion-input' placeholder='Repetir Password' id='inputPassword2' />
                
                <input type="file" className='formUserGestion-input' id="inputIMG1" />


                <input type="button" id='formUserGestion-acept' value="Aceptar datos" onClick={dataAcept} />

            </div>

        </div>

    )
}



export {
    FormUserGestion
}