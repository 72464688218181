import '../scss/quiz/quiz.scss'

import React from 'react'
import { useEffect } from 'react'
import { Navbar } from '../componentes/navbar'
import { QuizList } from '../componentes/quiz/quizList'
import { Footer } from '../componentes/footer'


function QuizPage() {





        // useEffect para enviar a la parte superior de la pagina una vez que se entra en ella 
        useEffect(() => {
            window.scrollTo({
                top: 0,
            })
        }, [])



    return (

        <div id="quizPage-body">

            <Navbar />

            <QuizList />

            <Footer />

        </div>

    )
}



export {
    QuizPage
}