import '../../scss/home/text.scss'

import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


function HomeText() {
    const navigate = useNavigate()


    // useEffect para enviar a la parte superior de la pagina una vez que se entra en ella 
    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])


    return(

        <div id="homeText-body">

            <div id="homeText-text">
        
                <h2>¡Bienvenid@s a Michipedia!</h2>

                <p>¡Miau! Estás a punto de entrar en el fascinante mundo de los gatos, donde cada rincón está lleno de ronroneos y aventuras felinas. Ya seas un amante de los gatos desde hace años o estés empezando a descubrir el mágico encanto de estos pequeños felinos, aquí encontrarás todo lo que necesitas para saciar tu curiosidad gatuna.</p>

                <p>En nuestra página, podrás:</p>

                <ul>
                    <li className='homeText-li'><b>Explorar Razas de Gatos: </b>Utiliza nuestro buscador para encontrar información detallada sobre tus razas favoritas. Ya sea por su nombre o por características específicas, te ayudaremos a encontrar el gato perfecto para ti.</li>
                    <li className='homeText-li'><b>Descubrir tu Gato Ideal: </b>Participa en nuestro divertido quiz y descubre qué raza de gato se adapta mejor a tu personalidad y estilo de vida.</li>
                    <li className='homeText-li'><b>Compartir y Aprender en el Foro: </b>Únete a nuestra comunidad de amantes de gatos. Comparte tus historias, fotos y consejos, y aprende de otros entusiastas felinos.</li>
                    <li className='homeText-li'><b>Zona de Usuarios: </b>Guarda tus publicaciones favoritas, sigue hilos interesantes y crea tu propio rincón gatuno personalizado.</li>
                </ul>

                <p>Aquí en Michipedia, creemos que cada gato tiene su propia magia y queremos compartir ese encanto contigo. Así que ponte cómodo, prepara tus mejores caricias y déjate llevar por esta aventura felina. ¡Nos encanta que hayas venido a ronronear con nosotros!</p>

                <h3>¡Bienvenid@ y que disfrute en Michipedia!</h3>

                <p id='homeText-button-contact' onClick={() => {navigate('/contact')}}>Formulario de contacto</p>

            </div>

        </div>

    )
}


export {
    HomeText
}