import '../../scss/quiz/quizDetailComponent.scss'

import React, { useContext } from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getQuizByID } from '../../api/quizAPI'
import { getRazaByID } from '../../api/razaAPI'




function QuizDetailComponent({id}) {
    const navigate = useNavigate()

    const [phase, setPhase] = useState(0)
    const [score, setScore] = useState(0)

    const [title, setTitle] = useState(null)
    const [descript, setDescript] = useState(null)
    const [img, setIMG] = useState(null)

    const [question, setQuestion] = useState('')
    const [result, setResult] = useState(null)

    const [answer1, setAnswer1] = useState('')
    const [answer2, setAnswer2] = useState('')
    const [answer3, setAnswer3] = useState('')
    const [answer4, setAnswer4] = useState('')

    const [result1, setResult1] = useState('')
    const [result2, setResult2] = useState('')
    const [result3, setResult3] = useState('')
    const [result4, setResult4] = useState('')

    const [razaIMG, setRazaIMG] = useState('')
    const [razaID, setRazaID] = useState('')




    
    // useEffect para traer todos los datos del Quiz por id
    useEffect(() => {

            getDataQuiz()
            async function getDataQuiz() {
                const getDataQuiz = await getQuizByID(id)           

                setTitle(getDataQuiz.data.title)
                setDescript(getDataQuiz.data.descript)
                setIMG(getDataQuiz.data.img)

                setQuestion(getDataQuiz.data.questions[phase][0])

                setAnswer1(getDataQuiz.data.questions[phase][1])
                setAnswer2(getDataQuiz.data.questions[phase][2])
                setAnswer3(getDataQuiz.data.questions[phase][3])
                setAnswer4(getDataQuiz.data.questions[phase][4])

                setResult1(getDataQuiz.data.results[0])
                setResult2(getDataQuiz.data.results[1])
                setResult3(getDataQuiz.data.results[2])
                setResult4(getDataQuiz.data.results[3])
            }

    }, [phase])






    // Funcion para guardar la informacion de las respuestas
    async function answerSelected(e) {
        const scoreButton = e.target.id
        setScore(prevCount => prevCount + parseInt(scoreButton))

            
        // Condicional para saber cuando no hay mas preguntas
        if (phase < 4) { // Hay mas preguntas
            setPhase(phase + 1)

        } else { // Se ha llegado a la ultima
            // Esconder preguntas y respuestas
            setQuestion(null)
            setAnswer1(null)
            setAnswer2(null)
            setAnswer3(null)
            setAnswer4(null)

            // Calcular resultado mediante la puntuacion recibida
            // Hacer un getRazaByID para sacar la info del gato asociado
            if (score < 7) {
                const findRazaID = await getRazaByID(result1[2])
                setRazaIMG(findRazaID.data.img[0])
                setRazaID(findRazaID.data._id)
                setResult(result1)

            } else if (score < 12) {
                const findRazaID = await getRazaByID(result2[2])
                setRazaIMG(findRazaID.data.img[0])
                setRazaID(findRazaID.data._id)
                setResult(result2)

            } else if (score < 16) {
                const findRazaID = await getRazaByID(result3[2])
                setRazaIMG(findRazaID.data.img[0])
                setRazaID(findRazaID.data._id)
                setResult(result3)

            } else {
                const findRazaID = await getRazaByID(result4[2])
                setRazaIMG(findRazaID.data.img[0])
                setRazaID(findRazaID.data._id)
                setResult(result4)
            }
        }
    }






    

    return (

        <div id="quizDetailComponent-body">

            <div id="quizDetailComponent-data">
                
                <div id="quizDetailComponent-text">
                    <img src={img} />
                    <p id='quizDetailComponent-title'>{title}</p>
                </div>

                <p id='quizDetailComponent-descript'>{descript}</p>
                
            </div>

            <div id="quizDetailComponent-questions">

                <div id="quizDetail-question">
                    <p id='quizDetail-question-p'>{question}</p>

                    <div id="quizDetail-result-header">
                        { result != null ? <p id='quizDetail-resultData'>{result[0]}</p> : <></> }
                        { result != null ? <img id='quizDetail-resultIMG' src={razaIMG} /> : <></> }
                    </div>
                    
                    { result != null ? <p id='quizDetail-resultDescript'>{result[1]}</p> : <></> }
                    { result != null ? <p id='quizDetail-resultLink' onClick={() => {navigate(`/raza/${razaID}`)}}>Para ver la raza pulse aqui</p> : <></> }
                </div>

                <div id="quizDetail-answer">
                    { answer1 != null ? <p className='quizDetail-answer-p' id='1' onClick={answerSelected}>{answer1}</p> : <></> }
                    { answer2 != null ? <p className='quizDetail-answer-p' id='2' onClick={answerSelected}>{answer2}</p> : <></> }
                    { answer3 != null ? <p className='quizDetail-answer-p' id='3' onClick={answerSelected}>{answer3}</p> : <></> }
                    { answer4 != null ? <p className='quizDetail-answer-p' id='4' onClick={answerSelected}>{answer4}</p> : <></> }
                </div>

            </div>

        </div>

    )
}




export {
    QuizDetailComponent
}