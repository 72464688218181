import '../../scss/quiz/quizList.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getQuiz, getQuizByProperty } from '../../api/quizAPI'



function QuizList() {
    const navigate = useNavigate()

    const [reboot, setReboot] = useState(true)
    const [dataQuiz, setDataQuiz] = useState(null)
    const [rotulo, setRotulo] = useState('')

    const [initial, setInitial] = useState(0)
    const [final, setFinal] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [totalQuiz, setTotalQuiz] = useState(0)




    // useEffect para traer los quiz de forma automatica y meterlos en un metodo map para sacar en pantalla
    useEffect(() => {
        if (reboot) {

            getAllquiz()
            async function getAllquiz() {
                
                const getAllQuiz = await getQuiz()

                // Paginacion para que se muestren solo en grupos de 10
                const nextItems = getAllQuiz.data.slice(initial, final)
                setTotalQuiz(getAllQuiz.data.length)
                setTotalPage(Math.ceil(getAllQuiz.data.length / 10))


                const allQuizMap = nextItems.reverse().map((data) => 
                    <div id="allQuiz-div" key={data._id} onClick={() => navigate(`/quiz/${data._id}`)}>
                        <img id='allQuiz-data-img' src={data.img} />

                        <div id="allQuiz-data-text">
                            <p id='allQuiz-data-title'>{data.title}</p>
                            <p id='allQuiz-data-date'>{data.dateString}</p>
                        </div>
                    </div>
                )
                setDataQuiz(allQuizMap)

            }

            setReboot(false)
        }
    }, [reboot])









    // Funcion para buscar quizs con input
    async function quizSearch(e) {
        setDataQuiz(null)
        const searchData = e.target.value
        const searchQuizProperty = await getQuizByProperty('title', searchData)

        // Condicional para comprobar si hay o no quizs con la busqueda
        if (searchData == '') { // Si se ha vaciado el input de busqueda
            setDataQuiz(null)
            setRotulo('')
            setReboot(true)

        } else if (searchQuizProperty.data == undefined) { // Si los datos encontrados son undefined
            setDataQuiz(null)
            setRotulo('Error de busqueda')
            setReboot(true)

        } else if (searchQuizProperty.data.length === 0) { // Si no ha encontrado coincidencias
            setRotulo('Sin coincidencias con su busqueda')
            setDataQuiz(null)

        } else if (searchQuizProperty.data.length > 0) { // Si encuentra datos apra mostrar
            // Metodo map para mostrar en pantalla
            const searchQuizMap = searchQuizProperty.data.reverse().map((data) => 
                <div id="allQuiz-div" key={data._id} onClick={() => navigate(`/quiz/${data._id}`)}>
                    <img id='allQuiz-data-img' src={data.img} />

                    <div id="allQuiz-data-text">
                        <p id='allQuiz-data-title'>{data.title}</p>
                        <p id='allQuiz-data-date'>{data.dateString}</p>
                    </div>
                </div>
            )
            setDataQuiz(searchQuizMap)
            setRotulo('Quizs encontrados')
        }

    }






    // Funciones de paginacion que vienen de los botones
    async function buttonPrevResponses(e) {
        // Condicional para que no pueda irse a negativo
        if (initial > 0) {
            setInitial(prev => prev - 10)
            setFinal(prev => prev - 10)
            setCurrentPage(prev => prev - 1)
            setReboot(true)

            // Funcion para volver a la parte superior de la pagina al pulsar el boton
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth' // Opcional: para un desplazamiento suave
                })            
            }, 100);

        }
    }


    async function buttonPostResponses(e) {
            // Condicional para que no pueda irse a negativo
            if (final < totalQuiz) {
                setInitial(prev => prev + 10)
                setFinal(prev => prev + 10)
                setCurrentPage(prev => prev + 1)
                setReboot(true)

            // Funcion para volver a la parte superior de la pagina al pulsar el boton
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth' // Opcional: para un desplazamiento suave
                })            
            }, 100);
        }
    }
    






    return (

        <div id="quizList-body">

            <div id="quizList-search">
                <input type="text" id="quizList-search-input" placeholder='Escriba su busqueda aqui...' onChange={quizSearch} />
                { rotulo.length > 0 ? <p id='quizList-rotulo'>{rotulo}</p> : <></> }
            </div>

            <div id="quizList-data">
                {dataQuiz}
            </div>

            <div id="quizList-data-paginacion">
                <input type="button" value="Anterior" onClick={buttonPrevResponses} />
                <p id='quizList-data-currentPage'>{currentPage} / {totalPage}</p>
                <input type="button" value="Siguiente" onClick={buttonPostResponses} />
            </div>

        </div>

    )
}


export {
    QuizList
}