import '../scss/footer.scss'

import React from 'react'
import { useNavigate } from 'react-router-dom'



function Footer() {
    const navigate = useNavigate()



    return(

        <div id="footer-body">

            <p>&#169; Michipedia | 2024 | <a id='footer-contact' onClick={() => {navigate('/contact')}}>Contacto</a></p>
            <p id='footer-cookies'>Michipedia usa Cookies para su correcto funcionamiento, no se usan Cookies de terceros</p>

        </div>

    )
}



export {
    Footer
}