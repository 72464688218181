import '../../../scss/gestion/quiz/listQuiz.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { getQuiz, getQuizByID, quizDelete } from '../../../api/quizAPI'
import Swal from 'sweetalert2'
import { deleteFile } from '../../../funciones/deleteMulter'



function ListQuizGestion() {
    const [reboot, setReboot] = useState(true)
    const [dataQuiz, setDataQuiz] = useState('')

    const [final, setFinal] = useState(20)
    const [total, setTotal] = useState(0)



    // useEffect para sacar todas las quiz, meterlas en un map y mostrarlas en el return
    useEffect(() => {
        if(reboot) {

            getAllQuiz()
            async function getAllQuiz() {
                const quiz = await getQuiz()

                // Separar la informacion en bloques de 20 para hacer scroll infinito
                setTotal(quiz.data.length)
                const nextItems = quiz.data.slice(0, final)

                const quizMap = nextItems.map((data) => 
                    <div id="quizMap-div" key={data._id}>
                        <img src={data.img} id='quizMap-img' />
                        <p id='quizMap-name'>{data.title}</p>
                        <div id="quizMap-div-buttons">
                            <input className='quizMap-button quizMap-button-update' id={data._id} type="button" value="Modificar" onClick={quizMapUpdate} />
                            <input className='quizMap-button quizMap-button-delete' id={data._id} type="button" value="Eliminar" onClick={QuizMapDelete} />
                        </div>
                    </div>
                )
                setDataQuiz(quizMap)
                setReboot(false)
            }
        }
    }, [reboot])





    // Funcion de scroll infinito para detectar cuando llega al final de la pagina
    window.addEventListener('scroll', () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight + 800) {
            if (total > final) {
                setFinal(prevFinal => prevFinal + 20)
                setReboot(true)
            }
        }
    })






    // Funcion para modificar los datos de una quiz
    async function quizMapUpdate(e){
        const buttonID = e.target.id

    }






    // Funcion para eliminar una quiz al completo
    async function QuizMapDelete(e) {
        const buttonID = e.target.id
        const nameQuiz = await getQuizByID(buttonID)

        // Sacar las rutas de las IMG para eliminar tambien
        let arrayIMG = nameQuiz.data.img
        
                

        Swal.fire({
            title: `Se va a eliminar la quiz ${nameQuiz.data.quiz}`,
            text: "Esta accion se se puede revertir",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Eliminar"
        }).then((result) => {
            if (result.isConfirmed) {

                // Funcion para eliminar una quiz creada
                removeQuiz(buttonID)
                async function removeQuiz(buttonID) {
                    // Funcion para eliminar las imagenes de multer y condicional para que no de error si no hay foto
                    if (arrayIMG != undefined && arrayIMG != null && arrayIMG != '') {
                        const filename = arrayIMG.split('/').pop()
                        const removeFileIMG = await deleteFile(filename)
                    }    


                    // Eliminar la quiz de MongoBD
                    const quizDeleteByID = await quizDelete(buttonID) 
                }


                Swal.fire({
                    title: "Eliminada correctamente",
                    text: `La quiz ${nameQuiz.data.quiz} ha sido eliminada`,
                    icon: "success"
                })

                setReboot(true)
            }
        })           
    }








    return (

        <div id="listQuizGestion-body">

                {dataQuiz}

        </div>

    )
}



export {
    ListQuizGestion
}