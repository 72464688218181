import '../scss/home/home.scss'

import React from 'react'
import { Navbar } from '../componentes/navbar'
import { Footer } from '../componentes/footer'
import { HomeFact } from '../componentes/home/fact'
import { HomeText } from '../componentes/home/text'



function HomePage() {



    return (

        <div id="home-body">

            <Navbar />

            <HomeFact />

            <HomeText />

            <Footer />

        </div>

    )
}



export {
    HomePage
}