import '../scss/contact.scss'

import React from 'react'
import { useEffect } from 'react'
import Swal from 'sweetalert2'

import { Navbar } from '../componentes/navbar'
import { Footer } from '../componentes/footer'

import { userEmailContact } from '../api/sendEmailAPI'



function Contact() {
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/


    // useEffect para enviar a la parte superior de la pagina una vez que se entra en ella 
    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])




    async function sendEmailContact() {
        const inputUsername = document.getElementById('contact-form-name')
        const inputEmail = document.getElementById('contact-form-email')
        const inputMessage = document.getElementById('contact-form-text')

        if (inputUsername.value.trim().length == 0 || inputEmail.value.trim().length == 0 || inputMessage.value.trim().length == 0) { // No se pueden dejar campos vacios
            Swal.fire({
                html: `Se deben rellenar todos los campos para poder enviar el mensaje de forma correcta`,
                icon: 'warning'
            })
            return

        } else if (!regexEmail.test(inputEmail.value.trim())) { // El email no tiene la estructura adecuada
            Swal.fire({
                html: 'El email no tiene la estructura correcta, debe escribirlo correctamente',
                icon: 'warning'
            })
            return

        } else { // Todo bien
            Swal.fire({
                html: `Su mensaje ha sido enviado de forma correcta, recibira una respuesta por parte del soporte de <i>Michipedia</i> lo antes posible`,
                icon: 'success'
            })

            const sendingEmailContact = await userEmailContact({'email': `${inputUsername.value.trim()} / ${inputEmail.value.trim()}`, 'message': inputMessage.value.trim()})

            inputUsername.value = ''
            inputEmail.value = ''
            inputMessage.value = ''
        }
    }






    return (
        <div id="contact-body">
            <Navbar />

            <div id="contact-form">
                <h2 id='contact-form-header'><b>¿Tienes alguna duda o sugerencia? ¡Escríbenos!</b></h2>
                <p className='contact-form-rotulo'>En <i>Michipedia</i> nos encanta escuchar a nuestra comunidad felina. Si tienes alguna pregunta, comentario o sugerencia, este es el lugar para ponerte en contacto directamente con los administradores del sitio.</p>
                <p className='contact-form-rotulo'>Tu mensaje será revisado con cuidado, y haremos todo lo posible por responderte lo antes posible. No te preocupes, tus datos estarán seguros con nosotros y no serán compartidos con terceros.</p>
                <p className='contact-form-rotulo' id='contact-form-rotulo-footer'>¡Gracias por ayudarnos a mejorar <i>Michipedia!</i></p>

                <input type="text" className='contact-form-input' id="contact-form-name" placeholder='Nombre' />
                <input type="text" className='contact-form-input' id="contact-form-email" placeholder='Correo electronico' />
                <textarea id="contact-form-text" placeholder='Escriba su mensaje aqui...' />

                <input type="button" id='contact-form-button' value="Enviar" onClick={sendEmailContact} />
            </div>

            <Footer />
        </div>
    )
}



export {
    Contact
}