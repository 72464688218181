import '../../../scss/userPanel/userPanel-noti-old.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { getNotiByProperty, notiUpdate } from '../../../api/notiAPI'
import { getCookie } from '../../../funciones/token'
import { decodeToken } from '../../../api/userAPI'
import { dateFormat } from '../../../funciones/fecha'
import { useNavigate } from 'react-router-dom'
import { FaTrashAlt } from "react-icons/fa";
import Swal from 'sweetalert2'




function UserPanelNotiOld() {
    const navigate = useNavigate()

    const [reboot, setReboot] = useState(true)
    const [dataNoti, setDataNoti] = useState(null)
    const [rotulo, setRotulo] = useState('')
    const [tokenID, setTokenID] = useState('')




        // useEffect para comprobar si el usuario tiene o no notificaciones antiguas
        useEffect(() => {

            validateNotification()
            async function validateNotification() {

                // Sacar ID del token
                const token = getCookie('token')
                const tokenDecode = await decodeToken()
                setTokenID(tokenDecode.data.id)
    
                // Buscar todas las notificaciones por el ID del usuario y filtrar por las no vistas
                const getNotiAll = await getNotiByProperty('userID', tokenDecode.data.id)


                // Metodo Map para llevar las notificaciones al return
                // Se usa un primer metodo map con Promise.all para poder hacer un dateFormat asincrono
                const processDates = async () => {
                    return await Promise.all(getNotiAll.data.map(async data => {
                        const formattedDate = await dateFormat(data.date);
                        return { ...data, formattedDate };
                    }))
                }

                // Filtrar unicamente los que sean notificaciones nuevas
                const getNotiAllMapFilter = (await processDates()).filter(data => data.old == true)

                // Ternario para modificar el rotulo en funcion de si hay o no notificaciones
                getNotiAllMapFilter.length > 0 ? setRotulo('Notificaciones antiguas') : setRotulo('No tienes ninguna notificacion antigua')
                
                // Metodo map para mostrar todo en pantalla
                const getNotiAllMap = getNotiAllMapFilter.map(data => 
                    <div id="getNoti-div" key={data._id} onClick={() => {navigate(`/forum/${data.topicID}`)}}>
                        <p>{data.topic}</p>
                        <p>{data.dateString}</p>
                    </div>
                )
                setDataNoti(getNotiAllMap)
                setReboot(false)
    
            }
    
        },[reboot])



        






    return (

        <div id="userPanel-noti-body-old">

            <div id="userPanel-rotulos-old">
                <p id='userPanel-noti-rotulo-old'> { rotulo } </p>
            </div>
             
            { dataNoti }
        </div>

    )
}




export {
    UserPanelNotiOld
}