import '../scss/quiz/quizDetail.scss'

import React from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Navbar } from '../componentes/navbar'
import { QuizDetailComponent } from '../componentes/quiz/quizDetailComponent'
import { Footer } from '../componentes/footer'


function QuizPageDetail() {
    const { id } = useParams()





        // useEffect para enviar a la parte superior de la pagina una vez que se entra en ella 
        useEffect(() => {
            window.scrollTo({
                top: 0,
            })
        }, [])




    return (

        <div id="quizDetail-body">

            <Navbar />

            <QuizDetailComponent id={id} />

            <Footer />

        </div>

    )
}


export {
    QuizPageDetail
}