import '../../../scss/gestion/razas/formRazas.scss'

import React from 'react'
import { getRazaByProperty, razaCreate } from '../../../api/razaAPI'
import { uploadFile } from '../../../funciones/uploadMulter'
import { validImageTypes } from '../../../funciones/resizeIMG'
import { API_URL } from '../../../api/API'
import Swal from 'sweetalert2'



function FormRazasGestion() {





    async function dataAcept(e) {
        const dataInputRaza = document.getElementById('inputRaza')
        const dataInputDescript = document.getElementById('inputDescript')
        const dataInputCountry = document.getElementById('inputCountry')
        const dataInputLife = document.getElementById('inputLife')
        const dataInputWeight = document.getElementById('inputWeight')
        const dataInputFamily = document.getElementById('inputFamily')
        const dataInputStrange = document.getElementById('inputStrange')
        const dataInputOtherPets = document.getElementById('inputOtherPets')
        const dataInputPlay = document.getElementById('inputPlay')
        const dataInputMeow = document.getElementById('inputMeow')
        const dataInputIntelligence = document.getElementById('inputIntelligence')
        const dataInputIMG1 = document.getElementById('inputIMG1')

        let routeFile1 = ''
        let routeFile2 = ''
        let routeFile3 = ''



        Swal.fire({
            html: 'Publicando contenido...',
            icon: 'info',
            showConfirmButton: false
        })


        // Evitar que se suban mas de 3 imagenes
        if (dataInputIMG1.files.length > 3) {
            Swal.fire({
                html: 'Solo se permite un maximo de tres fotos',
                icon: 'warning'
            })
            return
        }


        // Bucle y condicionales para comprobar que solo sean imagenes
        for (let i = 0; dataInputIMG1.files.length > i; i++) {
            if (!validImageTypes(dataInputIMG1.files[i].type)) {
                dataInputIMG1.value = ''
                Swal.fire({
                    text: "Solo se permiten adjuntar imagenes",
                    icon: "error"
                })
                return
            }
        }



        // Condicionales con la opcion de subir una imagen con multer para el topic
        if (dataInputIMG1.files[0] != null && dataInputIMG1.files[0] != undefined) {
            let postIMG1 = await uploadFile(dataInputIMG1.files[0]);
            routeFile1 = `${API_URL}${postIMG1.filePath}`
        }

        if (dataInputIMG1.files[1] != null && dataInputIMG1.files[1] != undefined) {
            let postIMG2 = await uploadFile(dataInputIMG1.files[1]);
            routeFile2 = `${API_URL}${postIMG2.filePath}`
        }

        if (dataInputIMG1.files[2] != null && dataInputIMG1.files[2] != undefined) {
            let postIMG3 = await uploadFile(dataInputIMG1.files[2]);
            routeFile3 = `${API_URL}${postIMG3.filePath}`
        }
        

        const arrayNewRaza = {'raza': dataInputRaza.value,
                            'descript': dataInputDescript.value,
                            'country': dataInputCountry.value,
                            'years': dataInputLife.value,
                            'weight': dataInputWeight.value,
                            'family_friendly': dataInputFamily.value,
                            'stranger_friendly': dataInputStrange.value,
                            'other_pets_friendly': dataInputOtherPets.value,
                            'playfulness': dataInputPlay.value,
                            'meowing': dataInputMeow.value,
                            'intelligence': dataInputIntelligence.value,
                            'img': [routeFile1, routeFile2, routeFile3] }


        // Condicional para evitar que se guarde una raza sin escribir todos los datos (Imagenes exentas)                            
        if (dataInputRaza.value.length > 1 && dataInputDescript.value.length > 10 && dataInputCountry.value.length > 3 && dataInputLife.value >= 1 && dataInputWeight.value >= 1) {
            

            // Condicional para evitar que se pueda crear una nueva raza con un nombre ya utilizado
            const findNameRaza = await getRazaByProperty('raza', dataInputRaza.value)
            const razaSome = findNameRaza.data.some( data => data.raza === dataInputRaza.value)
            
            if (!razaSome) {
                const newDataRaza = await razaCreate(arrayNewRaza)
                
                // Condicional para que depende de como se ha creado el registro aparezca un mensaje u otro
                if(newDataRaza.data._id.length > 10 ) {
                    Swal.fire({
                        title: "Registro creado",
                        text: `La raza ${dataInputRaza.value} ha sido creada correctamente`,
                        icon: "success"
                    });

                    // Vaciar todos los campos para evitar crear una raza por error
                    dataInputRaza.value = ''
                    dataInputDescript.value = ''
                    dataInputCountry.value = ''
                    dataInputLife.value = ''
                    dataInputWeight.value = ''
                    dataInputFamily.value = ''
                    dataInputStrange.value = ''
                    dataInputOtherPets.value = ''
                    dataInputPlay.value = ''
                    dataInputMeow.value = ''
                    dataInputIntelligence.value = ''
                    dataInputIMG1.value = ''

                } else {
                    Swal.fire({
                        title: "Error de registro",
                        text: `La raza ${dataInputRaza.value} no ha podido ser creada, revise sus datos y vuelva a intentarlo`,
                        icon: "error"
                    });
                }

            } else {
                Swal.fire({
                    title: "Raza ya creada",
                    text: `La raza ${dataInputRaza.value} ya esta creada, no se puede duplicar una raza`,
                    icon: "error"
                });
            }


        } else {
            Swal.fire({
                title: "Faltan datos",
                text: `Es necesario que rellene todos los campos para poder crear una nueva raza`,
                icon: "error"
            });
        }
        
    }





    return (

        <div id="formRazasGestion-body">

            <div id="formRazasGestion-data">

                <input type="text" className='formRazasGestion-input' placeholder='Nombre' id="inputRaza" />
                <textarea className='formRazasGestion-input' placeholder='Descripcion' id="inputDescript"/>
                <input type="text" className='formRazasGestion-input' placeholder='Pais' id="inputCountry" />
                <input type="number" className='formRazasGestion-input input-number' placeholder='Esperanza de vida' id="inputLife" max={25} min={1} />
                <input type="number" className='formRazasGestion-input input-number' placeholder='Peso en kilos' id="inputWeight" max={25} min={1} />
                <input type="number" className='formRazasGestion-input input-number' placeholder='Trato con familia' id="inputFamily" max={5} min={1} />
                <input type="number" className='formRazasGestion-input input-number' placeholder='Trato con extraños' id="inputStrange" max={5} min={1} />
                <input type="number" className='formRazasGestion-input input-number' placeholder='Trato con animales' id="inputOtherPets" max={5} min={1} />
                <input type="number" className='formRazasGestion-input input-number' placeholder='Jugueton' id="inputPlay" max={5} min={1} />
                <input type="number" className='formRazasGestion-input input-number' placeholder='Maullador' id="inputMeow" max={5} min={1} />
                <input type="number" className='formRazasGestion-input input-number' placeholder='Inteligencia' id="inputIntelligence" max={5} min={1} />
                <input type="file" className='formRazasGestion-input' id="inputIMG1" />

                <input type="button" id='formRazasGestion-acept' value="Aceptar datos" onClick={dataAcept} />

            </div>

        </div>

    )
}



export {
    FormRazasGestion
}