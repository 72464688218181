import '../../scss/login/newPassword.scss'

import React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getUserByID, getUserByProperty, userUpdate } from '../../api/userAPI'
import { userEmailContact } from '../../api/sendEmailAPI'
import { Navbar } from '../navbar'
import { Footer } from '../footer'
import Swal from 'sweetalert2'
import { sha256 } from 'js-sha256'



function NewPass() {
    const navigate = useNavigate()
    const { id } = useParams()

    const [showForm, setShowForm] = useState(false)

    
    const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#%&_])[a-zA-Z0-9@#%&_]{8,}$/







    
    // Funcion para reestablecer contraseña desde el boton
    async function newPass() {
        const inputEmail = document.getElementById('newPass-form-email')
        const inputPass = document.getElementById('newPass-form-pass')
        const inputRepeatPass = document.getElementById('newPass-form-repeatPass')

        const getUserEmail = await getUserByProperty('email', inputEmail.value.trim())

        if (getUserEmail.data == undefined || getUserEmail.data == null || getUserEmail.data.length == 0) { // Aqui si no existe ningun usuario con el email escrito
            Swal.fire({
                html: `La informacion proporcionada no es valida`,
                icon: 'error'
            })
            return

        } else if (inputEmail.value.trim().length == 0 || inputPass.value.trim().length == 0 || inputRepeatPass.value.trim().length == 0) { // Aqui si queda algun campo vacio
            Swal.fire({
                html: `Debe rellenar todos los campos para poder reestablecer su contraseña`,
                icon: 'warning'
            })
            return

        } else if (getUserEmail.data[0]._id != id) { // Aqui si la relacion entre el ID de la URL y el ID de DB no coinciden
            Swal.fire({
                html: `La informacion proporcionada no es valida`,
                icon: 'error'
            })
            return

        } else if (inputPass.value.trim() != inputRepeatPass.value.trim()) { // Aqui si las contraseñas no coinciden entre ellas
            Swal.fire({
                html: `Las contraseñas no coinciden entre ellas, debe escribir la misma contraseña en ambos campos`,
                icon: 'warning'
            })
            return

        } else if (!regexPassword.test(inputPass.value.trim()) || inputPass.value.trim().length < 8) { // Aqui si la contraseña no tiene el formato adecuado
            Swal.fire({
                html: `La contraseña no tiene el formato adecuado, debe tener al menos 8 caracteres, una letra mayuscula, una letra minuscula, un numero y uno de los siguientes simbolos: @ # % & _`,
                icon: 'warning'
            })
            return

        } else { // Aqui si todo esta bien y se puede reestablecer la contraseña
            const newPassSha = sha256(inputPass.value.trim())
            const userUpdateNewPass = await userUpdate(id, {'password': newPassSha})

            Swal.fire({
                html: `La contraseña de su usuario se ha reestablecido, ahora puede volver a iniciar sesion<br><br>Si tiene alguna duda puede contactar con el soporte de <i>Michipedia</i> usando el formulario de contacto`,
                icon: 'success'
            })

            inputEmail.value = ''
            inputPass.value = ''
            inputRepeatPass.value = ''

            navigate('/login')
        }

    }









    // Funcion para enviar un email a traves del formulario de contacto
    async function sendEmail() {
        const inputText = document.getElementById('newPass-contactForm-text')

        const getUserID = await getUserByID(id)

        const sendingEmailContacto = await userEmailContact({'email': getUserID.data.email, 'message': inputText.value.trim()})

        if (sendingEmailContacto.data == true) { // Comprobar si se ha enviado bien y poner un mensaje tanto si esta bien como si esta mal
            Swal.fire({
                html: `Su mensaje ha sido enviado correctamente al soporte de <i>Michipedia</i><br><br>Se pondran en contacto con usted lo antes posible`,
                icon: 'success'
            })
        } else {
            Swal.fire({
                html: `Su mensaje no ha podido ser enviado por un error inesperado en sus datos<br><br>Si continua teniendo problemas use el formulario de contacto facilitado dentro de <i>Michipedia</i>`,
                icon: 'error'
            })
        }
    }










    return (
        <div id="newPass-body">
            <Navbar />

            <div id="newPass-data">
                <p id='newPass-rotulo1'>Formulario para reestablecer contraseña</p>

                <p className='newPass-p-text'>Debe rellenar el formulario con su direccion de correo electronico y la nueva contraseña que desea usar. Recuerde usar una contraseña segura.</p>
                <p className='newPass-p-text'>Si tiene dudas puede ponerse en contacto con el soporte de <i>Michipedia</i> pulsando en el enlace "Abrir formulario de contacto" y podra enviar un mensaje.</p>

                <input type="text" id="newPass-form-email" className='newPass-form-input' placeholder='Correo electronico' />
                <input type="password" id="newPass-form-pass" className='newPass-form-input' placeholder='Nueva contraseña' />
                <input type="password" id="newPass-form-repeatPass" className='newPass-form-input' placeholder='Repetir nueva contraseña' />
                <input type="button" value="Reestablecer" id='newPass-form-button' onClick={newPass} />
            </div>

            <div id="newPass-contactForm">
                <input type="button" id='newPass-contactForm-show' value="Abrir formulario de contacto" onClick={() => { showForm ? setShowForm(false) : setShowForm(true) }} />

                { showForm ? <textarea id='newPass-contactForm-text' placeholder='Escriba su mensaje aqui...' /> : <></> }
                { showForm ? <input type="button" value="Enviar mensaje" id='newPass-contactForm-button' onClick={sendEmail} /> : <></> }
            </div>

            <Footer />
        </div>
    )
}


export {
    NewPass
}