import '../../../scss/gestion/forum/formForum.scss'

import React from 'react'
import { forumCreate } from '../../../api/forumAPI'
import { decodeToken } from '../../../api/userAPI'
import { uploadFile } from '../../../funciones/uploadMulter'
import { API_URL } from '../../../api/API'
import { dateFormat } from '../../../funciones/fecha'
import Swal from 'sweetalert2'



function FormForumGestion() {





    // Funcion para crear un nuevo tema
    async function dataAcept(e) {
        const tokenDecode = await decodeToken()  

        const dataInputTopic = document.getElementById('inputTopic')
        const dataInputDescript = document.getElementById('inputDescript')
        const dataInputIMG1 = document.getElementById('inputIMG1')

        let routeFile1 = ''
        let routeFile2 = ''
        let routeFile3 = ''


        Swal.fire({
            html: 'Publicando contenido...',
            icon: 'info',
            showConfirmButton: false
        })


        // Evitar que se suban mas de 3 imagenes
        if (dataInputIMG1.files.length > 3) {
            Swal.fire({
                html: 'Solo se permite un maximo de tres fotos',
                icon: 'warning'
            })
            return
        }


        // Bucle y condicionales para comprobar que solo sean imagenes
        for (let i = 0; dataInputIMG1.files.length > i; i++) {
            if (!validImageTypes(dataInputIMG1.files[i].type)) {
                dataInputIMG1.value = ''
                Swal.fire({
                    text: "Solo se permiten adjuntar imagenes",
                    icon: "error"
                })
                return
            }
        }



        // Condicionales con la opcion de subir una imagen con multer para el topic
        if (dataInputIMG1.files[0] != null && dataInputIMG1.files[0] != undefined) {
            let postIMG1 = await uploadFile(dataInputIMG1.files[0]);
            routeFile1 = `${API_URL}${postIMG1.filePath}`
        }

        if (dataInputIMG1.files[1] != null && dataInputIMG1.files[1] != undefined) {
            let postIMG2 = await uploadFile(dataInputIMG1.files[1]);
            routeFile2 = `${API_URL}${postIMG2.filePath}`
        }

        if (dataInputIMG1.files[2] != null && dataInputIMG1.files[2] != undefined) {
            let postIMG3 = await uploadFile(dataInputIMG1.files[2]);
            routeFile3 = `${API_URL}${postIMG3.filePath}`
        }


        

        const arrayNewTopic = {'topic': dataInputTopic.value,
                            'message': dataInputDescript.value,
                            'img': [routeFile1, routeFile2, routeFile3],
                            'dateString': await dateFormat(Date.now()),
                            'dateStringUpdate': await dateFormat(Date.now()),
                            'response': [],
                            'username': {'username': tokenDecode.data.username, 'ID': tokenDecode.data.id, 'IMG': tokenDecode.data.img}
        }


        // Condicional para evitar que se guarde un tema sin escribir todos los datos (Imagenes exentas)                            
        if (dataInputTopic.value.length > 1 && dataInputDescript.value.length > 10) {
            
                const newDataTopic = await forumCreate(arrayNewTopic)
                
                // Condicional para que depende de como se ha creado el registro aparezca un mensaje u otro
                if(newDataTopic != undefined && newDataTopic != null && newDataTopic.data._id.length > 10 ) {
                    Swal.fire({
                        title: "Registro creado",
                        text: `El tema ${dataInputTopic.value} ha sido creada correctamente`,
                        icon: "success"
                    });

                    // Vaciar todos los campos para evitar crear un tema por error
                    dataInputTopic.value = ''
                    dataInputDescript.value = ''
                    dataInputIMG1.value = ''

                } else {
                    Swal.fire({
                        title: "Error de registro",
                        text: `El tema ${dataInputTopic.value} no ha podido ser creada, revise sus datos y vuelva a intentarlo`,
                        icon: "error"
                    });
                }

        } else {
            Swal.fire({
                title: "Faltan datos",
                text: `Es necesario que rellene todos los campos para poder crear un nuevo tema`,
                icon: "error"
            });
        }
        
    }





    return (

        <div id="formForumGestion-body">

            <div id="formForumGestion-data">

                <input type="text" className='formForumGestion-input' placeholder='Tema' id="inputTopic" />
                <textarea className='formForumGestion-input' placeholder='Escriba su mensaje nuevo aqui...' id="inputDescript" rows={8} />
                <input type="file" className='formForumGestion-input' id="inputIMG1" />

                <input type="button" id='formForumGestion-acept' value="Aceptar datos" onClick={dataAcept} />

            </div>

        </div>

    )
}



export {
    FormForumGestion
}