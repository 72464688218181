import '../scss/userPanel/userPanelPage.scss'

import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { decodeToken } from '../api/userAPI'
import Swal from 'sweetalert2'
import { ProveedorContexto } from '../context'

import { Navbar } from '../componentes/navbar'
import { UserPanelButtonMenu } from '../componentes/userPanel/userPanel-buttonMenu'
import { UserPanelData } from '../componentes/userPanel/userPanelData'
import { Footer } from '../componentes/footer'





function UserPanelPage() {
    const navigate = useNavigate()




        // useEffect para enviar a la parte superior de la pagina una vez que se entra en ella 
        useEffect(() => {
            window.scrollTo({
                top: 0,
            })
        }, [])




    // useEffect para mandar a Login si no esta logueado
    useEffect(() => {

        validateUserLogin()
        async function validateUserLogin() {
            const token = await decodeToken()

            if (token.data.username == undefined) {
                Swal.fire({
                    html: "Debe esta logueado para entrar en el panel de usuario",
                    icon: "warning"
                })

                navigate('/login')
            }
        }

    }, [])




    return (
        <div id="backgroundPage">
            <ProveedorContexto>


                <div id="userPanelPage-body">
                    <Navbar />

                    <div id="userPanelPage-data">
                        <UserPanelButtonMenu />
                        <UserPanelData />
                    </div>

                    <Footer />
                </div>


            </ProveedorContexto>
        </div>

    )
}


export {
    UserPanelPage
}