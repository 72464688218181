import dotenv from 'dotenv';
import { API_URL } from "./API.js"
import { getCookie } from '../funciones/token.js';



async function getFact() {
  let data = await fetch(`${API_URL}/fact`);
  data = await data.json();
  return (data)
}


async function getOneFact() {
  let data = await fetch(`${API_URL}/fact/unit`);
  data = await data.json();
  return (data)
}


async function getFactByID(id) {
  let data = await fetch(`${API_URL}/fact/fimd/${id}`);
  data = await data.json();
  return (data)
}


async function getFactByProperty(property, value) {
  try {
    const post = await fetch(`${API_URL}/fact/find`,
      {
        method: 'POST',
        headers: { 
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ property, value })
    });

    if (!post.ok) {
      //throw new Error(`Error al buscar el data 1 (HTTP ${post.status})`);
    }

    const result = await post.json();
    //console.log('Data buscado:', result);
    return (result)
  } catch (error) {
    //console.error('Error al buscar el data 2 :', error);
  }
}




async function factCreate(data) {
  try {
    const response = await fetch(`${API_URL}/fact`,
      {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al crear los fact (HTTP ${response.status})`);
    }

    const result = await response.json();
    //console.log('fact creado:', result);
    return result
  } catch (error) {
    console.error('Error al crear los fact 2:', error);
  }
}




  async function factDelete(id) {
    try {
      const response = await fetch(`${API_URL}/fact/remove/${id}`, {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`Error al eliminar la data (HTTP ${response.status})`);
      }
  
      const result = await response.json();
      //console.log('data eliminada:', result);
    } catch (error) {
      console.error('Error al eliminar la data:', error);
    }
  }



  
  async function factUpdate(id, data) {
    try {
      const response = fetch(`${API_URL}/fact/update/${id}`,
        {
          method: 'POST',
          headers: { 
            'Authorization': `${getCookie('token')}`,
            'Content-type': 'application/json',
          },
            body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        throw new Error(`Error al actualizar 1 (HTTP ${response.status})`);
      }
  
      const result = await response.json();
      console.log('actualizado:', result);
    } catch (error) {
      console.error('Error al actualizar 2:', error);
    }
  }




  export {
    getFact,
    getOneFact,
    getFactByID,
    getFactByProperty,
    factCreate,
    factDelete,
    factUpdate
  }
