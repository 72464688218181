import '../../scss/search/advancedForm.scss'

import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getRazaByProperty } from '../../api/razaAPI'





function SearchAdvancedForm() {
    const navigate = useNavigate()

    const [dataFind, setDataFind] = useState(null)







    // Funcion para hacer las busquedas con el boton
    async function searchAdvance(e) {
        const inputCountry = document.getElementById('inputCountry')
        const inputLife = document.getElementById('inputLife')
        const inputWeight = document.getElementById('inputWeight')
        const inputFamiliar = document.getElementById('inputFamiliar')
        const inputStrange = document.getElementById('inputStrange')
        const inputPets = document.getElementById('inputPets')
        const inputEnjoy = document.getElementById('inputEnjoy')
        const inputMeow = document.getElementById('inputMeow')
        const inputIntelligence = document.getElementById('inputIntelligence')


        // Creacion de variables vacias para usar en las busquedas
        let searchCountry = []
        let searchLife = []
        let searchWeight = []
        let searchFamiliar = []
        let searchStrange = []
        let searchPets = []
        let searchEnjoy = []
        let searchMeow = []
        let searchIntelligence = []
        let dataFinal = []
        let count = 0


        // Busquedas individuales
        inputCountry.value != '' ? searchCountry = await getRazaByProperty('country', inputCountry.value) : count++
        inputLife.value != '' ? searchLife = await getRazaByProperty('years', inputLife.value) : count++
        inputWeight.value != '' ? searchWeight = await getRazaByProperty('weight', inputWeight.value) : count++
        inputFamiliar.value != '' ? searchFamiliar = await getRazaByProperty('family_friendly', inputFamiliar.value) : count++
        inputStrange.value != '' ? searchStrange = await getRazaByProperty('stranger_friendly', inputStrange.value) : count++
        inputPets.value != '' ? searchPets = await getRazaByProperty('other_pets_friendly', inputPets.value) : count++
        inputEnjoy.value != '' ? searchEnjoy = await getRazaByProperty('playfulness', inputEnjoy.value) : count++
        inputMeow.value != '' ? searchMeow = await getRazaByProperty('meowing', inputMeow.value) : count++
        inputIntelligence.value != '' ? searchIntelligence = await getRazaByProperty('intelligence', inputIntelligence.value) : count++



        // Usar todas las busquedas para hacer las combinaciones
        searchCountry.data != undefined ? dataFinal.push(...searchCountry.data) : null
        searchLife.data != undefined ? dataFinal.push(...searchLife.data) : null
        searchWeight.data != undefined ? dataFinal.push(...searchWeight.data) : null
        searchFamiliar.data != undefined ? dataFinal.push(...searchFamiliar.data) : null
        searchStrange.data != undefined ? dataFinal.push(...searchStrange.data) : null
        searchPets.data != undefined ? dataFinal.push(...searchPets.data) : null
        searchEnjoy.data != undefined ? dataFinal.push(...searchEnjoy.data) : null
        searchMeow.data != undefined ? dataFinal.push(...searchMeow.data) : null
        searchIntelligence.data != undefined ? dataFinal.push(...searchIntelligence.data) : null


        // Función para crear un string único de cada objeto (usando JSON.stringify)
        const getKey = obj => JSON.stringify(obj);

        // Usamos un Map para contar las ocurrencias de cada objeto
        const occurrences = dataFinal.reduce((map, obj) => {
        const key = getKey(obj);
        map.set(key, (map.get(key) || 0) + 1);
        return map;
        }, new Map());

        // Filtrar los objetos que están repetidos
        const repeatedObjects = dataFinal.filter(obj => occurrences.get(getKey(obj)) > 1);

        // Si deseas eliminar duplicados en el resultado final pero solo si hay mas de un campo de busqueda
        let uniqueRepeatedObjects
        if (count == 8) {
            uniqueRepeatedObjects = dataFinal
        } else {
            uniqueRepeatedObjects = [...new Set(repeatedObjects.map(getKey))].map(str => JSON.parse(str));
        }

        

        // Condicional para que cuando no haya ninguna raza con la busqueda, aparezca un rotulo para avisarlo
        if (uniqueRepeatedObjects.length == 0) {
            setDataFind('Sin coincidencias en su busqueda')
        } else {
            // Metodo Map para mostrar los datos en pantalla
            const getRazaMap = uniqueRepeatedObjects.map((data) => 
                <div id="searchResult-div" key={data._id} onClick={() => {navigate(`/raza/${data._id}`)}}>
                    <img src={data.img[0]} id='searchResult-div-img' />
                    <p id='searchResult-div-raza'>{data.raza}</p>
                </div>
            )
            setDataFind(getRazaMap)
        }

    }







    // Funcion para limpiar todos los inputs y dejarlos vacios
    async function resetAdvance(e) {
        const inputCountry = document.getElementById('inputCountry')
        const inputLife = document.getElementById('inputLife')
        const inputWeight = document.getElementById('inputWeight')
        const inputFamiliar = document.getElementById('inputFamiliar')
        const inputStrange = document.getElementById('inputStrange')
        const inputPets = document.getElementById('inputPets')
        const inputEnjoy = document.getElementById('inputEnjoy')
        const inputMeow = document.getElementById('inputMeow')
        const inputIntelligence = document.getElementById('inputIntelligence')

        inputCountry.value = ''
        inputLife.value = ''
        inputWeight.value = ''
        inputFamiliar.value = ''
        inputStrange.value = ''
        inputPets.value = ''
        inputEnjoy.value = ''
        inputMeow.value = ''
        inputIntelligence.value = ''

        setDataFind(null)
    }









    return (

        <div id="advancedForm-body">

            <div id="advancedForm-form">

                <div id="advancedForm-form-inputs">
                    <input type="text" id="inputCountry" placeholder='Pais' onChange={searchAdvance} />
                    <input type="number" id="inputLife" min={1} max={20} placeholder='Esperanza de vida' onChange={searchAdvance} />
                    <input type="number" id="inputWeight" min={1000} max={20000} placeholder='Peso maximo' onChange={searchAdvance} />
                    <input type="number" id="inputFamiliar" min={1} max={5} placeholder='Comportamiento familiar' onChange={searchAdvance} />
                    <input type="number" id="inputStrange" min={1} max={5} placeholder='Comportamiento con extraños' onChange={searchAdvance} />
                    <input type="number" id="inputPets" min={1} max={5} placeholder='Comportamiento con animales' onChange={searchAdvance} />
                    <input type="number" id="inputEnjoy" min={1} max={5} placeholder='Jugueton' onChange={searchAdvance} />
                    <input type="number" id="inputMeow" min={1} max={5} placeholder='Maullador' onChange={searchAdvance} />
                    <input type="number" id="inputIntelligence" min={1} max={5} placeholder='Inteligencia' onChange={searchAdvance} />
                </div>

                <div id="advancedForm-form-buttons">
                    <input type="button" value="Limpiar datos" id='inputReset' onClick={resetAdvance} />
                </div>

            </div>

            <div id='advancedForm-data'>
                {dataFind}
            </div>

        </div>

    )
}



export {
    SearchAdvancedForm
}