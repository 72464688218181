import '../scss/search/search.scss'

import React from 'react'
import { useEffect } from 'react'
import { Navbar } from '../componentes/navbar'
import { SearchForm } from '../componentes/search/searchForm'
import { Footer } from '../componentes/footer'




function SearchPage() {




        // useEffect para enviar a la parte superior de la pagina una vez que se entra en ella 
        useEffect(() => {
            window.scrollTo({
                top: 0,
            })
        }, [])




    return(

        <div id="search-body">

            <Navbar />

            <SearchForm />


            <Footer />

        </div>

    )
}



export {
    SearchPage
}