import '../../scss/gestion/gestionButtons.scss'


import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { ContextoCompartido } from '../../context'



function GestionButtons() {
    const { gestionComponent1 } = useContext(ContextoCompartido);
    const { setGestionComponent2 } = useContext(ContextoCompartido);

    const [hidden, setHidden] = useState('hidden')

    const [inputNew, setInputNew] = useState('')
    const [inputUpdate, setInputUpdate] = useState('')




    useEffect(() => {
        if (gestionComponent1 === '' || gestionComponent1 != '') {
            gestionComponent1 === '' ? setHidden('hidden') : setHidden('')
            setInputNew('')
            setInputUpdate('')
        }
    }, [gestionComponent1])
    



    // Funcion para poner estilos activados y para poner el formulario necesario en el componente secundario
    async function activateButton(e) {
        const buttonValue = e.target.value 
        setGestionComponent2(buttonValue)

        // Condicional para poder mantener el boton activado mientras se este usando
        if (buttonValue === 'Nuevo') {
            setInputNew('active')
            setInputUpdate('')

        } else if (buttonValue === 'Administrar') {
            setInputNew('')
            setInputUpdate('active')

        } else {
            setInputNew('')
            setInputUpdate('')
        }



    }





    return (

        <div id="gestionButtons-body">

            <div id="gestionButtons-buttons">

                <p className={hidden} id='gestionButtons-rotulo'>Gestion de <b> {gestionComponent1} </b> </p>

                <input type="button" className={`gestionButtons-button ${hidden}`} id={inputNew} value="Nuevo" onClick={activateButton} />
                <input type="button" className={`gestionButtons-button ${hidden}`} id={inputUpdate} value="Administrar" onClick={activateButton} />

            </div>

        </div>

    )
}



export { 
    GestionButtons
}