import '../../../scss/userPanel/userPanel-messageIndex.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { dateFormat, parseDate } from '../../../funciones/fecha'
import { API_URL } from '../../../api/API'
import { UserPanelMessageDetail } from './userPanel-messageDetail'

import { decodeToken } from '../../../api/userAPI'
import { getChatByProperty } from '../../../api/chatAPI'
import { getUserByProperty } from '../../../api/userAPI'
import { getChatNotiByProperty } from '../../../api/chatNotiAPI'




function UserPanelMessageIndex() {
    const [reboot, setReboot] = useState(true)
    const [showChat, setShowChat] = useState(null)
    const [activeChats, setActiveChats] = useState(null)
    const [userSearch, setUserSearch] = useState(null)
    const [showBackButton, setShowBackButton] = useState(false)
    const [rotuloVoid, setRotuloVoid] = useState('')
    const [dataDestiny, setDataDestiny] = useState(null)







    // useEffect para traer los datos de los chats que tiene activos
    useEffect(() => {

        getChatsData()
        async function getChatsData() {
            const token = await decodeToken()

            // Buscar las notificaciones que tiene el usurio logueado
            const getChatNoti = await getChatNotiByProperty('userDestinyID', token.data.id)

            // Traer los chats con un getByProperty de ambos userID
            const getChats1 = await getChatByProperty('user1', token.data.id)
            const getChats2 = await getChatByProperty('user2', token.data.id)

            // condicional para mostrar o no el rotulo de que no hay chats activos
            if (getChats1.data.length == 0 && getChats2.data.length == 0) {
                setRotuloVoid('No hay conversaciones activas')
                return
            } else {
                setRotuloVoid('')
            }

            // Juntar ambos arrays de chats
            let getChatsAll = [...getChats1.data, ...getChats2.data]
            
            // Ordenar los topics por fecha de actualizacion
            const sortedData = getChatsAll.sort((a, b) => new Date(b.dateUpdate) - new Date(a.dateUpdate));

            // Hacer un metodo map para mostrar todo en pantalla
            const getChatsAllMap = sortedData.map(data => {
                const getChatNotiSome = getChatNoti.data.some(noti => noti.chatID === data._id) 
                    ? 'chats-div-chatNoti-yes' 
                    : 'chats-div-chatNoti-no';
                
                return (
                    <div 
                        id="chats-div" 
                        key={data._id} 
                        className={getChatNotiSome} 
                        onClick={() => {
                            setShowChat(<UserPanelMessageDetail id={data._id} userid={data.user1 === token.data.id ? data.user2 : data.user1} />);
                            setUserSearch(null);
                            setShowBackButton(true);
                            setDataDestiny({
                                'username': data.user1 === token.data.id ? data.username2 : data.username1, 
                                'img': data.user1 === token.data.id ? data.user2IMG : data.user1IMG
                            });
                        }}
                    >
                        {data.user1 === token.data.id ? <img src={data.user2IMG} alt="User 2" /> : <img src={data.user1IMG} alt="User 1" />}
                        {data.user1 === token.data.id ? <p>{data.username2}</p> : <p>{data.username1}</p>}
                    </div>
                )
            })

            setActiveChats(getChatsAllMap)
            setShowChat(null)
            setReboot(false)

        }

    }, [reboot])







    // Funcion para buscar usuarios por username
    async function userSearchMessage() {
        const token = await decodeToken()
        const inputSearch = document.getElementById('userPanelMessage-userSearch')

        // Condicional para vaciar la busqueda si se elimina los datos del input
        if (inputSearch.value.length == 0) {
            setUserSearch(null)
            return
        }

        // Busqueda por propiedad (Username)
        let getUserSearch = await getUserByProperty('username', inputSearch.value)
        getUserSearch = getUserSearch.data

        // Ordenar los usuarios por orden alfabetico
        getUserSearch.sort((a, b) => {
            if (a.username.toLowerCase() < b.username.toLowerCase()) {
              return -1;
            }
            if (a.username.toLowerCase() > b.username.toLowerCase()) {
              return 1;
            }
            return 0;
        })

        // Metodo filter para que no se pueda buscar al usuario logueado
        const getUserSearchFilter = getUserSearch.filter(data => data._id != token.data.id)

        // Metodo map para mostrar todos los usuarios que han aparecido en la busqueda
        const getUserSearchMap = getUserSearchFilter.map(data =>
            <div id="userPanelSearch-div" key={data._id} onClick={() => { setShowChat(<UserPanelMessageDetail id={null} userid={data._id} />); setUserSearch(null); setShowBackButton(true); setUserSearch(null); setDataDestiny(data) }}>
                <img src={data.img} id='userPanelSearch-div-img'/>
                <p id='userPanelSearch-div-username'>{data.username}</p>
            </div>
        )
        setUserSearch(getUserSearchMap)
        setShowChat(null)

    }









    return(
        <div id="userPanelMessageIndex-body">

            <div id="userPanelMessageIndex-userSearch">
                { !showBackButton ? <input type="text" id="userPanelMessage-userSearch" placeholder='Buscar usuario...' onChange={userSearchMessage} /> : <></> }
                { !showBackButton ? userSearch : <></> }
            </div>


            <div id="userPanelMessageIndex-back">
                { showBackButton ? <div id="userPanelMessageIndex-header"> <input type="button" id='userPanelMessageIndex-backButton' value="Atras" onClick={() => { setShowChat(null); setReboot(true); setShowBackButton(false); setUserSearch(null); setReboot(true) }} /> <img src={dataDestiny.img} id='userPanelMessageIndex-header-img' /> <p id='userPanelMessageIndex-header-username'>{dataDestiny.username}</p> </div> : <></> }
                { !showBackButton ? <p id='userPanelMessageIndex-rotulo'>{rotuloVoid}</p> : <></> }
                { !showBackButton ? <div id='userPanelMessageIndex-chats'>{activeChats}</div> : <></> }

            </div>

            
            {showChat}
            


        </div>
    )
}



export {
    UserPanelMessageIndex
}