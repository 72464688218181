import '../../scss/login/loginValidate.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Navbar } from '../../componentes/navbar'
import { Footer } from '../../componentes/footer'

import { getUserByID, userValidate } from '../../api/userAPI'
import { userEmailContact } from '../../api/sendEmailAPI'



function UserValidateEmail() {
    const { id } = useParams()
    const navigate = useNavigate()

    const [data, setData] = useState(null)
    const [button, setButton] = useState(false)
    const [contacto, setContacto] = useState(false)



    // useEffect para cargar los datos
    useEffect(() => {

        validateData()
        async function validateData() {
            const getDataUser = await getUserByID(id)

            // Condicional para saber en que estado esta la operacion
            if (getDataUser.data == undefined || getDataUser.data == null || getDataUser.data.length == 0) { // Aqui en el caso de que no encuentre al usuario
                setData(`El usuario al que hace referencia no existe, si cree que es un error pongase en contacto con el soporte de Michipedia`)
                setContacto(true)
                setButton(false)

            } else if (getDataUser.data.delete == true) { // Aqui en el caso de que el usuario este eliminado
                setData(`El usuario ${getDataUser.data.username} no es un usuario activo, si cree que es un error pongase en contacto con el soporte de Michipedia`)
                setContacto(true)
                setButton(false)

            } else if (getDataUser.data.validateEmail == true) { // Aqui si ya se ha validado
                setData(`El enlace de validacion al que hace referencia ha caducado`)
                setContacto(false)
                setButton(false)

            } else { // Aqui si esta todo bien
                // Se actualiza para poner como validado
                const validateUser = await userValidate(id)

                // Mensaje a mostrar para el usuario
                setData(`El usuario ${getDataUser.data.username} ha sido validado correctamente`)
                setContacto(false)
                setButton(true)

                
            }
        }

    }, [])






    // Funcion para enviar un mensaje a los administradores
    async function sendMessage() {
        const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

        const inputEmail = document.getElementById('validateEmail-form-email')
        const inputText = document.getElementById('validateEmail-form-text')

        // Condicionales para que el mensaje se pueda enviar de forma correcta
        if (inputEmail.value.length == 0 || inputText.value.length == 0) { // Faltan campos por rellenar
            Swal.fire({
                html: `Debe rellenar los dos campos para poder enviar su mensaje`,
                icon: 'warning'
            })
            return

        } else if (!regexEmail.test(inputEmail.value)) { // El email no tiene una estructura correcta
            Swal.fire({
                html: `El email no tiene la estructura correcta`,
                icon: 'warning'
            })
            return

        } else { // Todo bien, se manda el correo
                Swal.fire({
                    title: "¿Enviar mensaje?",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Enviar"
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                                Swal.fire({
                                title: "Mensaje enviado",
                                html: `Nuestro equipo de soporte revisara su mensaje y se pondra en contacto con usted lo antes posible`,
                                icon: "success"
                                })

                                // Envio del email
                                const sendingEmail = await userEmailContact ({'email': inputEmail.value, 'message': inputText.value})

                                // Ocultar campos de formulario
                                inputEmail.value = ''
                                inputText.value = ''
                                setContacto(false)
                            }
                    })
        }

    }





    return (

        <div id="validateEmail-body">
            <Navbar />


            <div id="validateEmail-data">
                <p> {data} </p>
                { button ? <input type="button" value="Ir a login" id='validateEmail-button-login' onClick={() => {navigate('/')}} /> : <></> }

                { contacto ? <input type="text" id="validateEmail-form-email" placeholder='Correo Electronico' /> : <></> }
                { contacto ? <textarea id="validateEmail-form-text" placeholder='Escriba aqui su mensaje...' /> : <></> }
                { contacto ? <input type="button" id='validateEmail-form-button' value="Enviar mensaje" onClick={sendMessage} /> : <></> }
            </div>
        
        
            <Footer />
        </div>

    )
}



export {
    UserValidateEmail
}