import '../../../scss/gestion/user/listUser.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { getUser, getUserByID, getUserByProperty, userUpdate } from '../../../api/userAPI'
import { getForumByProperty, forumUpdate } from '../../../api/forumAPI'
import { userDelByAdmin, userReactByAdmin } from '../../../api/sendEmailAPI'
import { emailCreate } from '../../../api/emailAPI'
import Swal from 'sweetalert2'
import { sha256 } from 'js-sha256'
import { Modal } from '../../modal'
import { deleteFile } from '../../../funciones/deleteMulter'
import { uploadFileUser } from '../../../funciones/uploadMulter'
import { validImageTypes } from '../../../funciones/resizeIMG'
import { dateFormat } from '../../../funciones/fecha'
import { decodeToken } from '../../../api/userAPI'
import { API_URL } from '../../../api/API'



function ListUserGestion() {
    const [reboot, setReboot] = useState(true)
    const [dataUser, setDataUser] = useState('')

    const [modalData, setModalData] = useState(null)
    const [modalUsername, setModalUsername] = useState('')
    const [modalEmail, setModalEmail] = useState('')
    const [modalRol, setModalRol] = useState('')
    const [modalDelete, setModalDelete] = useState()
    const [modalValidate, setModalValidate] = useState('')
    const [modalID, setModalID] = useState('')

    const [final, setFinal] = useState(20)
    const [total, setTotal] = useState(0)


    // Expresiones regulares para poder validar los datos nuevos que el usuario quiera cambiar
    const regexUsername = /^[A-Za-z0-9]+$/
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#%&_])[a-zA-Z0-9@#%&_]+$/







    // useEffect para sacar todas las user, meterlas en un map y mostrarlas en el return
    useEffect(() => {
        if(reboot) {

            getAllUser()
            async function getAllUser() {
                const user = await getUser()

                // Separar la informacion en bloques de 20 para hacer scroll infinito
                setTotal(user.data.length)
                const nextItems = user.data.slice(0, final)

                const userMap = nextItems.map((data) => 
                    <div id="userMap-div" key={data._id}>
                        <p id='userMap-name'>{data.username}</p>
                        <input className='userMap-button userMap-button-update' id={data._id} type="button" value="Modificar" onClick={() => {userMapUpdate(data)}} />
                        { data.delete ? <input className='userMap-button userMap-button-delete' id={data._id} type="button" value="Recuperar" onClick={() => {userRecuperate(`${data._id}`)}} /> : <input className='userMap-button userMap-button-delete' id={data._id} type="button" value="Eliminar" onClick={UserMapDelete} /> }
                    </div>
                )
                setDataUser(userMap)
                setReboot(false)
            }
        }
    }, [reboot])





    
    // Funcion de scroll infinito para detectar cuando llega al final de la pagina
    window.addEventListener('scroll', () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight + 800) {
            if (total > final) {
                setFinal(prevFinal => prevFinal + 20)
                setReboot(true)
            }
        }
    })






    // Funcion para recuperar un usuario eliminado
    async function userRecuperate(id) {
        const recuperate = await userUpdate(id, {'delete': false}); 
        const getDataUser = await getUserByID(id)
        const token = await decodeToken()

        const sendEmailReactivate = await userReactByAdmin({'email': getDataUser.data.email, 'username': getDataUser.data.username})

        // Guardar en DB que este email ha sido enviado
        const ArrayEmailReactDB = {'username': getDataUser.data.username,
                                    'userID': getDataUser.data._id,
                                    'adminname': token.data.username,
                                    'dateString': await dateFormat(Date.now()),
                                    'message': `El usuario ${getDataUser.data.username} ha sido reactivado por ${token.data.username}`}

        const sendEmailReactDB = await emailCreate(ArrayEmailReactDB)

        setDataUser(null)
        setReboot(true);
    }






    // Funcion para modificar los datos de una user
    async function userMapUpdate(data){
        setModalUsername(data.username)
        setModalEmail(data.email)
        setModalID(data._id)
        setModalRol(data.rol)
        setModalDelete(data.delete)
        setModalValidate(data.validateEmail)
        setModalData(data)
    }






    // Funcion para eliminar un user al completo
    async function UserMapDelete(e) {
        const buttonID = e.target.id
        const nameUser = await getUserByID(buttonID)
        const token = await decodeToken()

        // Sacar las rutas de las IMG para eliminar tambien
        let arrayIMG = nameUser.data.img
                

        Swal.fire({
            title: `Se va a eliminar el usuario ${nameUser.data.username}`,
            text: "Esta accion no se se puede revertir",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Eliminar"
        }).then((result) => {
            if (result.isConfirmed) {

                // Funcion para eliminar un usuario creado
                removeUser(buttonID)
                async function removeUser(buttonID) {
                    // Funcion map para eliminar las imagenes de multer y condicional para que no de error si no hay foto
                    if (arrayIMG != '') {
                        const filename = arrayIMG.split('/').pop()

                        // Condicional para evitar que elimine la imagen none.png
                        if (!filename.includes('none')) {
                            const removeFileIMG = await deleteFile(filename)
                        }
                    }   
                    
                    
                    // Cerrar todos los temas del foro que haya creado el usuario
                    const getForumByUser = await getForumByProperty('username.ID', buttonID)
                    const getForumByUserMap = getForumByUser.data.map(async (data) => { const closeForum = await forumUpdate(data._id, {'open': false}) })


                    // Modificar el usuario para eliminar datos y dejarlo como bloqueado
                    const arrayUpdateUser = {'img': `${API_URL}/uploads/none.png`,
                                            'favs': [],
                                            'delete': true }

                    const updateUserDelete = await userUpdate(buttonID, arrayUpdateUser)

                    // Enviar email avisando al usuario que su cuenta ha sido eliminada por un administrador
                    const sendEmail = await userDelByAdmin({'email': nameUser.data.email, 'username': nameUser.data.username})

                    // Guardar en DB que este email ha sido enviado
                    const ArrayEmailDB = {'username': nameUser.data.username,
                                            'userID': nameUser.data._id,
                                            'adminname': token.data.username,
                                            'dateString': await dateFormat(Date.now()),
                                            'message': `El usuario ${nameUser.data.username} ha sido dado de baja por ${token.data.username}`}

                    const sendEmailDataDB = await emailCreate(ArrayEmailDB)
                }


                Swal.fire({
                    title: "Eliminado correctamente",
                    text: `El usuario ${nameUser.data.username} ha sido eliminado`,
                    icon: "success"
                })

                setDataUser(null)
                setReboot(true)
            }
        })           
    }








    // Funcion para actualizar un usuario, el boton viene del modal
    async function updateUserButton(e) {
        const inputModalUsername = document.getElementById('newModalUsername')
        const inputModalEmail = document.getElementById('newModalEmail')
        const inputModalRol = document.getElementById('newModalRol')
        const inputModalDelete = document.getElementById('newModalDelete')
        const inputModalValidate = document.getElementById('newModalValidate')
        let inputModalPass = document.getElementById('newModalPass')
        let inputModalRepeatPass = document.getElementById('newModalRepeatPass')
        let inputModalIMG = document.getElementById('newModalIMG')

        const getUserIMG = await getUserByID(modalID)

        // Comprobar que no se use un username o email ya en uso
        const findUser = await getUserByProperty('username', inputModalUsername.value)
        const findEmail = await getUserByProperty('email', inputModalEmail.value)


        if (inputModalUsername.value != getUserIMG.data.username) { // Comprobar si esta dejando el mismo nombre de usuario
            if (findUser.data.length != undefined && findUser.data.length != null && findUser.data.length > 0) {
                Swal.fire({
                    text: "Su nuevo nombre de usuario ya esta en uso",
                    icon: "error"
                })
                inputModalPass.value = ''
                inputModalRepeatPass.value = ''
                return
            }
        }


        if (inputModalEmail.value != getUserIMG.data.email) { // Comprobar si esta dejando el mismo email
            if (findEmail.data.length == undefined || findEmail.data.length == null || findEmail.data.length > 0) {
                Swal.fire({
                    text: "Su nuevo email no es valido",
                    icon: "error"
                })
                inputModalPass.value = ''
                inputModalRepeatPass.value = ''
                return
            }
        }



        // Condicional para las comprobaciones para la imagen de perfil
        if (inputModalIMG.files[0] != undefined && inputModalIMG.files[0] != null) {

            if (validImageTypes(inputModalIMG.files[0].type)) {
                // Condicional para que cuando no haya cargada una imagen, no ejecute la funcion para evitar errores
                if (inputModalIMG != undefined && inputModalIMG != null) {
                    // Primero eliminar la imagen anterior evitando eliminar la de noche.png
                    let filename = getUserIMG.data.img.replace(`${API_URL}/uploads/`, "");
                    filename == 'none.png' ? null : deleteFile(filename)                    

                    // Crear la nueva imagen
                    inputModalIMG = await uploadFileUser(inputModalIMG.files[0], inputModalUsername.value)
                    inputModalIMG = `${API_URL}${inputModalIMG.filePath}`
                }

            } else {
                inputModalIMG.value = ''
                Swal.fire({
                    text: "Solo se pueden usar imagenes para la foto de perfil",
                    icon: "error"
                    })
                return
            }
        } else {
            inputModalIMG = getUserIMG.data.img
        }


        const arrayNewDataUser = {'username': inputModalUsername.value,
                                'email': inputModalEmail.value,
                                'password': sha256(inputModalPass.value),
                                'img': inputModalIMG,
                                'rol': inputModalRol.value,
                                'delete': inputModalDelete.value,
                                'validateEmail': inputModalValidate.value }


        
        if (inputModalUsername.value.length === 0 || inputModalEmail.value.length === 0 || inputModalPass.value.length === 0 || inputModalRepeatPass.value.length === 0) {
            Swal.fire({
                text: "No se pueden dejar campos vacios",
                icon: "warning"
                })

        } else if (inputModalUsername.value.length < 4) {
            Swal.fire({
                text: "El nombre de usuario debe contener al menos 4 caracteres",
                icon: "warning"
                })

        } else if (!regexEmail.test(inputModalEmail.value)) {
            Swal.fire({
                text: "El correo no tiene una estructura adecuada",
                icon: "warning"
                })

        } else if (inputModalPass.value != inputModalRepeatPass.value) {
            Swal.fire({
                text: "Las contraseñas no coinciden entre ellas",
                icon: "warning"
                })
                inputModalPass.value = ''
                inputModalRepeatPass = ''

        } else if (!regexPassword.test(inputModalPass.value) || inputModalPass.value.length < 8) {
            Swal.fire({
                text: "La contraseña no es valida, debe tener tener al menos 8 caracteres y contener al menos una letra minuscula, una letra mayuscula, un numero y uno de los siguientes simbolos @ # % & _",
                icon: "warning"
                })
                inputModalPass.value = ''
                inputModalRepeatPass = ''                
        } else {
            const updateUserData = await userUpdate(modalID, arrayNewDataUser)

            Swal.fire({
                html: "Los datos de usuario han sido actualizados correctamente",
                icon: "success"
            }).then((result) => {
                if (result.isConfirmed) {
                    inputModalPass.value = ''
                    inputModalRepeatPass.value = ''
                    setReboot(true)
                }
            })
        }
    }




    

    // Funcion para eliminar la foto de perfil pero no tocar nada mas de info, solo dejando la imagen none.png
    async function delIMG(e){

        const getUserIMG = await getUserByID(modalID)

        // Sweet alert para preguntar si se esta de acuerdo
        Swal.fire({
            html: `¿Esta seguro de que desea eliminar la imagen de perfil del usuario <b>${getUserIMG.data.username}</b>?<br><br>Esta accion no se puede revertir`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#3085d6",
            confirmButtonColor: "#d33",
            confirmButtonText: "Eliminar imagen"
          }).then((result) => {
            if (result.isConfirmed) {
                delImagePerfil()
            }
          })


        async function delImagePerfil() {
            // Comprobar que no este vacio el campo de imagen

            if (getUserIMG.data.img == "" || getUserIMG.data.img.includes('none')) { // Esta vacio o tiene none
                Swal.fire({
                    text: "No hay ninguna imagen que eliminar",
                    icon: "info"
                })

            } else { // Se elimina la imagen y se deja la de none.png
                let filename = getUserIMG.data.img.replace(`${API_URL}/uploads/`, "");
                filename == 'none.png' ? null : deleteFile(filename) 

                const updateNoneIMG = await userUpdate(modalID, {'img': `${API_URL}/uploads/none.png`})

                Swal.fire({
                    html: `La imagen del usuario <b>${getUserIMG.data.username}</b> se ha eliminado correctamente`,
                    icon: "success"
                })
            }
        }

    }









    return (

        <div id="listUserGestion-body">

                {dataUser}


                {modalData && 
                
                    <Modal isOpen={true}>

                        <div id="modalUser-modal">
                            <div id="modalUser-form">
                                <input type="text" id="newModalUsername" value={modalUsername} onChange={(e) => {setModalUsername(e.target.value)}} />
                                <input type="text" id="newModalEmail" value={modalEmail} onChange={(e) => {setModalEmail(e.target.value)}} />

                                <div className="newModal-div-select">
                                    <p className="newModal-rotulo">Rol</p>
                                    <select className='newModal-select' id="newModalRol" value={modalRol}>
                                        <option value="user">User</option>
                                        <option value="admin">Admin</option>
                                    </select>
                                </div>

                                <div className="newModal-div-select">
                                    <p className="newModal-rotulo">Usuario eliminado</p>
                                    <select className='newModal-select' id="newModalDelete" value={modalDelete}>
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </select>
                                </div>

                                <div className="newModal-div-select">
                                    <p className="newModal-rotulo">Usuario validado</p>
                                    <select className='newModal-select' id="newModalValidate" value={modalValidate}>
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </select>
                                </div>

                                <input type="file" id="newModalIMG" />
                                <input type="password" id="newModalPass" placeholder='Contraseña' />
                                <input type="password" id="newModalRepeatPass" placeholder='Repetir contraseña' />
                            </div>

                            <div id="modalUser-buttons">
                                <input type="button" value="Actualizar" id='modalUser-button-update' onClick={updateUserButton}/>
                                <input type="button" value="Eliminar foto" id='modalUser-button-delIMG' onClick={delIMG} />
                                <input type="button" value="Cerrar" id='modalUser-button-close' onClick={() => {setModalData(null)}} />
                            </div>
                        </div>

                    </Modal>
                
                }

        </div>

    )
}



export {
    ListUserGestion
}