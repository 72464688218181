import '../../scss/userPanel/userPanelData.scss'


import React from 'react'
import { useContext } from 'react'
import { ContextoCompartido } from '../../context'




function UserPanelData() {
    const { showMenuSelected } = useContext(ContextoCompartido)





    return (

        <div id="userPanelData-body">
            {showMenuSelected}
        </div>

    )
}




export {
    UserPanelData
}