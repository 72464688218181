import dotenv from 'dotenv';
import { API_URL } from "./API.js"
import { getCookie } from '../funciones/token.js';



async function getNoti() {
  let data = await fetch(`${API_URL}/noti`);
  data = await data.json();
  return (data)
}




async function getNotiByID(id) {
  let data = await fetch(`${API_URL}/noti/fimd/${id}`);
  data = await data.json();
  return (data)
}


async function getNotiByProperty(property, value) {
  try {
    const post = await fetch(`${API_URL}/noti/find`,
      {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ property, value })
    });

    if (!post.ok) {
      throw new Error(`Error al buscar el data 1 (HTTP ${post.status})`);
    }

    const result = await post.json();
    //console.log('Data buscado:', result);
    return (result)
  } catch (error) {
    console.error('Error al buscar el data 2 :', error);
  }
}




async function notiCreate(data) {
  try {
    const response = await fetch(`${API_URL}/noti`,
      {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al crear los noti (HTTP ${response.status})`);
    }

    const result = await response.json();
    //console.log('noti creado:', result);
    return result
  } catch (error) {
    console.error('Error al crear los noti 2:', error);
  }
}




  async function notiDelete(id) {
    try {
      const response = await fetch(`${API_URL}/noti/remove/${id}`, {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`Error al eliminar la data (HTTP ${response.status})`);
      }
  
      const result = await response.json();
      //console.log('data eliminada:', result);
    } catch (error) {
      console.error('Error al eliminar la data:', error);
    }
  }



  
  async function notiUpdate(id, data) {
    try {
      const response = fetch(`${API_URL}/noti/update/${id}`,
        {
          method: 'POST',
          headers: { 
            'Authorization': `${getCookie('token')}`,
            'Content-type': 'application/json',
          },
            body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        //throw new Error(`Error al actualizar 1 (HTTP ${response.status})`);
      }
  
      const result = await response.json();
      //console.log('actualizado:', result);
    } catch (error) {
      //console.error('Error al actualizar 2:', error);
    }
  }




  export {
    getNoti,
    getNotiByID,
    getNotiByProperty,
    notiCreate,
    notiDelete,
    notiUpdate
  }
