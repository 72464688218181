import '../../../scss/userPanel/userPanel-noti-new.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { getNotiByProperty, notiDelete, notiUpdate } from '../../../api/notiAPI'
import { getCookie } from '../../../funciones/token'
import { decodeToken } from '../../../api/userAPI'
import { dateFormat } from '../../../funciones/fecha'
import { useNavigate } from 'react-router-dom'
import { FaTrashAlt } from "react-icons/fa";
import Swal from 'sweetalert2'




function UserPanelNotiNew() {
    const navigate = useNavigate()

    const [reboot, setReboot] = useState(true)
    const [dataNoti, setDataNoti] = useState(null)
    const [rotulo, setRotulo] = useState('')
    const [tokenID, setTokenID] = useState('')




        // useEffect para comprobar si el usuario tiene o no notificaciones
        useEffect(() => {

            validateNotification()
            async function validateNotification() {
                // Sacar ID del token
                const token = getCookie('token')
                const tokenDecode = await decodeToken()
                setTokenID(tokenDecode.data.id)
    
                // Buscar todas las notificaciones por el ID del usuario y filtrar por las no vistas
                const getNotiAll = await getNotiByProperty('userID', tokenDecode.data.id)



                // Metodo Map para llevar las notificaciones al return
                // Se usa un primer metodo map con Promise.all para poder hacer un dateFormat asincrono
                const processDates = async () => {
                    return await Promise.all(getNotiAll.data.map(async data => {
                        const formattedDate = await dateFormat(data.date);
                        return { ...data, formattedDate };
                    }))
                }

                // Filtrar unicamente los que sean notificaciones nuevas
                const getNotiAllMapFilter = (await processDates()).filter(data => data.old == false)

                // Ternario para modificar el rotulo en funcion de si hay o no notificaciones
                getNotiAllMapFilter.length > 0 ? setRotulo('Nuevos mensajes en foros') : setRotulo('No hay nuevos mensajes en foros')
                
                // Metodo map para mostrar todo en pantalla
                const getNotiAllMap = getNotiAllMapFilter.map(data => 
                    <div id="getNoti-div" key={data._id} onClick={() => {navigate(`/forum/${data.topicID}`)}}>
                        <p>{data.topic}</p>
                        <p>{data.dateString}</p>
                    </div>
                )
                setDataNoti(getNotiAllMap)
                setReboot(false)
    
            }
    
        },[reboot])



        


        // Funcion para poner en visto todas las notificaciones de golpe
        async function showAll(e) {

            Swal.fire({
                title: "¿Borrar todas las notificaciones?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonColor: "#3085d6",
                confirmButtonColor: "#d33",
                confirmButtonText: "Eliminar todo"
              }).then(async (result) => {
                if (result.isConfirmed) {

                    try {
                        // Buscar todas las notificaciones de este usuario y metodo Map para dejarlas todas en visto
                        const findAllNotis = await getNotiByProperty('userID', tokenID)
                        const findAllNotisMap = findAllNotis.data.map(async data => {
                            const deleteNotis = await notiUpdate(data._id, {'old': true})
                        })  

                        
                        // Buscar todas las notificaciones vistas y eliminar todas menos las 15 ultimas
                        const findNotiOld = await getNotiByProperty('userID', tokenID)
                        const findNotiOldFilter = findNotiOld.data.filter(data => data.old == true)

                        // Condicional para evitar que de error al eliminar datos cuando no hay mas que 15
                        if (findNotiOldFilter.length > 14) {
                            let findNotiOldDel = findNotiOldFilter.sort()

                            // Usar bucle para eliminar el excedente
                            for (let i = 0; (findNotiOldFilter.length - 14) > i; i++) {
                                const findNotiOldRemove = await notiDelete(findNotiOldDel[i]._id)
                            }
                        }


                        Swal.fire({
                        title: "Notificaciones eliminadas",
                        icon: "success"
                        })

                        setReboot(true)

                    } catch (error) {
                        console.log('Errores al eliminar las notis', error)                        
                    }
                }
              })
        }







    return (

        <div id="userPanel-noti-body">

            <div id="userPanel-rotulos">
                <p id='userPanel-noti-rotulo'> { rotulo } </p>
                { rotulo === 'Nuevos mensajes en foros' ? <p id='userPanel-deleteAll' title='Eliminar todo' onClick={showAll}> <FaTrashAlt /> </p> : <></> }
            </div>
             
            { dataNoti }
        </div>

    )
}




export {
    UserPanelNotiNew
}