import '../../scss/userPanel/userPanel-notifications.scss'

import React from 'react'
import { useState } from 'react'
import { UserPanelNotiNew } from './notifications/userPanel-noti-new'
import { UserPanelNotiOld } from './notifications/userPanel-noti-old'






function UserPanelNotifications() {
    const [notiData, setNotiData] = useState(<UserPanelNotiNew />)
    const [activeNew, setActiveNew] = useState('userPanel-noti-active')
    const [activeOld, setActiveOld] = useState('')






    // Funcion para seleccionar las notificaciones nuevas
    async function userPanelSelectNew(e) {
        setNotiData(<UserPanelNotiNew />)
        setActiveNew('userPanel-noti-active')
        setActiveOld('')
    }




    // Funcion para seleccionar las notificaciones antiguas
    async function userPanelSelectOld(e) {
        setNotiData(<UserPanelNotiOld />)
        setActiveNew('')
        setActiveOld('userPanel-noti-active')   
    }
        










    return (

        <div id="userPanel-noti">

            <div id="userPanel-noti-buttons-select">
                <input type="button" value="Nuevo" id={activeNew} className='userPanel-button' onClick={userPanelSelectNew} />
                <input type="button" value="Antiguo" id={activeOld} className='userPanel-button' onClick={userPanelSelectOld} />
            </div>

            <div id="userPanel-noti-dataShow">
                {notiData}
            </div>

        </div>

    )
}


export {
    UserPanelNotifications
}