import '../scss/gestion/gestion.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { ProveedorContexto } from '../context'
import { adminLoginValidate } from '../api/userAPI'
import { getCookie } from '../funciones/token'
import { useNavigate } from 'react-router-dom'
import { Navbar } from '../componentes/navbar'
import { GestionMenu } from '../componentes/gestion/gestionMenu'
import { GestionButtons } from '../componentes/gestion/gestionButtons'
import { GestionResult } from '../componentes/gestion/gestionResult'
import { Footer } from '../componentes/footer'




function GestionPage() {
    const navigate = useNavigate()




        // useEffect para enviar a la parte superior de la pagina una vez que se entra en ella 
        useEffect(() => {
            window.scrollTo({
                top: 0,
            })
        }, [])

        



    // useEffect para la comprobacion de si el usuario tiene rol de admin
    useEffect(() => {

        validateToken()
        async function validateToken() {
            // Capturar token de cookies
            const token = getCookie('token')

            // Meter token en array y mandar a Backend para validar
            const arraySendToken = {'token': token}
            const sendToken = await adminLoginValidate(arraySendToken)

            !sendToken ? navigate('/') : null
        }

    }, [])
    




    return (

        <div id="gestionPage-body">

            <ProveedorContexto>
                <Navbar />


                <div id="gestionPage-data">
                    <GestionMenu />
                    <GestionButtons />
                </div>

                <GestionResult />


                <Footer />
            </ProveedorContexto>

        </div>

    )
}



export {
    GestionPage
}