import dotenv from 'dotenv';
import { API_URL } from "./API.js"
import { getCookie } from '../funciones/token.js';



async function getUser() {
  let data = await fetch(`${API_URL}/user`);
  data = await data.json();
  return (data)
}


async function getUserByID(id) {
  let data = await fetch(`${API_URL}/user/${id}`);
  data = await data.json();
  return (data)
}


async function getUserByProperty(property, value) {
  try {
    const post = await fetch(`${API_URL}/user/find`,
      {
        method: 'POST',
        headers: { 
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ property, value })
    });

    if (!post.ok) {
      throw new Error(`Error al buscar el data 1 (HTTP ${post.status})`);
    }

    const result = await post.json();
    //console.log('Data buscado:', result);
    return (result)
  } catch (error) {
    console.error('Error al buscar el data 2 :', error);
  }
}




async function userCreate(data) {
  try {
    const response = await fetch(`${API_URL}/user`,
      {
        method: 'POST',
        headers: { 
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al crear los user (HTTP ${response.status})`);
    }

    const result = await response.json();
    //console.log('user creado:', result);
    return result
  } catch (error) {
    console.error('Error al crear los user:', error);
  }
}



async function userLogin(data) {
  try {
    const response = await fetch(`${API_URL}/user/login`,
      {
        method: 'POST',
        headers: { 
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al crear el login 1 (HTTP ${response.status})`);
    }

    const result = await response.json();
    //console.log('Login creado:', result);
    return result
  } catch (error) {
    console.error('Error al crear el login 2:', error);
  }
}






async function userLoginValidate(data) {
  try {
    const response = await fetch(`${API_URL}/user/login/validate/user`,
      {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al validar el token 1 (HTTP ${response.status})`);
    }

    const result = await response.json();
    //console.log('Token Validado:', result);
    return result
  } catch (error) {
    console.error('Error al validar el token 2:', error);
  }
}





async function adminLoginValidate(data) {
  try {
    const response = await fetch(`${API_URL}/user/login/validate/admin`,
      {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al validar el token 1 (HTTP ${response.status})`);
    }

    const result = await response.json();
    //console.log('Token Validado:', result);
    return result
  } catch (error) {
    console.error('Error al validar el token 2:', error);
  }
}





async function decodeToken(data) {
  try {
    const response = await fetch(`${API_URL}/user/login/decode`,
      {
        method: 'get',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al decodificar el token 1 (HTTP ${response.status})`);
    }

    const result = await response.json();
    //console.log('Token Decodificado:', result);
    return result
  } catch (error) {
    console.error('Error al decodificar el token 2:', error);
  }
}





  async function userDelete(id) {
    try {
      const response = await fetch(`${API_URL}/user/remove/${id}`, {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`Error al eliminar la data (HTTP ${response.status})`);
      }
  
      const result = await response.json();
      //console.log('data eliminada:', result);
    } catch (error) {
      console.error('Error al eliminar la data:', error);
    }
  }





  async function userValidate(id) {
    try {
      const response = fetch(`${API_URL}/user/validate/${id}`,
        {
          method: 'POST',
          headers: { 
            'Authorization': `${getCookie('token')}`,
            'Content-type': 'application/json',
          },
      });
  
      if (!response.ok) {
        //throw new Error(`Error al actualizar 1 (HTTP ${response.status})`);
      }
  
      const result = await response.json();
      //console.log('actualizado:', result);
    } catch (error) {
      //console.error('Error al actualizar 2:', error);
    }
  }






  async function userUpdate(id, data) {
    try {
      const response = fetch(`${API_URL}/user/update/${id}`,
        {
          method: 'POST',
          headers: { 
            'Authorization': `${getCookie('token')}`,
            'Content-type': 'application/json',
          },
            body: JSON.stringify(data),
      });
  
      if (!response.ok) {
       //throw new Error(`Error al actualizar 1 (HTTP ${response.status})`);
      }
  
      const result = await response.json();
      //console.log('actualizado:', result);
    } catch (error) {
      //console.error('Error al actualizar 2:', error);
    }
  }




  export {
    getUser,
    getUserByID,
    getUserByProperty,
    userCreate,
    userLogin,
    userLoginValidate,
    adminLoginValidate,
    decodeToken,
    userDelete,
    userValidate,
    userUpdate
  }
