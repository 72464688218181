import '../../scss/userPanel/userPanel-favs.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { decodeToken, getUserByID, userUpdate } from '../../api/userAPI'
import { getRazaByID } from '../../api/razaAPI'
import { getCookie } from '../../funciones/token'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../modal'
import { FaHeart } from 'react-icons/fa'






function UserPanelFavs() {
    const navigate = useNavigate()

    const [reboot, setReboot] = useState(true)
    const [razasFavsState, setRazasFavsState] = useState(null)
    const [dataModal, setDataModal] = useState(null)
    const [userID, setUserID] = useState('')
    const [allRazaID, setAllRazaID] = useState([])






    // useEffect para buscar los datos del usuario logueado
    useEffect(() => {

        findUserData()
        async function findUserData() {
            const token = getCookie('token')
            const tokenDecode = await decodeToken()

            const findUserByID = await getUserByID(tokenDecode.data.id)
            setAllRazaID(findUserByID.data.favs)
            setUserID(findUserByID.data._id)
            

            // Usar Promise.all para esperar a que todas las promesas se resuelvan
            const allRazasMap = await Promise.all(allRazaID.map(async (data) => {
                const findRazaID = await getRazaByID(data);
                return findRazaID.data;
            }));


            // Funcion sort para ordenar las razas de favoritos por nombre de raza
            allRazasMap.sort((a, b) => a.nombre < b.nombre ? -1 : a.nombre > b.nombre ? 1 : 0 )


            // Ahora que allRazasMap tiene los datos, se puede mapear para mostrar en pantalla
            const razasMap = allRazasMap.map(data => 
                <div id="razasFavs-div" key={data._id}>
                    <img id='razasFavs-div-img' src={data.img[0]} onClick={() => {setDataModal(`${data.img[0]}`)}} />
                    <p id='razasFavs-div-name' onClick={() => {navigate(`/raza/${data._id}`)}}>{data.raza}</p>
                    <p id='razasFavs-div-icon' onClick={() => {removeFav(`${data._id}`,`${data.raza}`)}}> <FaHeart /> </p>
                </div>
            )

            allRazasMap.length > 0 ? setRazasFavsState(razasMap) : setRazasFavsState('No tienes ninguna raza como favorita')
            setReboot(false)
        }
        
    },[reboot])







    // Funcion para eliminar alguna raza de favoritos
    async function removeFav(id,raza) {
        try {
            const razasFilter = allRazaID.filter(data => data !== id )
            const updateUserFavs = await userUpdate(userID, {'favs': razasFilter})
    
            Swal.fire({
                html: `La raza <b>${raza}</b> ha sido eliminada de favoritos`,
                icon: "success"
              })

              setReboot(true)
              
        } catch (error) {
            Swal.fire({
                html: `Ha habido un error al querer eliminar la raza ${raza}<br>Recargue la pagina y vuelva a intentarlo`,
                icon: "error"
              })
        }
    }








    return (

        <div id="userPanel-userFav-body">

            <p id='userPanel-userFav-rotulo'>Tus razas favoritas</p>

            <div id="userPanel-userFav-list">
                {razasFavsState}
            </div>            


            {dataModal &&
                <Modal isOpen={true}>
                
                    <div id="dataModal-div">
                        <img src={dataModal} onClick={() => {setDataModal(null)}} />                
                    </div>
                
                </Modal>
            }


        </div>




    )
}




export {
    UserPanelFavs
}