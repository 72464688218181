import '../scss/navbar.scss'
import logo from '../assets/logo/michipedia.jpeg'

import { Outlet, NavLink } from "react-router-dom";
import React from 'react';
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../funciones/token';
import Swal from 'sweetalert2';
import { FaHome, FaUserSlash, FaUserAlt , FaUserCircle, FaQuestion, FaSearch, FaComments } from 'react-icons/fa'
import { userLoginValidate } from '../api/userAPI'
import { getNotiByProperty } from '../api/notiAPI'
import { getChatNotiByProperty } from '../api/chatNotiAPI'
import { decodeToken } from '../api/userAPI';




const Navbar = () => {
    const navigate = useNavigate()

    const [reboot, setReboot] = useState(true)
    const [login, setLogin] = useState(false)
    const [notification, setNotificacion] = useState(false)

    

    
    // useEffect para la comprobacion de si esta o no logueado el usuario
    useEffect(() => {

        validateToken()
        async function validateToken() {
            // Capturar token de cookies
            const token = getCookie('token')

            // Meter token en array y mandar a Backend para validar
            const arraySendToken = {'token': token}
            const sendToken = await userLoginValidate(arraySendToken)

            validateNotification()

            setLogin(sendToken.data)  
            setReboot(false)          
        }

    }, [reboot])




    // useEffect para comprobar si el usuario tiene o no notificaciones
    async function validateNotification() {
        // Sacar ID del token
        const token = getCookie('token')
        const tokenDecode = await decodeToken()
        
        // Condicional para cuando no hay un usuario logueado y no de error
        if (tokenDecode.data != 'No esta autorizado 2') {
            // Buscar todas las notificaciones por el ID del usuario, Notificaciones tanto del foro como de chats privados
            const getNotiAll = await getNotiByProperty('userID', tokenDecode.data.id)

            if (getNotiAll.data.length != undefined && getNotiAll.data.length != null && getNotiAll.data.length > 0) {
                const getNotiAllFilter = getNotiAll.data.filter(data => data.old == false)
                const getChatNotiAll = await getChatNotiByProperty('userDestinyID', tokenDecode.data.id)
                getNotiAllFilter.length > 0 || getChatNotiAll.data.length > 0 ? setNotificacion(true) : setNotificacion(false)
            }
            
        } else {
            return
        }
    }





    setInterval(() => {
        validateNotification()
    }, 1000 * 10);






    // Funcion para que el usuario pueda cerrar sesion
    async function userLogOut() {

        try {
            Swal.fire({
                text: "Su login se ha cerrado de forma correcta",
                icon: "success"
              })
    
            document.cookie = 'token=; path=/; max-age=0; SameSite=none; Secure';
            setReboot(true)

        } catch (error) {
            Swal.fire({
                text: "Ha ocurrido un error inesperado, recargue la pagina y vuelva a intentarlo",
                icon: "error"
              })
        }
    }




    return (
        <div id='navbar-global'>
            <nav>

                    <img src={logo} />
                    <NavLink to="/" className='link' title='Home'> <FaHome /> </NavLink>
                    <NavLink to="/search" className='link' title='Buscardor'> <FaSearch /> </NavLink>
                    <NavLink to="/quiz" className='link' title='Quiz'> <FaQuestion /> </NavLink>
                    { login ? <NavLink to="/forum" className='link' title='Foro'> <FaComments /> </NavLink> : <></> }
                    { login ? notification ? <NavLink to="/user" className='alert' title='Panel de usuario'> <FaUserCircle /> </NavLink> : <NavLink to="/user" className='link' title='Panel de usuario'> <FaUserCircle /> </NavLink> : <></> }
                    { login ? <NavLink to="/login" className='link' title='LogOut' onClick={userLogOut}> <FaUserSlash /> </NavLink> : <NavLink to="/login" className='link' title='LogIn'> <FaUserAlt /> </NavLink> }

            </nav>
            <Outlet />
        </div>
    )
};



export {
    Navbar
}
