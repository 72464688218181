import '../scss/forum/forumTopicDetail.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { API_URL } from '../api/API'
import { Modal } from '../componentes/modal'
import Swal from 'sweetalert2'
import { dateFormat } from '../funciones/fecha'
import { uploadFile } from '../funciones/uploadMulter'
import { validImageTypes } from '../funciones/resizeIMG'
import { getForumByID, forumUpdate } from '../api/forumAPI'
import { notiCreate, getNotiByProperty, notiUpdate } from '../api/notiAPI'
import { userLoginValidate, decodeToken } from '../api/userAPI'
import { useNavigate } from 'react-router-dom'
import { getCookie } from '../funciones/token'
import { Navbar } from '../componentes/navbar'
import { Footer } from '../componentes/footer'
import { FaTrashAlt } from "react-icons/fa";
import { deleteFile } from '../funciones/deleteMulter'



function ForumDetailPage() {
    const navigate = useNavigate()
    const { id } = useParams()

    const [tokenID, setTokenID] = useState('')
    const [isAdmin, setIsAdmin] = useState(false)

    const [imgModal, setImgModal] = useState(null)
    const [responseModal, setResponseModal] = useState(null)

    const [responsesTotal, setResponsesTotal] = useState(0)
    const [numResponse, setNumResponse] = useState(null)
    const [allResponse, setAllResponse] = useState([])
    const [allUserResponse, setAllUserResponse] = useState([])

    const [reboot, setReboot] = useState(true)
    const [topicResponse, setTopicResponse] = useState(null)

    const [topicName, setTopicName] = useState(null)
    const [topicMessage, setTopicMessage] = useState(null)
    const [topicIMG, setTopicIMG] = useState([])
    const [topicDate, setTopicDate] = useState(null)
    const [topicUser, setTopicUser] = useState({})

    const [initial, setInitial] = useState(0)
    const [final, setFinal] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)

    const [topicOpen, setTopicOpen] = useState(false)






        // useEffect para enviar a la parte superior de la pagina una vez que se entra en ella 
        useEffect(() => {
            window.scrollTo({
                top: 0,
            })
        }, [])







    
        // useEffect para la comprobacion de si esta o no logueado el usuario
        useEffect(() => {

            validateToken()
            async function validateToken() {
                // Capturar token de cookies
                const token = getCookie('token')
                const tokenDecode = await decodeToken()
    
                // Meter token en array y mandar a Backend para validar
                const arraySendToken = {'token': token}
                const sendToken = await userLoginValidate(arraySendToken)
    
                if (!sendToken.data) {
                    Swal.fire({
                        text: "Para entrar en el foro necesitas iniciar sesion con su usuario",
                        icon: "warning"
                    })

                    navigate('/login')
                } else {
                    setReboot(true)
                    setTokenID(tokenDecode.data.id)
                    tokenDecode.data.rol == 'admin' ? setIsAdmin(true) : setIsAdmin(false)
                }

                deleteAllNoti()
            }
    
        }, [])



        

        
        // Funcion para poner en visto las notificaciones que pudiera tener el usuario
        async function deleteAllNoti() {
            const getAllNotiByUser = await getNotiByProperty('userID', tokenID)

            // try catch para evitar que de error si no hay notificaciones
            try {
                // Metodo Map para buscar si hay alguna notificacion para este tema
                const getAllNotiByUserMap = getAllNotiByUser.data.map(async data => {
                    data.topicID == id ? await notiUpdate(data._id, {'old': true}) : null
                })


                // Buscar todas las notificaciones vistas y eliminar todas menos las 15 ultimas
                const findNotiOld = await getNotiByProperty('userID', tokenID)
                const findNotiOldFilter = findNotiOld.data.filter(data => data.old == true)

                // Condicional para evitar que de error al eliminar datos cuando no hay mas que 15
                if (findNotiOldFilter.length > 14) {
                    let findNotiOldDel = findNotiOldFilter.sort()

                    // Usar bucle para eliminar el excedente
                    for (let i = 0; (findNotiOldFilter.length - 14) > i; i++) {
                        const findNotiOldRemove = await notiDelete(findNotiOldDel[i]._id)
                    }
                }
                

            } catch (error) {
                console.log('este es el error del try catch', error);
            }
        }







    // useEffect para traer todos los datos del topic con el id
    useEffect(() => {
        if (reboot) {

            getTopicData()
            async function getTopicData() {
                const topicData = await getForumByID(id)

                // Sacar datos del tema original para mostrar
                setTopicName(topicData.data.topic)
                setTopicMessage(topicData.data.message)
                setTopicIMG(topicData.data.img)
                setTopicDate(topicData.data.dateString)
                setTopicUser(topicData.data.username)
                setTopicOpen(topicData.data.open)

                setAllResponse(topicData.data.response)
                setAllUserResponse(topicData.data.userResponse)

                // Calcular cual es el siguiente numero de respuesta
                setResponsesTotal(topicData.data.response.length)
                topicData.data.response.length > 0 ? setNumResponse(topicData.data.response[topicData.data.response.length-1].idResponse) : setNumResponse(0)

                // Paginacion para que se muestren solo en grupos de 10
                const nextItems = topicData.data.response.slice(initial, final)


                // Hacer un metodo map para sacar todas las respuestas
                const allResponseMap = nextItems.map((data) => 
                    <div id="responseMap-div" key={data.idResponse}>
                        <div id="responseMap-div-user">

                            <div id="responseMap-div-user-1">
                                <img src={data.userIMG} />
                                <p onClick={() => {navigate(`/user/${data.userID}`)}}>{data.username}</p>
                                { tokenID === data.userID || isAdmin ? <p id='responseMap-div-delete' onClick={() => {removeResponse(`${data.idResponse}`)}}><FaTrashAlt /></p> : <></> }
                            </div>
                            <div id="responseMap-div-user-2">
                                <p>{data.date}</p>
                                <p>#{data.idResponse}</p>
                            </div>

                        </div>

                        <div id="responseMap-div-topic">
                            <p>{data.response}</p>
                            <div id="responseMap-div-topic-img">
                                { data.img[0].length > 0 ? <img src={data.img[0]} onClick={() => {setImgModal(`${data.img[0]}`)}} /> : <></> }
                                { data.img[1].length > 0 ? <img src={data.img[1]} onClick={() => {setImgModal(`${data.img[1]}`)}} /> : <></> }
                                { data.img[2].length > 0 ? <img src={data.img[2]} onClick={() => {setImgModal(`${data.img[2]}`)}} /> : <></> }
                            </div>
                        </div>
                    </div>
                )
                setTopicResponse(allResponseMap)
                deleteAllNoti()

            }
        }
        setReboot(false)
    }, [reboot])









    // Funcion para hacer el envio de la respuesta
    async function sendResponse(e) {
        const tokenDecode = await decodeToken()

        const newResponse_Textarea = document.getElementById('modalResponse-textarea')
        const newResponse_IMG1 = document.getElementById('modalResponse-img1')

        let routeFile1 = ''
        let routeFile2 = ''
        let routeFile3 = ''

        Swal.fire({
            html: 'Publicando contenido...',
            icon: 'info',
            showConfirmButton: false
        })


        // Solo permitir un maximo de tres imagenes
        if (newResponse_IMG1.files.length > 3) {
            Swal.fire({
                html: 'Solo se permite un maximo de tres imagenes',
                icon: 'warning'
            })
            return
        }


        // Bucle y condicionales para comprobar que solo sean imagenes
        for (let i = 0; newResponse_IMG1.files.lenght > i; i++) {
            if (!validImageTypes(newResponse_IMG1.files[i].type)) {
                newResponse_IMG1.value = ''
                Swal.fire({
                    text: "Solo se permiten adjuntar imagenes",
                    icon: "error"
                })
                return
            }
        }
        

        // Condicional con la opcion de subir una imagen con multer para el topic
        if (newResponse_IMG1.files[0] != null && newResponse_IMG1.files[0] != undefined) {
            let postIMG1 = await uploadFile(newResponse_IMG1.files[0]);
            routeFile1 = `${API_URL}${postIMG1.filePath}`
        }

        if (newResponse_IMG1.files[1] != null && newResponse_IMG1.files[1] != undefined) {
            let postIMG2 = await uploadFile(newResponse_IMG1.files[1]);
            routeFile2 = `${API_URL}${postIMG2.filePath}`
        }

        if (newResponse_IMG1.files[2] != null && newResponse_IMG1.files[2] != undefined) {
            let postIMG3 = await uploadFile(newResponse_IMG1.files[2]);
            routeFile3 = `${API_URL}${postIMG3.filePath}`
        }

        
        // Evitar que se pueda crear la respuesta si contiene menos de 5 caracteres
        if (newResponse_Textarea.value.trim().length > 5) {

                try {
                    // Incluir el nuevo ID del usuario si aun no esta
                    const existID = allUserResponse.some(data => data == tokenID)
                    let allResponsesVar = [...allUserResponse]
                    !existID ? allResponsesVar.push(tokenID) : null

                    // Datos para crear un array y despues la funcion de crear el topic
                    const newArrayResponse = {'username': tokenDecode.data.username,
                                            'userID': tokenID,
                                            'userIMG': tokenDecode.data.img,
                                            'idResponse': numResponse + 1,
                                            'date': await dateFormat(Date.now()),
                                            'response': newResponse_Textarea.value.trim(),
                                            'img': [routeFile1, routeFile2, routeFile3]
                    }

                    // Juntar los nuevos datos con lo que ya habia guardado
                    allResponse.push(newArrayResponse)
                    const newArrayTopic = {'response': allResponse,
                                            'dateStringUpdate': await dateFormat(Date.now()),
                                            'userResponse': allResponsesVar,
                                            'dateUpdate': Date.now()
                    }

                    const updateResponses = await forumUpdate(id, newArrayTopic)



                    // Crear una nueva entrada en las notificaciones si no existe ya una anterior
                    const getNotiByTopic = await getNotiByProperty('topic', topicName)  // Sacar todas las entradas de las notificaciones con el ID de este tema
                    const getTopicNoti = await getForumByID(id)
                    const usersNoti = getTopicNoti.data.userResponse  // Lista de todos los usuarios asociados a este tema

                    // Crear metodo Map para pasar por todas las IDs
                    const userNotiMap = usersNoti.map(async data => { // Se pasa por cada uno de los IDs asociados a este tema
                        if (data != tokenID) { // Condicional para evitar que se haga una notificacion para el mismo usuario que escribe una respuesta
                            const getNotiByTopicSome = getNotiByTopic.data.some(dataNoti => { // Metodo Some para comprobar si ya hay una notificacion por cada usuario
                                dataNoti.userID == data && dataNoti.old == false
                            })

                            if (!getNotiByTopicSome) {  // Condicional para que si no hay notificacion previa, la pueda crear ahora
                                const arrayNewNoti = {'topic': topicName,
                                                        'topicID': id,
                                                        'userID': data,
                                                        'dateString': await dateFormat(Date.now()) }

                                const newNotiTopic = await notiCreate(arrayNewNoti)
                            }
                        }
                    })

                        Swal.fire({
                            title: "Respuesta creada",
                            html: "Su respuesta se ha creado de forma correcta",
                            icon: "success"
                        })

                        setResponseModal(null)
                        setReboot(true)


                } catch (error) {
                        Swal.fire({
                            html: "Ha habido un error, recargue la pagina y vuelva a intentarlo",
                            icon: "error"
                        })
                }


        } else {
                Swal.fire({
                    text: "No puede dejar el campo de texto vacio",
                    icon: "error"
                })
        }
    }










    // Funcion para eliminar una respuesta
    async function removeResponse(responseID) {
        const getTopic = await getForumByID(id)


        Swal.fire({
            title: "Su respuesta va a ser eliminada",
            html: "Va a eliminar su respuesta junto con las imagenes asociadas<br>Esta operacion no se puede revertir",
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#3085d6",
            confirmButtonColor: "#d33",
            confirmButtonText: "Eliminar"
          }).then(async (result) => {
            if (result.isConfirmed) { // Cuando se acepta la eliminacion

                try {

                        // Metodo Filter para buscar la respuesta
                        const getImageURL = getTopic.data.response.filter(data => data.idResponse == responseID)

                        // Metodo Map para eliminar todas las imagenes asociadas
                        const imageDeleteMapIMG = getImageURL[0].img.map(img => {
                            if (img != '') {
                                //let filename = img.replace("http://localhost:3017/uploads/", "");
                                let filename = img.replace(`${API_URL}/uploads/`, "");
                                deleteFile(filename)
                            }
                        })      

                        // Metodo Filter para quitar la respuesta
                        const responseRemoveFilter = getTopic.data.response.filter(data => data.idResponse != responseID)

                        const arrayUpdateResponse = {'response': responseRemoveFilter}
                        const updateTopic = await forumUpdate(id, arrayUpdateResponse)


                        Swal.fire({
                            title: "Respuesta eliminada",
                            html: "Su respuesta e imagenes asociadas han sido eliminadas de forma correcta",
                            icon: "success"
                        })

                        setResponseModal(null)
                        setReboot(true)

                } catch (error) {
                        Swal.fire({
                            html: "Ha habido un error, recargue la pagina y vuelva a intentarlo",
                            icon: "error"
                        })
                }


            } else { // Cuando no se acepta la eliminacion
                Swal.fire({
                    title: "Cancelado",
                    html: `Se ha cancelado la operacion y su respuesta no ha sufrido cambios`,
                    icon: "info"
                  })
            }
          })        
    }







    // Funcion para dejar de recibir notificaciones de este tema
    async function deleteNotisAll(e) {
        Swal.fire({
            text: "No recibiras mas notificaciones de este tema",
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#3085d6",
            confirmButtonColor: "#d33",
            confirmButtonText: "Bloquear notificaciones"
          }).then( async (result) => {
            if (result.isConfirmed) {

                // Traer los IDs de los usuarios asociados
                const getTopicDataByID = await getForumByID(id)
                let userResponseByID = getTopicDataByID.data.userResponse
                
                // Metodo filter para eliminar el usuario actual y despues actualizar tema
                const userResponseFilter = userResponseByID.filter( data => data != tokenID)
                const updateUserResponse = await forumUpdate(id, {'userResponse': userResponseFilter})

              Swal.fire({
                text: "Las notificaciones de este tema han sido bloqueadas",
                icon: "success"
              })
            }
          })
    }









    // Funciones de paginacion que vienen de los botones
    async function buttonPrevResponses(e) {
        // Condicional para que no pueda irse a negativo
        if (initial > 0) {
            setInitial(prev => prev - 10)
            setFinal(prev => prev - 10)
            setCurrentPage(prev => prev - 1)
            setReboot(true)

            // Funcion para volver a la parte superior de la pagina al pulsar el boton
            setTimeout(() => {
                window.scrollTo({
                    top: 100,
                    behavior: 'smooth' // Opcional: para un desplazamiento suave
                })            
            }, 100);

        }
    }


    async function buttonPostResponses(e) {
            // Condicional para que no pueda irse a negativo
            if (final < responsesTotal) {
                setInitial(prev => prev + 10)
                setFinal(prev => prev + 10)
                setCurrentPage(prev => prev + 1)
                setReboot(true)

            // Funcion para volver a la parte superior de la pagina al pulsar el boton
            setTimeout(() => {
                window.scrollTo({
                    top: 100,
                    behavior: 'smooth' // Opcional: para un desplazamiento suave
                })            
            }, 100);
        }
    }










    return (
        
        <div id="forumDetailPage-body">
            
            <Navbar />


            <div id="forumDetailPage-data">
                
                <div id="forumDetailPage-data-message">
                    <div id="forumDetailPage-data-message-header">

                        <div id="forumDetailPage-data-message-header-user">

                            <div id="forumDetailPage-data-message-header-user_1">
                                <img src={topicUser.IMG} />
                                <p onClick={() => {navigate(`/user/${topicUser.ID}`)}}>{topicUser.username}</p>
                            </div>
                            <div id="forumDetailPage-data-message-header-user_2">
                                <p>{topicName}</p>
                            </div>                            
                            <div id="forumDetailPage-data-message-header-user_3">
                                <p>{topicDate}</p>
                            </div>

                        </div>

                    </div>

                    <div id="forumDetailPage-data-message-body">
                        <p>{topicMessage}</p>
                        <div id="forumDetailPage-data-message-body-img">
                            { topicIMG[0] == undefined || topicIMG[0].length > 0 ? <img src={topicIMG[0]} onClick={() => {setImgModal(`${topicIMG[0]}`)}} /> : <></> }
                            { topicIMG[1] == undefined || topicIMG[1].length > 0 ? <img src={topicIMG[1]} onClick={() => {setImgModal(`${topicIMG[1]}`)}} /> : <></> }
                            { topicIMG[2] == undefined || topicIMG[2].length > 0 ? <img src={topicIMG[2]} onClick={() => {setImgModal(`${topicIMG[2]}`)}} /> : <></> }
                        </div>
                    </div>
                </div>





                <div id="forumDetailPage-data-newMessage">
                    { topicOpen ? <input type="button" value="Nueva respuesta" id='newResponse-button' onClick={() => {setResponseModal(id)}} /> : <input type="button" value="Tema cerrado" id='newResponse-button' /> }
                    <input type="button" value="Cancelar notificaciones" id='noNotis-button' onClick={deleteNotisAll} />
                </div>





                {responseModal && 
                    <Modal isOpen={true}>

                        <div id="modalResponse-body">
                            <textarea id="modalResponse-textarea" placeholder='Escriba su respuesta aqui...' rows={4}/>

                            <p id='modalResponse-rotuloIMG'>Puede seleccionar un maximo de tres imagenes</p>
                            <input type="file" id="modalResponse-img1" multiple className='modalResponse-img' />

                            <div id="modalResponse-buttons">
                                <input type="button" value="Enviar respuesta" id='modalResponse-button-send' onClick={sendResponse} />
                                <input type="button" value="Cerrar" id='modalResponse-button-close' onClick={() => {setResponseModal(null)}} />
                            </div>
                        </div>

                    </Modal>
                }






                {imgModal && 
                    <Modal isOpen={true}>

                        <div id="modalIMG-body" onClick={() => {setImgModal(null)}}>
                            <img src={imgModal} />
                        </div>

                    </Modal>
                }   







                <div id="forumDetailPage-data-responses">
                    {topicResponse}
                </div>




                <div id="forumDetailPage-data-paginacion">
                    <input type="button" value="Anterior" onClick={buttonPrevResponses} />
                    <p id='forumDetaulPage-data-currentPage'>{currentPage} / {Math.ceil(responsesTotal / 10)}</p>
                    <input type="button" value="Siguiente" onClick={buttonPostResponses} />
                </div>

            </div>




            <Footer />

        </div>

    )
}



export {
    ForumDetailPage
}