import '../../scss/userPanel/userPanelMenu.scss'

import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCookie } from '../../funciones/token'
import { decodeToken, getUserByID } from '../../api/userAPI'
import { adminLoginValidate } from '../../api/userAPI'
import { ContextoCompartido } from '../../context'
import { sha256 } from 'js-sha256'
import Swal from 'sweetalert2'
import { API_URL } from '../../api/API'
import { deleteFile } from '../../funciones/deleteMulter'

import { UserPanelUserData } from './userPanel-userData'
import { UserPanelNotifications } from './userPanel-notifications'
import { UserPanelFavs } from './userPanel-favs'
import { UserPanelTopics } from './userPanel-topics'
import { UserPanelMessageIndex } from './message/userPanel-messageIndex'

import { getNotiByProperty, notiDelete } from '../../api/notiAPI'
import { getChatNotiByProperty } from '../../api/chatNotiAPI'
import { userUpdate } from '../../api/userAPI'
import { getForumByProperty, forumUpdate } from '../../api/forumAPI'
import { dateFormat } from '../../funciones/fecha'
import { emailCreate } from '../../api/emailAPI'
import { userDelByUser } from '../../api/sendEmailAPI'





function UserPanelMenu() {
    const { setShowMenuSelected } = useContext(ContextoCompartido);
    const { setShowUserPanelMenu } = useContext(ContextoCompartido)

    const navigate = useNavigate()  

    const [gestion, setGestion] = useState()
    const [notification, setNotificacion] = useState(false)
    const [message, setMessage] = useState(false)






    // useEffect para la comprobacion de si el usuario tiene rol de admin
    useEffect(() => {

        validateToken()
        async function validateToken() {
            // Capturar token de cookies
            const token = getCookie('token')

            // Meter token en array y mandar a Backend para validar
            const arraySendToken = {'token': token}
            const sendToken = await adminLoginValidate(arraySendToken)

            sendToken.data ? setGestion(true) : setGestion(false)
        }
        
    }, [])






    // useEffect para comprobar si el usuario tiene o no notificaciones
    useEffect(() => {

        validateNotification()
        async function validateNotification() {
            // Sacar ID del token
            const token = getCookie('token')
            const tokenDecode = await decodeToken()

            // Buscar todas las notificaciones por el ID del usuario, Notificaciones tanto del foro como de chats privados
            const getNotiAll = await getNotiByProperty('userID', tokenDecode.data.id)
            const getNotiAllFilter = getNotiAll.data.filter(data => data.old == false)

            const getChatNotiAll = await getChatNotiByProperty('userDestinyID', tokenDecode.data.id)

            getNotiAllFilter.length > 0 ? setNotificacion(true) : setNotificacion(false)
            getChatNotiAll.data.length > 0 ? setMessage(true) : setMessage(false)


        }

    },[])





    
    // Funcion para que aparezca los datos segun el boton pulsado
    async function selectedMenu(e) {
        const buttonValue = e.target.value

        if (buttonValue === 'Datos de usuario') {
            setShowMenuSelected(<UserPanelUserData />)

        } else if (buttonValue === 'Notificaciones') {
            setShowMenuSelected(<UserPanelNotifications />)

        } else if (buttonValue === 'Favoritos') {
            setShowMenuSelected(<UserPanelFavs />)

        } else if (buttonValue === 'Temas creados') {
            setShowMenuSelected(<UserPanelTopics />)

        } else if (buttonValue === 'Mensajes') {
            setShowMenuSelected(<UserPanelMessageIndex />)
        }

        setShowUserPanelMenu(true)
    }










    // Funcion para que un usuario pueda borrar por completo su usuario
    async function deleteUser(e) {

        Swal.fire({
            title: "Su usuario va a ser eliminado",
            html: '<div style="text-align:justify;"><ul><li style="margin-bottom:5px;">Esta accion no se puede revertir</li><li style="margin-bottom:5px;">Tambien se eliminaran favoritos, notificaciones y configuraciones</li><li style="margin-bottom:20px;">Una vez eliminado su usuario no podra volver a usar la misma direccion de correo electronico ni el mismo nombre de usuario</li><p style="margin-left:-40px;margin-right:-20px;">Escriba su contraseña para eliminar su usuario definitivamente</p></ul></div>',
            icon: "warning",
            input: "password",
            inputAttributes: {
              autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "Si, eliminar todo",
            confirmButtonColor: "#d33",
            cancelButtonText: "Cancelar",
            cancelButtonColor: "#3085d6",
            showLoaderOnConfirm: true,
            preConfirm: async (pass) => {
              try {

                    // Conseguir datos del usuario
                    const tokenDecode = await decodeToken()
                    const tokenID = tokenDecode.data.id
                    const userGetData = await getUserByID(tokenID)

                    // Condicional para comprobar contraseña y aceptar o denegar la eliminacion
                    if (userGetData.data.password === sha256(pass)) { // Contraseña valida

                            // Buscar, metodo Map y eliminar todas las notificaciones pendientes
                            const getAllNoti = await getNotiByProperty('userID', tokenID)
                            const deleteAllNotiMap = getAllNoti.data.map(async data => { await notiDelete(data._id) })


                            // Cerrar todos los temas del foro que haya creado el usuario
                            const getForumByUser = await getForumByProperty('username.ID', tokenID)
                            const getForumByUserMap = getForumByUser.data.map(async (data) => { const closeForum = await forumUpdate(data._id, {'open': false}) })


                            // Funcion map para eliminar las imagenes de multer y condicional para que no de error si no hay foto
                            if (userGetData.data.img != '') {
                                const filename = userGetData.data.img.split('/').pop()

                                // Condicional para evitar que elimine la imagen none.png
                                if (!filename.includes('none')) {
                                    const removeFileIMG = await deleteFile(filename)
                                }
                            } 

                            
                            // Enviar email de despedida
                            const sendingEmailBye = await userDelByUser({'email': userGetData.data.email, 'username': userGetData.data.username})

                            // Modificar el usuario para eliminar datos y dejarlo como bloqueado
                            const arrayUpdateUser = {'img': `${API_URL}/uploads/none.png`,
                                                    'favs': [],
                                                    'delete': true }

                            const updateUserDelete = await userUpdate(tokenID, arrayUpdateUser)

                            // Guardar en DB informacion en la tabla email
                            const arraySendEmail = {'username': userGetData.data.username,
                                                    'userID': userGetData.data._id,
                                                    'adminname': 'System',
                                                    'dateString': await dateFormat(Date.now()),
                                                    'message': `El usuario se ha dado de baja a si mismo`}

                            const sendingEmailDelByUser = await emailCreate(arraySendEmail)
                            

                    } else { // Contraseña incorrecta
                        Swal.showValidationMessage(`Su password es incorrecto`);
                    }
                
                
              } catch (error) {
                Swal.showValidationMessage(`Request failed: ${error}`);
              }

            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then(async (result) => {

                if (result.dismiss === 'cancel' || result.isDismissed) { // Para cuando se cancela la eliminacion del usuario
                    Swal.fire({
                        title: 'Operacion cancelada',
                        html: `Se ha cancelado la operacion y el usuario no ha tenido ningun cambio`
                    })

                } else if (result.isConfirmed) { // Para cuando se acepta la eliminacion del usuario
                    Swal.fire({
                        title: 'Usuario eliminado',
                        html: '<p style="text-align:center;">Su usuario se ha eliminado correctamente junto con la informacion que hubiera asociada. No se puede recuperar ningun dato o informacion eliminada. Tanto el username como el email no se pueden volver a reutilizar, si desea volver a tener un usuario activo debe usar un username y un email diferentes</p>'
                    })
        
                    // Eliminar token en Cookies y mandar a index
                    document.cookie = 'token=; path=/; max-age=0; SameSite=none; Secure';
                    navigate('/')  

                } else { // Cuando ha ocurrido algun error

                    Swal.fire({
                        title: 'Error de operacion',
                        html: `Ha ocurrido un error inesperado y la operacion se ha cancelado, recargue la pagina y vuelva a intentarlo`
                    })

                }
            
          
          })
    }










    return (

        <div id="userPanelMenu-body">
            <div id="userPanelMenuOptions-buttons">
        
                <input type="button" value="Datos de usuario" className='userPanelMenu-button' id='userPanelMenu-userData' onClick={selectedMenu} />    
                { notification ? <input type="button" value="Notificaciones" className='userPanelMenu-button' id='userPanelMenu-notifications' onClick={selectedMenu} /> : <input type="button" value="Notificaciones" className='userPanelMenu-button' onClick={selectedMenu} /> }
                { message ? <input type="button" value="Mensajes" className='userPanelMenu-button' id='userPanelMenu-notifications' onClick={selectedMenu} /> : <input type="button" value="Mensajes" className='userPanelMenu-button' onClick={selectedMenu} /> }                
                <input type="button" value="Favoritos" className='userPanelMenu-button' id='userPanelMenu-favs' onClick={selectedMenu} />
                <input type="button" value="Temas creados" className='userPanelMenu-button' id='userPanelMenu-topics' onClick={selectedMenu} />
                { gestion ? <input type="button" value="Gestion Admin" className='userPanelMenu-button' id='userPanelMenu-gestionAdmin' onClick={() => {navigate('/gestion')}} /> : <></> }
                <input type="button" value="Borrar usuario" className='userPanelMenu-button' id='userPanelMenu-delUser' onClick={deleteUser} />
            
            </div>
        </div>

    )
}



export {
    UserPanelMenu
}