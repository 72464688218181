import dotenv from 'dotenv';
import { API_URL } from "./API.js"
import { getCookie } from '../funciones/token.js';



async function getForum() { 
  try {
    const post = await fetch(`${API_URL}/forum`,
      {
        method: 'GET',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        }
    });

    if (!post.ok) {
      throw new Error(`Error al buscar el data 1 (HTTP ${post.status})`);
    }

    const result = await post.json();
    //console.log('Data buscado:', result);
    return (result)
  } catch (error) {
    console.error('Error al buscar el data 2 :', error);
  }
}





async function getForumByID(id) { 
  try {
    const post = await fetch(`${API_URL}/forum/${id}`,
      {
        method: 'GET',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        }
    });

    if (!post.ok) {
      throw new Error(`Error al buscar el data 1 (HTTP ${post.status})`);
    }

    const result = await post.json();
    //console.log('Data buscado:', result);
    return (result)
  } catch (error) {
    console.error('Error al buscar el data 2 :', error);
  }
}







async function getForumByProperty(property, value) {
  try {
    const post = await fetch(`${API_URL}/forum/find`,
      {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ property, value })
    });

    if (!post.ok) {
      throw new Error(`Error al buscar el data 1 (HTTP ${post.status})`);
    }

    const result = await post.json();
    //console.log('Data buscado:', result);
    return (result)
  } catch (error) {
    console.error('Error al buscar el data 2 :', error);
  }
}





async function forumCreate(data) {
  try {
    const response = await fetch(`${API_URL}/forum`,
      {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al crear los foros (HTTP ${response.status})`);
    }

    const result = await response.json();
    //console.log('foro creado:', result);
    return result
  } catch (error) {
    console.error('Error al crear los foros:', error);
  }
}






  async function forumDelete(id) {
    try {
      const response = await fetch(`${API_URL}/forum/remove/${id}`, {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`Error al eliminar la data (HTTP ${response.status})`);
      }
  
      const result = await response.json();
      //console.log('data eliminada:', result);
    } catch (error) {
      console.error('Error al eliminar la data:', error);
    }
  }



 
  

  async function forumUpdate(id, data) {
    try {
      const response = fetch(`${API_URL}/forum/update/${id}`,
        {
          method: 'POST',
          headers: { 
            'Authorization': `${getCookie('token')}`,
            'Content-type': 'application/json',
          },
            body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        //throw new Error(`Error al actualizar 1 (HTTP ${await response.status})`);
      }
  
      const result = await response.json();
      //console.log('actualizado:', result);
    } catch (error) {
      //console.error('Error al actualizar 2:', error);
    }
  }




  export {
    getForum,
    getForumByID,
    getForumByProperty,
    forumCreate,
    forumDelete,
    forumUpdate
  }
