import '../scss/forum/forumTopicsPage.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { dateFormat } from '../funciones/fecha'
import { uploadFile } from '../funciones/uploadMulter'
import { validImageTypes } from '../funciones/resizeIMG'
import { API_URL } from '../api/API'
import Swal from 'sweetalert2'
import { getForum, getForumByProperty, forumCreate } from '../api/forumAPI'
import { userLoginValidate, decodeToken } from '../api/userAPI'
import { getCookie } from '../funciones/token'
import { Navbar } from '../componentes/navbar'
import { Footer } from '../componentes/footer'



function ForumTopicsPage() {
    const navigate = useNavigate()

    const [rotulo, setRotulo] = useState('Temas mas recientes')

    const [reboot, setReboot] = useState(false)
    const [topicData, setTopicData] = useState(null)

    const [showFind, setShowFind] = useState(false)
    const [showForm, setShowForm] = useState(false)




    // useEffect para enviar a la parte superior de la pagina una vez que se entra en ella 
    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])





    // useEffect para la comprobacion de si esta o no logueado el usuario
    useEffect(() => {

        validateToken()
        async function validateToken() {
            // Capturar token de cookies
            const token = getCookie('token')

            // Meter token en array y mandar a Backend para validar
            const arraySendToken = {'token': token}
            const sendToken = await userLoginValidate(arraySendToken)

            if (!sendToken.data) {
                navigate('/')
            } else {
                setReboot(true)
            }
        }

    }, [])







    // useEffect para traer todos los temas creados hasta la fecha
    useEffect(() => {
        if (reboot) {

            getAllTopics()
            async function getAllTopics() {
                let allTopics = await getForum()

                
                // Funcion para poder ordenar los topics por fecha de actualizacion
                let arrayOrdenado = ordenarPorFecha(allTopics.data)

                function ordenarPorFecha(arr) {
                    let n = arr.length;
                    let swapped;
                
                    do {
                        swapped = false;
                        for (let i = 0; i < n - 1; i++) {
                            let fecha1 = new Date(arr[i].dateUpdate);
                            let fecha2 = new Date(arr[i + 1].dateUpdate);
                
                            // Comparar fechas: si la primera es menor que la segunda, intercambiarlas
                            if (fecha1 < fecha2) {
                                let temp = arr[i];
                                arr[i] = arr[i + 1];
                                arr[i + 1] = temp;
                                swapped = true;
                            }
                        }
                        n--; // Reducir el rango a recorrer porque el último ya está ordenado
                    } while (swapped);
                    return arr;
                }

                

                // Mostrar solo los 20 mas recientes
                const nextItems = arrayOrdenado.slice(0, 20)


                const allTopicsMap = nextItems.map((data) => 
                    <div id="allTopics-div" key={data._id} onClick={() => {navigate(`/forum/${data._id}`)}}>
                        <p id='allTopics-div-name'>{data.topic} ({data.response.length})</p>
                        <p id='allTopics-div-date'>Actualizado: {data.dateStringUpdate}</p>
                    </div>
                )
                setTopicData(allTopicsMap)
            }

        }
        setReboot(false)
    }, [reboot])
    
    




    // funcion unicamente para mostrar u ocultar los campos de busqueda
    async function findTopic(e) {
        setShowForm(false)
        showFind ? setShowFind(false) : setShowFind(true)
        showFind ? setReboot(true) : setTopicData(null)
        showFind ? setRotulo('Temas mas recientes') : setRotulo(null)
    }




    // funcion unicamente para mostrar u ocultar los campos de crear tema
    async function newTopic(e) {
        setShowFind(false)
        showForm ? setShowForm(false) : setShowForm(true)
        showForm ? setReboot(true) : setTopicData(null)
        showForm ? setRotulo('Temas mas recientes') : setRotulo(null)
    }






    // Funcion onChange para buscar los temas desde el input type text
    async function findTopicByName(e) {
        const inputText = e.target.value

        // Condicional para que cuando el campo este vacio se re-renderice el useEffect y salgan todos los temas
        // En caso contrario, busca y mete en un map los datos para mostrar en pantalla
        if (inputText === '') {
            setReboot(true)
            setRotulo('Temas mas recientes')

        } else {
            const getTopicsByName = await getForumByProperty('topic', inputText)

            getTopicsByName.data.length == 0 ? setRotulo('No hay coincidencias') : setRotulo('Temas encontrados')

            const getTopicsByNameMap = getTopicsByName.data.reverse().map((data) => 
                <div id="allTopics-div" key={data._id} onClick={() => {navigate(`/forum/${data._id}`)}}>
                    <p>{data.topic} ({data.response.length})</p>
                    <p>{data.dateStringUpdate}</p>
                </div>
            )
            setTopicData(getTopicsByNameMap)
        }
    
    }






    // Funcion para crear un nuevo tema desde cero usando el formulario
    async function createNewTopic(e) {
        const tokenDecode = await decodeToken()     

        const newTopic_topic = document.getElementById('forumPage-inputNewTopic')
        const newTopic_message = document.getElementById('forumPage-inputNewMessage')
        const newTopic_IMG1 = document.getElementById('forumPage-inputNewIMG1')

        let routeFile1 = ''
        let routeFile2 = ''
        let routeFile3 = ''

        Swal.fire({
            html: 'Publicando contenido...',
            icon: 'info',
            showConfirmButton: false
        })

        // Evitar que se suban mas de 3 imagenes
        if (newTopic_IMG1.files.length > 3) {
            Swal.fire({
                html: 'Solo se permite un maximo de tres fotos',
                icon: 'warning'
            })
            return
        }


        // Bucle y condicionales para comprobar que solo sean imagenes
        for (let i = 0; newTopic_IMG1.files.length > i; i++) {
            if (!validImageTypes(newTopic_IMG1.files[i].type)) {
                newTopic_IMG1.value = ''
                Swal.fire({
                    text: "Solo se permiten adjuntar imagenes",
                    icon: "error"
                })
                return
            }
        }



        // Condicionales con la opcion de subir una imagen con multer para el topic
        if (newTopic_IMG1.files[0] != null && newTopic_IMG1.files[0] != undefined) {
            let postIMG1 = await uploadFile(newTopic_IMG1.files[0]);
            routeFile1 = `${API_URL}${postIMG1.filePath}`
        }

        if (newTopic_IMG1.files[1] != null && newTopic_IMG1.files[1] != undefined) {
            let postIMG2 = await uploadFile(newTopic_IMG1.files[1]);
            routeFile2 = `${API_URL}${postIMG2.filePath}`
        }

        if (newTopic_IMG1.files[2] != null && newTopic_IMG1.files[2] != undefined) {
            let postIMG3 = await uploadFile(newTopic_IMG1.files[2]);
            routeFile3 = `${API_URL}${postIMG3.filePath}`
        }



        // Datos para crear un array y despues la funcion de crear el topic
        const newArrayTopic = {'topic': newTopic_topic.value.trim(),
                                'message': newTopic_message.value.trim(),
                                'img': [routeFile1, routeFile2, routeFile3],
                                'dateString': await dateFormat(Date.now()),
                                'dateStringUpdate': await dateFormat(Date.now()),
                                'response': [],
                                'userResponse': [tokenDecode.data.id],
                                'username': {'username': tokenDecode.data.username, 'ID': tokenDecode.data.id, 'IMG': tokenDecode.data.img}
        }


        if (newTopic_topic.value.trim().length > 5 && newTopic_message.value.trim().length > 10) { 
                               
                const createNewTopicAPI = await forumCreate(newArrayTopic)

                // Condicional para validar si se ha guardado correctamente
                if (createNewTopicAPI.data._id.length != undefined && createNewTopicAPI.data._id.length > 10) {

                        Swal.fire({
                            text: "El nuevo tema se ha creado correctamente",
                            icon: "success"
                        })

                        // Vaciar todos los campos y ocultarlos, despues recargar para ver el nuevo tema en la lista
                        newTopic_topic.value = ''
                        newTopic_message.value = ''
                        newTopic_IMG1.value = ''

                        setShowForm(false)
                        setReboot(true)

                } else {
                        Swal.fire({
                            text: "Ha habido un error, recargue la pagina y vuelva a intentarlo",
                            icon: "error"
                        })
                }
                

        } else {
                Swal.fire({
                    text: "No se permite dejar los campos vacios",
                    icon: "warning"
                })
        }

    }

        










    return (
        
        <div id="forumPage-body">
            
            <Navbar />

            <div id="forumPage-buttons">
                <input type="button" value="Buscar tema" id='forumPage-findTopic' className='forumPage-button' onClick={findTopic} />
                <input type="button" value="Crear nuevo tema" id='forumPage-newTopic' className='forumPage-button' onClick={newTopic} />
            </div>

            <div id="forumPage-inputs">
                { showFind ? <input type="text" id="forumPage-inputFind" placeholder='Escriba aqui su busqueda...' onChange={findTopicByName} /> : <></> }

                { showForm ? <input type="text" id="forumPage-inputNewTopic" className='forumPage-inputForm' placeholder='Nombre del tema nuevo' /> : <></> }
                { showForm ? <textarea type="text" id="forumPage-inputNewMessage" className='forumPage-inputForm' placeholder='Escriba aqui su mensaje nuevo...' rows={6} /> : <></> }
                { showForm ? <p id='forumPage-rotuloIMG'>Puede seleccionar hasta un maximo de tres imagenes</p> : <></> }
                { showForm ? <input type="file" multiple id="forumPage-inputNewIMG1" className='forumPage-inputForm' /> : <></> }

                { showForm ? <input type="button" id='forumPage-inputButton' className='forumPage-inputForm' value="Crear tema" onClick={createNewTopic} /> : <></> }
            </div>

            <p id='forumTopicMenu-rotulo'>{rotulo}</p>

            <div id="forumTopicMenu-body">
                {topicData}
            </div>


            <Footer />

        </div>

    )
}



export {
    ForumTopicsPage
}