import '../../scss/gestion/gestionResult.scss'

import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { ContextoCompartido } from '../../context'

import { ListRazasGestion } from './razas/listRazas'
import { FormRazasGestion } from './razas/formRazas'

import { ListQuizGestion } from './quiz/listQuiz'
import { FormQuizGestion } from './quiz/formQuiz'

import { ListFactGestion } from './fact/listFact'
import { FormFactGestion } from './fact/formFact'

import { ListForumGestion } from './forum/listForum'
import { FormForumGestion } from './forum/formForum'

import { FormUserGestion } from './user/formUser'
import { ListUserGestion } from './user/listUser'

import { SendEmail } from './email/sendEmail'
import { ListEmailGestion } from './email/listEmail'



function GestionResult() {
    const { gestionComponent1 } = useContext(ContextoCompartido);
    const { gestionComponent2 } = useContext(ContextoCompartido);
    
    const [selected, setSelected] = useState('')
    

    

    // useEffect para condicional y decidir cual de los componentes es el que se debe mostrar
    useEffect(() => {

        if (gestionComponent2 === '' || gestionComponent2 != '') {

            setSelected('')

            if (gestionComponent1 === 'Razas' && gestionComponent2 != '') {
                gestionComponent2 === 'Nuevo' ? setSelected(<FormRazasGestion />) : setSelected(<ListRazasGestion />)

            } else if (gestionComponent1 === 'Quiz' && gestionComponent2 != '') {
                gestionComponent2 === 'Nuevo' ? setSelected(<FormQuizGestion />) : setSelected(<ListQuizGestion />)

            } else if (gestionComponent1 === 'Foro' && gestionComponent2 != '') {
                gestionComponent2 === 'Nuevo' ? setSelected(<FormForumGestion />) : setSelected(<ListForumGestion />)

            } else if (gestionComponent1 === 'User' && gestionComponent2 != '') {
                gestionComponent2 === 'Nuevo' ? setSelected(<FormUserGestion />) : setSelected(<ListUserGestion />)

            } else if (gestionComponent1 === 'Fact' && gestionComponent2 != '') {
                gestionComponent2 === 'Nuevo' ? setSelected(<FormFactGestion />) : setSelected(<ListFactGestion />)

            } else if (gestionComponent1 === 'Email' && gestionComponent2 != '') {
                gestionComponent2 === 'Nuevo' ? setSelected(<SendEmail />) : setSelected(<ListEmailGestion />)

            } else {
                setSelected('')
            }

        }

    }, [gestionComponent2])




    return (

        <div id="gestionResult-body">

            <div id="gestionResult-data">

                {selected}

            </div>

        </div>

    )
}


export {
    GestionResult
}