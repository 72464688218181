import React, { createContext, useContext, useState } from 'react';
import { UserPanelUserData } from './componentes/userPanel/userPanel-userData';

export const ContextoCompartido = createContext();


export const ProveedorContexto = ({ children }) => {

  const [reboot, setReboot] = useState(true)
  const [gestionComponent1, setGestionComponent1] = useState('')
  const [gestionComponent2, setGestionComponent2] = useState('')
  const [forumID, setForumID] = useState('')
  const [showMenuSelected, setShowMenuSelected] = useState(<UserPanelUserData />)
  const [showUserPanelMenu, setShowUserPanelMenu] = useState('')

  

  const values = {
                  reboot, 
                  setReboot,
                  gestionComponent1, 
                  setGestionComponent1, 
                  gestionComponent2, 
                  setGestionComponent2,
                  forumID,
                  setForumID,
                  showMenuSelected,
                  setShowMenuSelected,
                  showUserPanelMenu,
                  setShowUserPanelMenu
  }

  

  return (
    <ContextoCompartido.Provider value={ values }>
      {children}
    </ContextoCompartido.Provider>
  );
};
