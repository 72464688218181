import '../../scss/userPanel/userPanel-topics.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { decodeToken, getUserByID } from '../../api/userAPI'
import { getForumByProperty, getForumByID, forumDelete, forumUpdate } from '../../api/forumAPI'
import { getNotiByProperty, notiDelete } from '../../api/notiAPI'
import { getCookie } from '../../funciones/token'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { deleteFile } from '../../funciones/deleteMulter'
import { API_URL } from '../../api/API'





function UserPanelTopics() {
    const navigate = useNavigate()

    const [reboot, setReboot] = useState(true)
    const [topicsData, setTopicsData] = useState(null)
    const [userID, setUserID] = useState('')






    // useEffect para buscar los datos de usuario con token y traer los temas creados por el usuario
    useEffect(() => {

        findUserData()
        async function findUserData() {
            // Usar el token para traer la informacion del usuario
            const token = getCookie('token')
            const tokenDecode = await decodeToken()

            const findUserByID = await getUserByID(tokenDecode.data.id)
            setUserID(findUserByID.data._id)


            // Buscar todos los temas creados por el usuario
            const getTopicsByProperty = await getForumByProperty('username.username', findUserByID.data.username)


            // Usar un metodo Map para mostrar los temas en pantalla
            const topicsMap = getTopicsByProperty.data.map(data => 
                <div id="topicsByUser-div" key={data._id}>
                    <p id='topicsByUser-div-name' onClick={() => {navigate(`/forum/${data._id}`)}} >{data.topic} ({data.response.length})</p>
                    <p id='topicsByUser-div-dateCreate'>Creado: {data.dateString}</p>
                    <p id='topicsByUser-div-dateUpdate'>Actualizado: {data.dateStringUpdate}</p>
                    <div id="topicsByUser-div-buttons">
                        <input type="button" value="Eliminar" id='topicsByUser-div-buttonDel' onClick={(e) => {e.stopPropagation(); removeTopic(`${data._id}`); }} />
                        { data.open ? <input type="button" value="Cerrar" id='topicsByUser-div-buttonDel' onClick={async (e) => {e.stopPropagation(); const closeTopic = await forumUpdate(`${data._id}`,{'open': false}); swalMessage('cerrado', data.topic); setReboot(true); }} /> : <input type="button" value="Abrir" id='topicsByUser-div-buttonOpen' onClick={async (e) => {e.stopPropagation(); const closeTopic = await forumUpdate(`${data._id}`,{'open': true}); swalMessage('abierto', data.topic); setReboot(true) }} /> }
                    </div>
                </div>
            )

            getTopicsByProperty.data.length > 0 ? setTopicsData(topicsMap) : setTopicsData('No has creado ningun tema para el foro')
            setReboot(false)
        }

    },[reboot])



    
    // Funcion para lanzar un mensaje al abrir o cerrar un tema
    async function swalMessage(state, topic) {
        Swal.fire({
            html: `El tema <b>${topic}</b> se ha ${state} correctamente`,
            icon: 'info'
        })
    }






    async function removeTopic(topicID) {
        const getTopicRemove = await getForumByID(topicID)
        const topicName = getTopicRemove.data.topic
        const topicResponses = getTopicRemove.data.response 
        
        Swal.fire({
            title: "¿Estas seguro?",
            html: `Si el tema <b>${topicName}</b> se elimina tambien se eliminaran las respuestas e imagenes<br>Esta operacion no puede ser revertida`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#3085d6",
            confirmButtonColor: "#d33",
            confirmButtonText: "Eliminar"
          }).then(async (result) => {
            if (result.isConfirmed) { // Cuando se ha eliminado

                try {
                        // Metodo Map para navegar por todas las respuestas y borrar imagenes
                        const imageDeleteMap = topicResponses.map(data => {
                            // Metodo Map para navegar por cada imagen de las respuestas
                            const imageDeleteMapIMG = data.img.map(img => {
                                if (img != '') {
                                    //let filename = img.replace("http://localhost:3017/uploads/", "");
                                    let filename = img.replace(`${API_URL}/uploads/`, "");
                                    deleteFile(filename)
                                }
                            })
                        })

                        // Metodo Map para navegar por las imagenes principales del tema
                        const imageDeleteMapPrincipal = getTopicRemove.data.img.map(img => {
                            if (img != '') {
                                //let filename = img.replace("http://localhost:3017/uploads/", "");
                                let filename = img.replace(`${API_URL}/uploads/`, "");
                                deleteFile(filename)
                            }
                        })

                        // Buscar las notificaciones asociadas y eliminarlas
                        const getNoti = await getNotiByProperty('topicID', topicID)
                        const getNotiMap = getNoti.data.map(async data => await notiDelete(data._id))

                        // Eliminar el tema por completo
                        const removeTopic = await forumDelete(topicID)

                        Swal.fire({ 
                            title: "Eliminado",
                            html: `El tema <b>${topicName}</b> ha sido eliminado correctamente`,
                            icon: "success"
                        })

                        setReboot(true)

                } catch (error) {
                        Swal.fire({ 
                            html: `Ha habido un error, recargue la pagina y vuelva a intentarlo`,
                            icon: "error"
                        })
                }

              
            } else { // Cuando se cancela la eliminacion
                Swal.fire({
                    title: "Cancelado",
                    text: "Su operacion se ha cancelado y el tema no ha sufrido cambios",
                    icon: "info"
                  })
            }
          })
    }










    return (

        <div id="userPanel-topics-body">

            <p id='userPanel-topics-rotulo'>Temas creados</p>

            <div id="userPanel-topics-list">
                {topicsData}
            </div>
        </div>

    )
}


export {
    UserPanelTopics
}