import '../../../scss/userPanel/userPanel-messageDetail.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { decodeToken } from '../../../api/userAPI'
import { dateFormat } from '../../../funciones/fecha'
import Swal from 'sweetalert2'

import { getUserByID } from '../../../api/userAPI'
import { getChatByID, getChatByProperty, chatCreate, chatUpdate } from '../../../api/chatAPI'
import { chatNotiCreate, getChatNotiByID, getChatNotiByProperty, chatNotiDelete } from '../../../api/chatNotiAPI'



function UserPanelMessageDetail({id, userid}) {
    const [reboot, setReboot] = useState(true)
    const [dataChat, setDataChat] = useState(null)
    const [chatID, setChatID] = useState('')
    
    let findChatSelectedFilter




    // useEffect para buscar si hay ya un chat activo
    useEffect(() => {

        if (reboot) {

                // Hacer condicional para que si se pasa un ID de chat solo abra chat, si es null entonces que busque y pueda crear si no existe
                if (id == null) { // No hay un ID de chats

                    findChat()
                    async function findChat() {
                        const token = await decodeToken()

                        // Buscar al usuario por el 'userid'
                        const getUserDestiny = await getUserByID(userid)

                        // Buscar por propiedad todos los chats del usuario logueado
                        const getAllChats1 = await getChatByProperty('user1', token.data.id)
                        const getAllChats2 = await getChatByProperty('user2', token.data.id)
                        let getAllChats0 = [...getAllChats1.data, ...getAllChats2.data];

                        // Buscar en todos los chats encontrados al usuario destino usando Map y condicional (No me vale un filter)
                        const getAllChatsFilter = getAllChats0.map(data => {
                            if ((data.user1 == token.data.id && data.user2 == userid) || (data.user2 == token.data.id && data.user1 == userid)) {
                                findChatSelectedFilter = data
                            }
                        })


                        // Si no hay ningun chat asociado entonces crear nuevo y si lo hay mostrar mensajes en pantalla
                        if (findChatSelectedFilter == undefined || findChatSelectedFilter == null || findChatSelectedFilter.length == 0) {
                            const createNewChat = await chatCreate({'user1': token.data.id, 
                                                                    'user1IMG': token.data.img,
                                                                    'username1': token.data.username, 
                                                                    'user2': userid, 
                                                                    'user2IMG': getUserDestiny.data.img,
                                                                    'username2': getUserDestiny.data.username,  
                                                                    'dateString': await dateFormat(Date.now()), 
                                                                    'dateUpdate': Date.now()
                            })

                            setChatID(createNewChat.data._id)
                        
                        } else {
                            setChatID(findChatSelectedFilter._id)

                            // Metodo map para sacar todos los mensajes en pantalla
                            const chatsMap = findChatSelectedFilter.message.reverse().map(data =>
                                <div id="mensaje-div" key={data.messageID} className={data.userID == token.data.id ? 'mensaje-div-end' : 'mensaje-div-start'}>
                                    <p id='mensaje-div-text' className={data.userID == token.data.id ? 'mensaje-div-p-end' : 'mensaje-div-p-start'}>{data.message}</p>
                                    <p id='mensaje-div-date' className={data.userID == token.data.id ? 'mensaje-div-p-end' : 'mensaje-div-p-start'}>{data.date}</p>
                                </div>
                            )
                            setDataChat(chatsMap)
                        }
                    }

                } else if (id.length > 10) { // Existe un ID de chats

                    setChatID(id)

                    chatExist()
                    async function chatExist() {
                        const token = await decodeToken()

                        // Traer datos del chats
                        const chatData = await getChatByID(id)

                        // Metodo map para sacar todos los mensajes en pantalla
                        const chatsMap = chatData.data.message.reverse().map(data =>
                            <div id="mensaje-div" key={data.messageID} className={data.userID == token.data.id ? 'mensaje-div-end' : 'mensaje-div-start'}>
                                <p id='mensaje-div-text' className={data.userID == token.data.id ? 'mensaje-div-p-end' : 'mensaje-div-p-start'}>{data.message}</p>
                                <p id='mensaje-div-date' className={data.userID == token.data.id ? 'mensaje-div-p-end' : 'mensaje-div-p-start'}>{data.date}</p>
                            </div>
                        )
                        setDataChat(chatsMap)
                    }

                } else { // Fallo inesperado
                    Swal.fire({
                        html: 'Ha ocurrido un fallo inesperado, recargue la pagina y vuelva a intentarlo',
                        icon: 'error'
                    })
                }

            setReboot(false) 
            
            setInterval(() => {
                setReboot(true)
            }, 1000 * 10);

        }

    }, [reboot])






    // useEffect para eliminar posibles notificaciones asociadas a este chat
    useEffect(() => {

        getChatNotiDelete()
        async function getChatNotiDelete() {
            const token = await decodeToken()

            const getChatNotiByChatID = await getChatNotiByProperty('chatID', chatID)
            const getChatNotiFilter = getChatNotiByChatID.data.filter( data => data.userDestinyID == token.data.id)

            if (getChatNotiFilter[0] != undefined) {
                const chatNotiRemove = await chatNotiDelete(getChatNotiFilter[0]._id)
            }


        }

    }, [reboot])







    // Funcion para hacer el envio del mensaje
    async function sendMessage() {
        const token = await decodeToken()
        const inputText = document.getElementById('chatFrame-form-textarea')

        const getActiveChat = await getChatByID(chatID)
        let listMessages = getActiveChat.data.message
        const numberMessages = getActiveChat.data.message.length

        const newMessageToArray = {'messageID': listMessages[numberMessages-1] != undefined ? listMessages[numberMessages-1].messageID + 1 : 1, 
                                    'userID': token.data.id,
                                    'date': await dateFormat(Date.now()),
                                    'message': inputText.value
        }

        listMessages.push(newMessageToArray)

        const newUpdateChat = {'dateUpdate': Date.now(),
                                'message': listMessages
        }

        const updateChatsBD = await chatUpdate(chatID, newUpdateChat)

        // Comprobar si existe ya una notificacion para el usuario destino
        const findChatNoti = await getChatNotiByProperty('chatID', chatID)

        const ArrayNewChatNoti = {'chatID': chatID,
            'userDestinyID': getActiveChat.data.user1 == token.data.id ? getActiveChat.data.user2 : getActiveChat.data.user1,
            'userRemiteID': token.data.id,
            'userRemiteName': token.data.username,
            'userRemiteIMG': token.data.img,
            'dateString': await dateFormat(Date.now())
        }

        // Condicional para decidir si se puede o no crear la notificacion
        if (findChatNoti.data.lenght == undefined || findChatNoti.data.length == null || findChatNoti.data.length == 0) { // Si no existe nada
            const createChatNotiVoid = await chatNotiCreate(ArrayNewChatNoti)

        } else if (findChatNoti.data.userRemiteID != token.data.id) { // Si existe pero no soy yo el creador
            const createChatNotiVoid = await chatNotiCreate(ArrayNewChatNoti)

        }

        
        inputText.value = null
        setReboot(true)

    }












    return(
        <div id="userPanelMessageDetail-body">
            
            <div id="chatFrame">


                <div id="chatFrame-form">
                    <textarea id="chatFrame-form-textarea" />
                    <input type="button" value="Enviar" id='chatFrame-form-button' onClick={sendMessage} />
                </div>


                <div id="chatFrame-messages">
                    {dataChat}
                </div>


            </div>

        </div>
    )
}



export {
    UserPanelMessageDetail
}