import '../../../scss/gestion/email/listEmail.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { getEmail } from '../../../api/emailAPI'




function ListEmailGestion() {
    const [reboot, setReboot] = useState(true)
    const [dataEmail, setDataEmail] = useState('')
    const [final, setFinal] = useState(20)
    const [total, setTotal] = useState(0)




    // useEffect para sacar todas las Email, meterlas en un map y mostrarlas en el return
    useEffect(() => {
        if(reboot) {

            getAllEmail()
            async function getAllEmail() {
                const email = await getEmail()

                // Separar la informacion en bloques de 20 para hacer scroll infinito
                setTotal(email.data.length)
                const nextItems = email.data.reverse().slice(0, final)

                const emailMap = nextItems.map((data) => 
                    <div id="emailMap-div" key={data._id}>
                        <p id='emailMap-username'>{data.dateString} - {data.username}</p>
                        <p id='emailMap-message'>{data.message}</p>
                    </div>
                )
                setDataEmail(emailMap)
                setReboot(false)
            }
        }
    }, [reboot])







    // Funcion de scroll infinito para detectar cuando llega al final de la pagina
    window.addEventListener('scroll', () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight + 800) {
            if (total > final) {
                setFinal(prevFinal => prevFinal + 20)
                setReboot(true)
            }
        }
    })











    return (

        <div id="listEmailGestion-body">

                {dataEmail}

        </div>

    )
}






export {
    ListEmailGestion
}