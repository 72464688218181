import '../../scss/search/searchForm.scss'

import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getRazaByProperty, getRaza } from '../../api/razaAPI.js'
import { SearchAdvancedForm } from './advancedForm.jsx'




function SearchForm() {
    const navigate = useNavigate()

    const [findName, setFindName] = useState(null)
    const [advancedForm, setAdvancedForm] = useState(null)




    // Funcion para que se haga la busqueda de raza por nombre cuando se vaya escribiendo
    async function findRaza(e) {
        const inputText = e.target.value
        setAdvancedForm(null)

        if (inputText === '') {
            setFindName(null)
        } else {
            const getRazaSearch = await getRazaByProperty('raza', inputText)

            // Condicional para avisar cuando no hay coincidencias con el nombre
            if (getRazaSearch.data.length > 0) {
                const getRazaMap = getRazaSearch.data.map((data) => 
                    <div id="searchResult-div" key={data._id} onClick={() => {navigate(`/raza/${data._id}`)}}>
                        <img src={data.img[0]} id='searchResult-div-img' />
                        <p id='searchResult-div-raza'>{data.raza}</p>
                    </div>
                )
                setFindName(getRazaMap)
            } else {
                setFindName('Sin coincidencias en su busqueda')
            }
            
            
        }
    }






    // Funcion para mostrar un listado de todas las razas en BBDD de una vez
    async function showAll(e) {
        const inputRazaDel = document.getElementById('searchInput-input')
        inputRazaDel.value = ''

        setAdvancedForm(null)
        setFindName(null)
        const getRazaSearch = await getRaza()
        
        // metodo map para mostrar todo en pantalla y enlace desde el div hacia la raza en si
        const getRazaMap = getRazaSearch.data.map((data) => 
            <div id="searchResult-div" key={data._id} onClick={() => {navigate(`/raza/${data._id}`)}}>
                <img src={data.img[0]} id='searchResult-div-img' />
                <p id='searchResult-div-raza'>{data.raza}</p>
            </div>
        )
        setFindName(getRazaMap)
        
    }







    // Funcion para usar el boton y que aparezca o desaparezca la busqueda avanzada
    async function advancedSearchActivate(e) {
        const inputRazaDel = document.getElementById('searchInput-input')
        inputRazaDel.value = ''
        setFindName(null)

        advancedForm === null ? setAdvancedForm(<SearchAdvancedForm />) : setAdvancedForm(null)
    }





    return(

        <div id="searchInput-body">

            <div id='searchInput-form'>

                <input type="text" placeholder='Escriba la raza a buscar...' id='searchInput-input' maxLength={30} onChange={findRaza} />

                <div id="searchInput-buttons">
                    <input type="button" value="Busqueda avanzada" className='inputSearch-button' id='inputSearch-advanced' onMouseDown={advancedSearchActivate}/>
                    <input type="button" value="Ver todas" className='inputSearch-button' id='inputSearch-button-all' onClick={showAll}/>

                </div>
                

                {advancedForm}

            </div>

            <div id="searchInput-results">

                {findName}

            </div>

        </div>

    )
}


export {
    SearchForm
}