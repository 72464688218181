import '../../../scss/gestion/quiz/formQuiz.scss'

import React from 'react'
import { getQuizByProperty, quizCreate } from '../../../api/quizAPI'
import { uploadFile } from '../../../funciones/uploadMulter'
import { dateFormat } from '../../../funciones/fecha'
import { API_URL } from '../../../api/API'
import Swal from 'sweetalert2'



function FormQuizGestion() {





    async function dataAcept(e) {
        // Datos de cabecera
        const dataInputQuiz = document.getElementById('inputQuiz')
        const dataInputDescript = document.getElementById('inputDescript')
        const dataInputIMG1 = document.getElementById('inputIMG1')
        let routeFile1 = ''

        // Datos de las preguntas
        const dataQuestion_1 = document.getElementById('question1')
        const dataResponse_11 = document.getElementById('response_11')
        const dataResponse_12 = document.getElementById('response_12')
        const dataResponse_13 = document.getElementById('response_13')
        const dataResponse_14 = document.getElementById('response_14')

        const dataQuestion_2 = document.getElementById('question2')
        const dataResponse_21 = document.getElementById('response_21')
        const dataResponse_22 = document.getElementById('response_22')
        const dataResponse_23 = document.getElementById('response_23')
        const dataResponse_24 = document.getElementById('response_24')

        const dataQuestion_3 = document.getElementById('question3')
        const dataResponse_31 = document.getElementById('response_31')
        const dataResponse_32 = document.getElementById('response_32')
        const dataResponse_33 = document.getElementById('response_33')
        const dataResponse_34 = document.getElementById('response_34')

        const dataQuestion_4 = document.getElementById('question4')
        const dataResponse_41 = document.getElementById('response_41')
        const dataResponse_42 = document.getElementById('response_42')
        const dataResponse_43 = document.getElementById('response_43')
        const dataResponse_44 = document.getElementById('response_44')

        const dataQuestion_5 = document.getElementById('question5')
        const dataResponse_51 = document.getElementById('response_51')
        const dataResponse_52 = document.getElementById('response_52')
        const dataResponse_53 = document.getElementById('response_53')
        const dataResponse_54 = document.getElementById('response_54')

        // Datos de los resultados
        const dataResult_1 = document.getElementById('result1')
        const descriptResult_1 = document.getElementById('result1_descript')
        const IDResult_1 = document.getElementById('result1_raza')

        const dataResult_2 = document.getElementById('result2')
        const descriptResult_2 = document.getElementById('result2_descript')
        const IDResult_2 = document.getElementById('result2_raza')

        const dataResult_3 = document.getElementById('result3')
        const descriptResult_3 = document.getElementById('result3_descript')
        const IDResult_3 = document.getElementById('result3_raza')

        const dataResult_4 = document.getElementById('result4')
        const descriptResult_4 = document.getElementById('result4_descript')
        const IDResult_4 = document.getElementById('result4_raza')




        // Condicionales para evitar que si no hay una imagen cargada no haga nada con multer
        if (dataInputIMG1.files[0] != null && dataInputIMG1.files[0] != undefined) {
            let postIMG1 = await uploadFile(dataInputIMG1.files[0]);
            routeFile1 = `${API_URL}${postIMG1.filePath}`
        }



        const arrayNewQuiz = {'title': dataInputQuiz.value,
                            'descript': dataInputDescript.value,
                            'dateString': await dateFormat(Date.now()),
                            'img': routeFile1,
                            'username': {'username': 'Nombre de usuario', 'ID': 'ID de usuario', 'IMG': 'IMG del usuario'},
                            'questions': [[dataQuestion_1.value, dataResponse_11.value, dataResponse_12.value, dataResponse_13.value, dataResponse_14.value], 
                                        [dataQuestion_2.value, dataResponse_21.value, dataResponse_22.value, dataResponse_23.value, dataResponse_24.value], 
                                        [dataQuestion_3.value, dataResponse_31.value, dataResponse_32.value, dataResponse_33.value, dataResponse_34.value], 
                                        [dataQuestion_4.value, dataResponse_41.value, dataResponse_42.value, dataResponse_43.value, dataResponse_44.value],
                                        [dataQuestion_5.value, dataResponse_51.value, dataResponse_52.value, dataResponse_53.value, dataResponse_54.value]],
                            'results': [[dataResult_1.value, descriptResult_1.value, IDResult_1.value], 
                                        [dataResult_2.value, descriptResult_2.value, IDResult_2.value], 
                                        [dataResult_3.value, descriptResult_3.value, IDResult_3.value], 
                                        [dataResult_4.value, descriptResult_4.value, IDResult_4.value]]
        }


        // Condicional para evitar que se guarde una quiz sin escribir todos los datos (Imagenes exentas)                            
        if (dataInputQuiz.value.length > 5 && dataInputDescript.value.length > 10 && dataQuestion_1.value.length > 8 && dataResponse_11.value.length && dataResult_1.value.length > 5 && descriptResult_1.value.length > 10 && IDResult_1.value.length > 10) {
            

            // Condicional para evitar que se pueda crear una nueva quiz con un nombre ya utilizado
            const findNameQuiz = await getQuizByProperty('title', dataInputQuiz.value)
            const quizSome = findNameQuiz.data.some( data => data.quiz === dataInputQuiz.value)
            
            if (!quizSome) {
                const newDataQuiz = await quizCreate(arrayNewQuiz)
                
                // Condicional para que depende de como se ha creado el registro aparezca un mensaje u otro
                if(newDataQuiz != undefined && newDataQuiz.data._id.length > 0) {
                    Swal.fire({
                        title: "Registro creado",
                        text: `La quiz ${dataInputQuiz.value} ha sido creada correctamente`,
                        icon: "success"
                    });


                    // Vaciar todos los campos para evitar crear una quiz por error
                    dataInputQuiz.value = ''
                    dataInputDescript.value = ''
                    dataInputIMG1.value = ''

                    // Datos de las preguntas
                    dataQuestion_1.value = ''
                    dataResponse_11.value = ''
                    dataResponse_12.value = ''
                    dataResponse_13.value = ''
                    dataResponse_14.value = ''

                    dataQuestion_2.value = ''
                    dataResponse_21.value = ''
                    dataResponse_22.value = ''
                    dataResponse_23.value = ''
                    dataResponse_24.value = ''

                    dataQuestion_3.value = ''
                    dataResponse_31.value = ''
                    dataResponse_32.value = ''
                    dataResponse_33.value = ''
                    dataResponse_34.value = ''

                    dataQuestion_4.value = ''
                    dataResponse_41.value = ''
                    dataResponse_42.value = ''
                    dataResponse_43.value = ''
                    dataResponse_44.value = ''

                    dataQuestion_5.value = ''
                    dataResponse_51.value = ''
                    dataResponse_52.value = ''
                    dataResponse_53.value = ''
                    dataResponse_54.value = ''

                    // Datos de los resultados
                    dataResult_1.value = ''
                    descriptResult_1.value = ''
                    IDResult_1.value = ''

                    dataResult_2.value = ''
                    descriptResult_2.value = ''
                    IDResult_2.value = ''

                    dataResult_3.value = ''
                    descriptResult_3.value = ''
                    IDResult_3.value = ''

                    dataResult_4.value = ''
                    descriptResult_4.value = ''
                    IDResult_4.value = ''




                } else {
                    Swal.fire({
                        title: "Error de registro",
                        text: `La quiz ${dataInputQuiz.value} no ha podido ser creada, revise sus datos y vuelva a intentarlo`,
                        icon: "error"
                    });
                }

            } else {
                Swal.fire({
                    title: "Quiz ya creada",
                    text: `La quiz ${dataInputQuiz.value} ya esta creada, no se puede duplicar una quiz`,
                    icon: "error"
                });
            }


        } else {
            Swal.fire({
                title: "Faltan datos",
                text: `Es necesario que rellene todos los campos para poder crear una nueva quiz`,
                icon: "error"
            });
        }
        
    }





    return (

        <div id="formQuizGestion-body">

            <div id="formQuizGestion-data">

                <input type="text" className='formQuizGestion-input' placeholder='Titulo' id="inputQuiz" />
                <textarea className='formQuizGestion-input' placeholder='Descripcion' id="inputDescript" cols={35} rows={5} />
                <input type="file" className='formQuizGestion-input' id="inputIMG1" />

                <div id="formQuizGestion-div-questions">
                    <div id="formQuizGestion-question">
                        <p id='rotulo-question'>Pregunta 1</p>
                        <textarea className="text-question" id='question1' placeholder='Escriba su pregunta aqui' rows={4} />
                        <input className='response-question' type="text" id="response_11" placeholder='Respuesta 1' />
                        <input className='response-question' type="text" id="response_12" placeholder='Respuesta 2' />
                        <input className='response-question' type="text" id="response_13" placeholder='Respuesta 3' />
                        <input className='response-question' type="text" id="response_14" placeholder='Respuesta 4' />
                    </div>

                    <div id="formQuizGestion-question">
                        <p id='rotulo-question'>Pregunta 2</p>
                        <textarea className="text-question" id='question2' placeholder='Escriba su pregunta aqui' rows={4} />
                        <input className='response-question' type="text" id="response_21" placeholder='Respuesta 1' />
                        <input className='response-question' type="text" id="response_22" placeholder='Respuesta 2' />
                        <input className='response-question' type="text" id="response_23" placeholder='Respuesta 3' />
                        <input className='response-question' type="text" id="response_24" placeholder='Respuesta 4' />
                    </div>

                    <div id="formQuizGestion-question">
                        <p id='rotulo-question'>Pregunta 3</p>
                        <textarea className="text-question" id='question3' placeholder='Escriba su pregunta aqui' rows={4} />
                        <input className='response-question' type="text" id="response_31" placeholder='Respuesta 1' />
                        <input className='response-question' type="text" id="response_32" placeholder='Respuesta 2' />
                        <input className='response-question' type="text" id="response_33" placeholder='Respuesta 3' />
                        <input className='response-question' type="text" id="response_34" placeholder='Respuesta 4' />
                    </div>

                    <div id="formQuizGestion-question">
                        <p id='rotulo-question'>Pregunta 4</p>
                        <textarea className="text-question" id='question4' placeholder='Escriba su pregunta aqui' rows={4} />
                        <input className='response-question' type="text" id="response_41" placeholder='Respuesta 1' />
                        <input className='response-question' type="text" id="response_42" placeholder='Respuesta 2' />
                        <input className='response-question' type="text" id="response_43" placeholder='Respuesta 3' />
                        <input className='response-question' type="text" id="response_44" placeholder='Respuesta 4' />
                    </div>

                    <div id="formQuizGestion-question">
                        <p id='rotulo-question'>Pregunta 5</p>
                        <textarea className="text-question" id='question5' placeholder='Escriba su pregunta aqui' rows={4} />
                        <input className='response-question' type="text" id="response_51" placeholder='Respuesta 1' />
                        <input className='response-question' type="text" id="response_52" placeholder='Respuesta 2' />
                        <input className='response-question' type="text" id="response_53" placeholder='Respuesta 3' />
                        <input className='response-question' type="text" id="response_54" placeholder='Respuesta 4' />
                    </div>
                </div>


                <div id="formQuizGestion-response">
                    <p id='rotulo-result'>Resultados</p>

                    <div className="formQuizGestion-response-result">
                        <input type="text" id="result1" className="result-question" placeholder='Resultado 1' />
                        <textarea className='result-descript' id="result1_descript" placeholder='Descripcion 1' rows={5} />
                        <input type="text" className='result-raza' id="result1_raza" placeholder='ID de la raza 1' />
                    </div>
                        
                    <div className="formQuizGestion-response-result">
                        <input type="text" id="result2" className="result-question" placeholder='Resultado 2' />
                        <textarea className='result-descript' id="result2_descript" placeholder='Descripcion 2' rows={5} />
                        <input type="text" className='result-raza' id="result2_raza" placeholder='ID de la raza 2' />
                    </div>

                    <div className="formQuizGestion-response-result">
                        <input type="text" id="result3" className="result-question" placeholder='Resultado 3' />
                        <textarea className='result-descript' id="result3_descript" placeholder='Descripcion 3' rows={5} />
                        <input type="text" className='result-raza' id="result3_raza" placeholder='ID de la raza 3' />
                    </div>

                    <div className="formQuizGestion-response-result">
                        <input type="text" id="result4" className="result-question" placeholder='Resultado 4' />
                        <textarea className='result-descript' id="result4_descript" placeholder='Descripcion 4' rows={5} />
                        <input type="text" className='result-raza' id="result4_raza" placeholder='ID de la raza 4' />
                    </div>

                </div>


                <input type="button" id='formQuizGestion-acept' value="Aceptar datos" onClick={dataAcept} />

            </div>

        </div>

    )
}



export {
    FormQuizGestion
}