import '../../scss/gestion/gestionMenu.scss'

import React from 'react'
import { useState, useContext } from 'react'
import { ContextoCompartido } from '../../context'



function GestionMenu() {
    const { setGestionComponent1 } = useContext(ContextoCompartido);
    const { setGestionComponent2 } = useContext(ContextoCompartido);

    const [inputRazas, setInputRazas] = useState('')
    const [inputQuiz, setInputQuiz] = useState('')
    const [inputForo, setInputForo] = useState('')
    const [inputUser, setInputUser] = useState('')
    const [inputFact, setInputFact] = useState('')
    const [inputEmail, setInputEmail] = useState('')




    // Funcion para poner estilos activados y para poner el formulario necesario en el componente secundario
    async function activateButton(e) {
        const buttonValue = e.target.value 
        setGestionComponent1(buttonValue)
        setGestionComponent2('')

        // Condicional para poder mantener el boton activado mientras se este usando
        if (buttonValue === 'Razas') {
            setInputRazas('active')
            setInputQuiz('')
            setInputForo('')
            setInputUser('')
            setInputFact('')
            setInputEmail('')

        } else if (buttonValue === 'Quiz') {
            setInputRazas('')
            setInputQuiz('active')
            setInputForo('')
            setInputUser('')
            setInputFact('')
            setInputEmail('')

        } else if (buttonValue === 'Foro') {
            setInputRazas('')
            setInputQuiz('')
            setInputForo('active')
            setInputUser('')
            setInputFact('')
            setInputEmail('')
            
        } else if (buttonValue === 'User') {
            setInputRazas('')
            setInputQuiz('')
            setInputForo('')
            setInputUser('active')
            setInputFact('')
            setInputEmail('')
            
        } else if (buttonValue === 'Fact') {
            setInputRazas('')
            setInputQuiz('')
            setInputForo('')
            setInputUser('')
            setInputFact('active')
            setInputEmail('')

        } else if (buttonValue === 'Email') {
            setInputRazas('')
            setInputQuiz('')
            setInputForo('')
            setInputUser('')
            setInputFact('')
            setInputEmail('active')    
            
        } else {
            setInputRazas('')
            setInputQuiz('')
            setInputForo('')
            setInputUser('')
            setInputFact('')
        }
    }




    return (

        <div id="gestionMenu-body">

            <div id="gestionMenu-buttons">

                <input type="button" id={inputRazas} value="Razas" onClick={activateButton} />
                <input type="button" id={inputQuiz} value="Quiz" onClick={activateButton} />
                <input type="button" id={inputForo} value="Foro" onClick={activateButton} />
                <input type="button" id={inputUser} value="User" onClick={activateButton} />
                <input type="button" id={inputFact} value="Fact" onClick={activateButton} />
                <input type="button" id={inputEmail} value="Email" onClick={activateButton} />

            </div>

        </div>

    )
}



export {
    GestionMenu
}