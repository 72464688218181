import dotenv from 'dotenv';
import { API_URL } from "./API.js"
import { getCookie } from '../funciones/token.js';




async function sendEmailManual(data) {
  try {
    const response = await fetch(`${API_URL}/sendemail/manual`,
      {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al enviar los email 1 (HTTP ${response.status})`);
    }

    const result = await response.json();
    console.log('email enviado:', result);
    return result
  } catch (error) {
    console.error('Error al enviar los email 2:', error);
  }
}






async function userDelByAdmin(data) {
  try {
    const response = await fetch(`${API_URL}/sendemail/delbyadmin`,
      {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al enviar los email 1 (HTTP ${response.status})`);
    }

    const result = await response.json();
    console.log('email enviado:', result);
    return result
  } catch (error) {
    console.error('Error al enviar los email 2:', error);
  }
}







async function userReactByAdmin(data) {
  try {
    const response = await fetch(`${API_URL}/sendemail/reactbyadmin`,
      {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al enviar los email 1 (HTTP ${response.status})`);
    }

    const result = await response.json();
    //console.log('email enviado:', result);
    return result
  } catch (error) {
    console.error('Error al enviar los email 2:', error);
  }
}





async function userDelByUser(data) {
  try {
    const response = await fetch(`${API_URL}/sendemail/delbyuser`,
      {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al enviar los email 1 (HTTP ${response.status})`);
    }

    const result = await response.json();
    //console.log('email enviado:', result);
    return result
  } catch (error) {
    console.error('Error al enviar los email 2:', error);
  }
}





async function userEmailValidate(data) {
  try {
    const response = await fetch(`${API_URL}/sendemail/emailvalidate`,
      {
        method: 'POST',
        headers: { 
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al enviar los email 1 (HTTP ${response.status})`);
    }

    const result = await response.json();
    //console.log('email enviado:', result);
    return result
  } catch (error) {
    console.error('Error al enviar los email 2:', error);
  }
}




async function userEmailContact(data) {
  try {
    const response = await fetch(`${API_URL}/sendemail/fromuser`,
      {
        method: 'POST',
        headers: { 
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al enviar los email 1 (HTTP ${response.status})`);
    }

    const result = await response.json();
    //console.log('email enviado:', result);
    return result
  } catch (error) {
    console.error('Error al enviar los email 2:', error);
  }
}




async function userEmailOther(data) {
  try {
    const response = await fetch(`${API_URL}/sendemail/userotheremail`,
      {
        method: 'POST',
        headers: { 
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al enviar los email 1 (HTTP ${response.status})`);
    }

    const result = await response.json();
    //console.log('email enviado:', result);
    return result
  } catch (error) {
    console.error('Error al enviar los email 2:', error);
  }
}




async function userEmailNewPass(data) {
  try {
    const response = await fetch(`${API_URL}/sendemail/usernewpass`,
      {
        method: 'POST',
        headers: { 
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al enviar los email 1 (HTTP ${response.status})`);
    }

    const result = await response.json();
    //console.log('email enviado:', result);
    return result
  } catch (error) {
    console.error('Error al enviar los email 2:', error);
  }
}





  export {
    sendEmailManual,
    userDelByAdmin,
    userReactByAdmin,
    userDelByUser,
    userEmailValidate,
    userEmailContact,
    userEmailOther,
    userEmailNewPass
  }
