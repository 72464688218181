import '../../../scss/gestion/fact/listFact.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { getFact, factDelete, factUpdate } from '../../../api/factAPI'
import Swal from 'sweetalert2'
import { Modal } from '../../modal'
import { dateFormat } from '../../../funciones/fecha'


function ListFactGestion() {
    const [reboot, setReboot] = useState(true)
    const [dataFact, setDataFact] = useState('')
    const [updateModal, setUpdateModal] = useState(null)
    const [updateFact, setUpdateFact] = useState('')
    const [final, setFinal] = useState(20)
    const [total, setTotal] = useState(0)



    // useEffect para sacar todas las Fact, meterlas en un map y mostrarlas en el return
    useEffect(() => {
        if(reboot) {

            getAllFact()
            async function getAllFact() {
                const fact = await getFact()

                // Separar la informacion en bloques de 20 para hacer scroll infinito
                setTotal(fact.data.length)
                const nextItems = fact.data.slice(0, final)

                const factMap = nextItems.map((data) => 
                    <div id="factMap-div" key={data._id}>
                        <p id='factMap-fact'>{data.fact}</p>
                        <div id="factMap-div-buttons">
                            <input className='factMap-button factMap-button-update' id={data._id} type="button" value="Modificar" onClick={ () => {factMapUpdate(data)}} />
                            <input className='factMap-button factMap-button-delete' id={data._id} type="button" value="Eliminar" onClick={factMapDelete} />
                        </div>
                    </div>
                )
                setDataFact(factMap)
                setReboot(false)
            }
        }
    }, [reboot])



    // Funcion de scroll infinito para detectar cuando llega al final de la pagina
    window.addEventListener('scroll', () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight + 800) {
            if (total > final) {
                setFinal(prevFinal => prevFinal + 20)
                setReboot(true)
            }
        }
    })



    
    // Funcion para abrir modal de actualizacion de datos
    async function factMapUpdate(data) {
        setUpdateFact(data.fact)
        setUpdateModal([data._id, data.fact])
        
    }




    // Funcion para modificar los datos de una fact
    async function updateFactButton(data, fact){

        Swal.fire({
            text: "Una vez actualizado no se podra revertir al estado anterior",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Actualizar"
          }).then(async(result) => {
            if (result.isConfirmed) {

                const sendUpdateFact = await factUpdate(data[0], {'fact': fact, 'date': Date.now(), 'dateString': await dateFormat(Date.now())})

              Swal.fire({
                title: "Actualizado",
                text: "El dato ha sido actualizado correctamente",
                icon: "success"
              }).then(result => {
                setUpdateModal(null)
                setReboot(true)
              })
            }
          })
    }






    // Funcion para eliminar una fact al completo
    async function factMapDelete(e) {
        const buttonID = e.target.id

        Swal.fire({
            title: `Se va a eliminar la fact`,
            text: "Esta accion se se puede revertir",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Eliminar"
        }).then(async (result) => {
            if (result.isConfirmed) {

                // Eliminar una fact creada
                const factDeleteByID = await factDelete(buttonID) 
                    

                Swal.fire({
                    title: "Eliminada correctamente",
                    text: `La fact ha sido eliminada`,
                    icon: "success"
                })

                setReboot(true)
            }
        })           
    }








    return (

        <div id="listFactGestion-body">

                {dataFact}


                {updateModal && 
                    <Modal isOpen={true}>

                        <div id="modalUpdate-fact">
                            <textarea type="text" id="modalUpdate-fact" value={updateFact} onChange={(e) => setUpdateFact(e.target.value)}/>
                            <input type="button" value="Actualizar" id={updateModal[0]} onClick={() => {updateFactButton(updateModal, updateFact)}} />
                            <input type="button" value="Cerrar" onClick={() => setUpdateModal(null)} />
                        </div>

                    </Modal>
                }

        </div>

    )
}



export {
    ListFactGestion
}