import '../scss/login/loginPage.scss'

import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { decodeToken } from '../api/userAPI.js';
import { Navbar } from "../componentes/navbar.jsx";
import { LoginComponent } from '../componentes/login/loginComponent.jsx';
import { RegisterComponent } from '../componentes/login/registerComponent.jsx';
import { Footer } from '../componentes/footer.jsx'






function LoginPage() {
    const navigate = useNavigate()

    const [forms, setForms] = useState(null)




    // useEffect para enviar a la parte superior de la pagina una vez que se entra en ella 
    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])




    // useEffect para que si el usuario esta logueado no pueda entrar a esta pagina y se le rediriga a userPanel
    useEffect(() => {

        validateUserLogin()
        async function validateUserLogin() {
            const tokenValidate = await decodeToken()
         
            tokenValidate.data.username == undefined ? null : navigate('/user')
        }

    }, [])





    return (
        <div id='index-cuerpo'>
            <Navbar />

            <div id="loginPage-data">

                <div id="loginPage-data-text">                
                        <p className='loginPage-data-text-c'>¡Bienvenido a nuestro rincón gatuno en la web! 😺</p>
                        <p className='loginPage-data-text-c'>Aquí, el ronroneo no solo es bienvenido, ¡es casi obligatorio!</p>
                        <p className='loginPage-data-text-j'>Al registrarte como usuario, desbloquearás una serie de beneficios diseñados especialmente para ti, amante de los felinos:</p>

                        <ul>
                            <li><b>Foro de Discusión Gatuno: </b>Únete a nuestra comunidad de locos por los gatos, donde podrás compartir tus historias, preguntar cualquier duda sobre el cuidado de tus michis y debatir sobre las últimas tendencias felinas.</li>
                            <li><b>Gestión de Razas Favoritas: </b>¿Tienes un amor especial por los Maine Coons, los Siamés o los exóticos Sphynx? ¡Agrega tus razas favoritas a tu perfil para tener toda la informacion importante siempre cerca!</li>
                            <li><b>Panel de Usuario Personalizado: </b>Accede a tu propio panel de usuario, donde podrás seguir tus temas de discusión favoritos, actualizar tu perfil y recibir notificaciones de todo lo que ocurre en la comunidad.</li>
                        </ul>

                        <p className='loginPage-data-text-j'>Regístrate ahora y únete a nuestra gran familia gatuna. ¡La comunidad te está esperando con las patas abiertas! Además, es gratis, como una caricia detrás de las orejas.</p>
                        <p className='loginPage-data-text-c'>¿Qué esperas para unirte? 🐾</p>
                        <p className='loginPage-data-text-c'>¡Haz clic en el botón de registro y empieza a maullar con nosotros!</p>
                </div>


                <div id="loginPage-data-buttons">
                    <input type="button" value="Entrar" onClick={() => {setForms(<LoginComponent />)}}/>
                    <input type="button" value="Registrarse" onClick={() => {setForms(<RegisterComponent />)}}/>
                </div>


                <div id="loginPage-data-forms">
                    {forms}
                </div>

            </div>


            <Footer />

        </div>
    )
}




export {
    LoginPage
}