import '../scss/razaDetail/razaDetailPage.scss'

import React from 'react'
import { useEffect } from 'react'
import { useParams } from "react-router-dom";
import { Navbar } from '../componentes/navbar'
import { RazaDetail } from '../componentes/razaDetail/razaDetail';
import { RazaRandom } from '../componentes/razaDetail/razaRandom';
import { Footer } from '../componentes/footer' 


function RazaDetailPage() {
    const { id } = useParams()




        // useEffect para enviar a la parte superior de la pagina una vez que se entra en ella 
        useEffect(() => {
            window.scrollTo({
                top: 0,
            })
        }, [])





    return (

        <div id="razaDetail-body">
            <Navbar />


            <div id="razaDetail-data">
                
                <RazaDetail id={id} />
                <RazaRandom />

            </div>


            <Footer />
        </div>

    )
}



export {
    RazaDetailPage
}