import '../../../scss/gestion/fact/formFact.scss'

import React from 'react'
import { factCreate } from '../../../api/factAPI'
import { dateFormat } from '../../../funciones/fecha'
import Swal from 'sweetalert2'



function FormFactGestion() {





    async function dataAcept(e) {
        const inputFact = document.getElementById('inputFact')


        const arrayNewFact = {'fact': inputFact.value,
                            'dateString': await dateFormat(Date.now()),
                            'username': {'username':'nombre de usuario', 'ID': 'id del usuario', 'IMG': 'foto del usuario'}
        }


        // Condicional para evitar que se guarde la fact escribiendo menos de 10 caracteres                            
        if (inputFact.value.length > 10) {
            
                const newDataFact = await factCreate(arrayNewFact)
                
                // Condicional para que depende de como se ha creado el registro aparezca un mensaje u otro
                if(newDataFact.data._id != undefined && newDataFact.data._id.length > 0 ) {
                    Swal.fire({
                        title: "Registro creado",
                        text: `La fact ha sido creada correctamente`,
                        icon: "success"
                    });

                    // Vaciar el campo para evitar crear una fact por error
                    inputFact.value = ''

                } else {
                    Swal.fire({
                        title: "Error de registro",
                        text: `La fact no ha podido ser creada, revise sus datos y vuelva a intentarlo`,
                        icon: "error"
                    });
                }


        } else {
            Swal.fire({
                title: "Faltan datos",
                text: `Es necesario que rellene correctamente el campo de texto (10 caracteres min)`,
                icon: "error"
            });
        }
        
    }





    return (

        <div id="formFactGestion-body">

            <div id="formFactGestion-data">

                <textarea className='formFactGestion-input' placeholder='Escriba su fact aqui...' id="inputFact" cols={35} rows={7} />

                <input type="button" id='formFactGestion-acept' value="Aceptar datos" onClick={dataAcept} />

            </div>

        </div>

    )
}



export {
    FormFactGestion
}