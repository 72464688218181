import '../../scss/login/loginRegister.scss'

import React from 'react'
import { getUserByProperty, userCreate } from '../../api/userAPI'
import { userEmailValidate, userEmailOther } from '../../api/sendEmailAPI'
import { emailCreate } from '../../api/emailAPI'
import Swal from 'sweetalert2'
import { dateFormat } from '../../funciones/fecha'
import { sha256 } from 'js-sha256';



function RegisterComponent() {





    async function sendRegister(e) {
        const inputUsername = document.getElementById('registerComponent-username')
        const inputEmail = document.getElementById('registerComponent-email')
        const inputPassword = document.getElementById('registerComponent-password')
        const inputRepeatPassword = document.getElementById('registerComponent-repeatPassword')


        // Expresiones regulares para controlar la validez de lo que se escribe en el formulario
        const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#%&_])[a-zA-Z0-9@#%&_]{8,}$/


        // Condicional para evitar que se dejen campos vacios y las contraseñas sean distintas entre ellas
        if (inputUsername.value.trim().length == 0 || inputEmail.value.trim().length == 0 || inputPassword.value.trim().length == 0 || inputRepeatPassword.value.trim().length == 0) { // Los campos no pueden estar vacios
            Swal.fire({
                html: `Debe rellenar todos los campos para poder continuar con la creacion de su usuario`,
                icon: "error"
              })

              return 

        } else if (inputPassword.value != inputRepeatPassword.value) { // Los campos de contraseñas no son iguales
            Swal.fire({
                html: `Las contraseñas no coinciden entre ellas, debe escribirlas correctamente`,
                icon: "error"
              })

              inputPassword.value = ''
              inputRepeatPassword.value = ''

              return

        } else if (inputUsername.value.trim().length < 3) { // El nombre de usuario es demasiado corto
            Swal.fire({
                html: `El nombre de usuario debe tener como minimo 4 caracteres`,
                icon: "error"
              })

              return

        } else if (!regexEmail.test(inputEmail.value)) { // El email introducido no tiene la estructura correcta
            Swal.fire({
                html: `El email usado no tiene la estructura correcta, vuelva a escribirlo`,
                icon: "error"
              })

              return

        } else if (!regexPassword.test(inputPassword.value)) { // La contraseña no tiene el formato adecuado
            Swal.fire({
                html: `La contraseña no tiene el formato adecuado, debe tener al menos 8 caracteres, una letra mayuscula, una letra minuscula, un numero y uno de los siguientes simbolos: @ # % & _`,
                icon: "error"
              })

              return
        }



        const newArrayCreateUser = {'username': inputUsername.value.trim(),
                                    'email': inputEmail.value.trim(),
                                    'password': sha256(inputPassword.value.trim()),
                                    'dateString': await dateFormat(Date.now())
        }

        const registerNewUser = await userCreate(newArrayCreateUser)



        // Condicional para cada uno de los estados posibles
        if (registerNewUser.data === 'user') { // Usuario ya en uso
            Swal.fire({
                html: `El usuario <b>${inputUsername.value.trim()}</b> ya esta en uso`,
                icon: "warning"
              })

        } else if (registerNewUser.data === 'email') { // Email ya en uso

            // Comprobar si el email en uso es de un usuario activo o eliminado
            const verificateStateUser = await getUserByProperty('email', inputEmail.value.trim())

            if (verificateStateUser.data[0].delete == true) {
                Swal.fire({
                    html: `El email <b>${inputEmail.value}</b> no es una direccion de correo aceptada en nuestros sistemas<br><br>Debe crear un usuario con otro email distinto`,
                    icon: "warning"
                })

                return
            }


            // Crear registro del email en MongoDB
            const arrayEmailDB = {'username': verificateStateUser.data.username,
                                'userID': verificateStateUser.data._id,
                                'adminname': 'System',
                                'dateString': await dateFormat(Date.now()),
                                'message': 'Intento de registro con un email ya en uso'}

            const registerEmailDB = await emailCreate(arrayEmailDB)

            // Enviar un email al correo para avisar de que pueden estar suplantando la identidad de otra persona
            const sendingEmailOther = await userEmailOther({'email': inputEmail.value.trim(), 'username':verificateStateUser.data[0].username})


            Swal.fire({
                html: `Se ha enviado a <b>${inputEmail.value.trim()}</b> un correo de verificación (Podria estar en SPAM)`,
                icon: "info"
              })

        } else { // Se ha creado el usuario

            // Se buscan los datos creados y despues se envian por email para validar
            const getCreateUser = await getUserByProperty('username', inputUsername.value.trim())

            // Condicional por si el usuario no se ha creado correctamente
            if (getCreateUser.data.length != undefined && getCreateUser.data.length != null && getCreateUser.data.length > 0) {
                const sendValidateEmail = await userEmailValidate({'email': getCreateUser.data[0].email, 'username': getCreateUser.data[0].username, 'url': getCreateUser.data[0]._id})
                
            } else {
                Swal.fire({
                    html: `El usuario <b>${inputUsername.value.trim()}</b> no ha podido ser creado por un error inesperado<br><br>Recargue la pagina o intentelo mas tarde`,
                    icon: 'error'
                })
                return
            }

            Swal.fire({
                html: `El usuario <b>${inputUsername.value.trim()}</b> ha sido creado correctamente<br><br>Se ha enviado a <b>${inputEmail.value.trim()}</b> un correo para la verificacion de su usuario (Revise la carpeta SPAM)`,
                icon: "success"
            })

            inputUsername.value = ''
            inputEmail.value = ''
            inputPassword.value = ''
            inputRepeatPassword.value = ''
        }
    }





    return (
        <div id="registerComponent-body">

            <input type="text" id="registerComponent-username" placeholder='Nombre de usuario' />
            <input type="text" id="registerComponent-email" placeholder='Correo electronico' />
            <input type="password" id="registerComponent-password" placeholder='Password' />
            <input type="password" id="registerComponent-repeatPassword" placeholder='Repetir password' />

            <input type="button" id="registerComponent-button" value='Registrarse' onClick={sendRegister} />

        </div>
    )
}



export {
    RegisterComponent
}