import '../../scss/login/loginIN.scss'

import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUserByProperty, userLogin } from '../../api/userAPI'
import { userEmailValidate, userEmailNewPass } from '../../api/sendEmailAPI'
import { emailCreate } from '../../api/emailAPI'
import { dateFormat } from '../../funciones/fecha'
import Swal from 'sweetalert2'
import { sha256 } from 'js-sha256';



function LoginComponent() {
    const navigate = useNavigate()

    const [newPass, setNewPass] = useState(false)




    async function enterLogin(e) {
        const inputEmail = document.getElementById('loginComponent-email')
        const inputPassword = document.getElementById('loginComponent-password')

        if (inputEmail.value.trim().length == 0 || inputPassword.value.trim().length == 0) {
            Swal.fire({
                html: `Debe rellenar todos los campos para poder iniciar sesion`,
                icon: 'error'
            })
            return
        }

        const arrayUserLogin = {'email': inputEmail.value.trim(),
                                'password': sha256(inputPassword.value.trim())
        }

        const enterLogin = await userLogin(arrayUserLogin)

        // Condicional para controlar cada uno de los estado de la respuesta
        if (enterLogin.data === 'incorrecto') { // Algun dato es incorrecto
            Swal.fire({
                text: "Su email y/o contraseña son incorrectos",
                icon: "error"
              })
              setNewPass(true)
        } else if (enterLogin.data === 'error') { // Algun error de datos
            Swal.fire({
                text: "Ha ocurrido un error, recargue la pagina y vuelva a intentarlo",
                icon: "error"
              })
              setNewPass(false)
        } else if (enterLogin.data === 'eliminado') { // Usuario eliminado
            Swal.fire({
                text: `El usuario ha sido eliminado y no es posible iniciar sesion`,
                icon: "error"
                })
                setNewPass(false)
        } else if (enterLogin.data === 'no validado') { // Email sin validar
            Swal.fire({
                title: "Usuario sin validar",
                text: "Su usuario necesita ser validado mediante email para poder acceder con sus credenciales",
                icon: "error",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Solicitar de nuevo"
              }).then(async(result) => {
                if (result.isConfirmed) {

                    // Se buscan los datos del usuario para enviar la validacion
                    const getUserEmailValidate = await getUserByProperty('email', inputEmail.value.trim())

                    // Se envia a DB el email enviado
                    const arrayNewSendEmail = {'username': getUserEmailValidate.data[0].username,
                                                'userID': getUserEmailValidate.data[0]._id,
                                                'adminname': 'System',
                                                'dateString': await dateFormat(Date.now()),
                                                'message': 'Se envia email para validar el usuario desde Login'}

                    const sendEmailDB = await emailCreate(arrayNewSendEmail)

                    // Aqui se programa el envio del email de validacion
                    const sendValidateEmail = await userEmailValidate({'email': getUserEmailValidate.data.email, 'username': getUserEmailValidate.data.username, 'url': getUserEmailValidate.data._id})

                  Swal.fire({
                    title: "Email enviado",
                    html: `Se ha enviado un email a <b>${inputEmail.value.trim()}</b> para poder validar su usuario`,
                    icon: "success"
                  })
                }
              })
              setNewPass(false)
        } else if (enterLogin.data.length > 25) { // Todo bien
            document.cookie = `token=${enterLogin.data}; path=/; max-age=86400; SameSite=none; Secure`;
            navigate('/user')
        }
    }




    async function newPassword() {
        const inputEmailNew = document.getElementById('loginComponent-email')

        Swal.fire({
            title: "Reestablecer contraseña",
            html: `Se enviara un email a <b>${inputEmailNew.value.trim()}</b> con las instrucciones para reestablecer su contraseña`,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Enviar"
        }).then(async (result) => {
            if (result.isConfirmed) {
                Swal.fire({
                title: "Mensaje enviado",
                text: "Revise su correo para poder reestablecer la contraseña (Podria estar en SPAM)",
                icon: "success"
                })

                // Comprobar que el correo existe en nuestro sistema
                const getUserEmail = await getUserByProperty('email', inputEmailNew.value.trim())
                if (getUserEmail.data == undefined || getUserEmail.data == null || getUserEmail.data.length == 0) {
                    return
                }

                // Guardar datos en la tabla emailsDB
                const arrayNewPassDB = {'username': getUserEmail.data[0].username,
                                        'userID': getUserEmail.data[0]._id,
                                        'adminname': 'System',
                                        'dateString': await dateFormat(Date.now()),
                                        'message': 'Se envia email para reestablecer contraseña'}

                const sendEmailNewPassDB = await emailCreate(arrayNewPassDB)


                // Se envia el email para reestablecer la contraseña
                const sendingEmailRepass = await userEmailNewPass({'email': inputEmailNew.value.trim(),'username': getUserEmail.data[0].username, 'url': getUserEmail.data[0]._id})

                setNewPass(false)
            
            }
        })
    }








    return (
        <div id="loginComponent-body">

            <input type="text" id="loginComponent-email" placeholder='Correo Electronico' />
            <input type="password" id="loginComponent-password" placeholder='Password' />
            { newPass ? <p id='loginComponent-newPass' onClick={newPassword}>Reestablecer contraseña</p> : <></> }
            <input type="button" id='loginComponent-button' value="Entrar" onClick={enterLogin}/>

        </div>
    )
}



export {
    LoginComponent
}