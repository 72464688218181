import dotenv from 'dotenv';
import { API_URL } from "./API.js"
import { getCookie } from '../funciones/token.js';


async function getQuiz() {
  let data = await fetch(`${API_URL}/quiz`);
  data = await data.json();
  return (data)
}


async function getQuizByID(id) {
  let data = await fetch(`${API_URL}/quiz/${id}`);
  data = await data.json();
  return (data)
}


async function getQuizByProperty(property, value) {
  try {
    const post = await fetch(`${API_URL}/quiz/find`,
      {
        method: 'POST',
        headers: { 
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ property, value })
    });

    if (!post.ok) {
      throw new Error(`Error al buscar el data 1 (HTTP ${post.status})`);
    }

    const result = await post.json();
    //console.log('Data buscado:', result);
    return (result)
  } catch (error) {
    console.error('Error al buscar el data 2 :', error);
  }
}




async function quizCreate(data) {
  try {
    const response = await fetch(`${API_URL}/quiz`,
      {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al crear los quiz 1 (HTTP ${response.status})`);
    }

    const result = await response.json();
    //console.log('quiz creado:', result);
    return result
  } catch (error) {
    console.error('Error al crear los quiz 2:', error);
  }
}




  async function quizDelete(id) {
    try {
      const response = await fetch(`${API_URL}/quiz/remove/${id}`, {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`Error al eliminar la data (HTTP ${response.status})`);
      }
  
      const result = await response.json();
      //console.log('data eliminada:', result);
    } catch (error) {
      console.error('Error al eliminar la data:', error);
    }
  }



  async function quizUpdate(id, data) {
    try {
      const response = fetch(`${API_URL}/quiz/update/${id}`,
        {
          method: 'POST',
          headers: { 
            'Authorization': `${getCookie('token')}`,
            'Content-type': 'application/json',
          },
            body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        //throw new Error(`Error al actualizar 1 (HTTP ${response.status})`);
      }
  
      const result = await response.json();
      //console.log('actualizado:', result);
    } catch (error) {
      //console.error('Error al actualizar 2:', error);
    }
  }




  export {
    getQuiz,
    getQuizByID,
    getQuizByProperty,
    quizCreate,
    quizDelete,
    quizUpdate
  }
