import '../scss/topicByUser.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getForumByProperty } from '../api/forumAPI'
import { getUserByID } from '../api/userAPI'
import { Navbar } from '../componentes/navbar'
import { Footer } from '../componentes/footer' 




function TopicByUser() {
    const { id } = useParams()
    const navigate = useNavigate()

    const [reboot, setReboot] = useState(true)

    const [topicData, setTopicData] = useState(null)
    const [rotulo, setRotulo] = useState(null)

    const [final, setFinal] = useState(20)
    const [total, setTotal] = useState(0)




        // useEffect para enviar a la parte superior de la pagina una vez que se entra en ella 
        useEffect(() => {
            window.scrollTo({
                top: 0,
            })
        }, [])




    // useEffect para traer todos los temas que tenga el usuario
    useEffect(() => {

        if (reboot) {

            getTopicByUser()
            async function getTopicByUser() {
                // Traer los datos del usuario
                const getUser = await getUserByID(id)

                // Traer todos los temas por propiedad de ID
                const getTopics = await getForumByProperty('username.ID', getUser.data._id)

                // condicional para que si no hay ningun tema aparezca un rotulo avisandolo
                if (getTopics.data.length == undefined || getTopics.data.length == null || getTopics.data.length == 0) {
                    setRotulo(`${getUser.data.username} no tiene ningun tema creado`)
                } else {
                    getUser.data.delete ? setRotulo(`Temas creados por ${getUser.data.username} <p style="color:red;">(Usuario eliminado)</p>`) : setRotulo(`Temas creados por ${getUser.data.username}`)
                    setTotal(getTopics.data.length)

                    // Funcion para ordenar por fecha de actualizacion, mas nuevos primeros
                    let arrayOrdenado = ordenarPorFecha(getTopics.data)

                    function ordenarPorFecha(arr) {
                        let n = arr.length;
                        let swapped;
                    
                        do {
                            swapped = false;
                            for (let i = 0; i < n - 1; i++) {
                                let fecha1 = new Date(arr[i].dateUpdate);
                                let fecha2 = new Date(arr[i + 1].dateUpdate);
                    
                                // Comparar fechas: si la primera es menor que la segunda, intercambiarlas
                                if (fecha1 < fecha2) {
                                    let temp = arr[i];
                                    arr[i] = arr[i + 1];
                                    arr[i + 1] = temp;
                                    swapped = true;
                                }
                            }
                            n--; // Reducir el rango a recorrer porque el último ya está ordenado
                        } while (swapped);
                        return arr;
                    }


                    // Usar scroll infinito para mostrar los temas en bloques de 20
                    const nextItems = arrayOrdenado.slice(0, final)
                    

                    // Metodo Map para sacar todo por pantalla
                    const getTopicsMap = nextItems.map(data => 
                        <div id="getTopicsByUser-div" key={data._id} onClick={() => {navigate(`/forum/${data._id}`)}}>
                            <p id='getTopicByUser-div-update'>{data.dateStringUpdate}</p>
                            <p id='getTopicByUser-div-topic'>- {data.topic} /</p>
                        </div>
                    )
                    setTopicData(getTopicsMap)
                }

            }
            setReboot(false)
        }

    }, [reboot])







    window.addEventListener('scroll', () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight + 800) {
            if (total > final) {
                setFinal(prevFinal => prevFinal + 20)
                setReboot(true)
            }
        }
    })








    return (

        <div id="topicUser-body">

            <Navbar />

            <div id='topicUser-data'>
                    <p id='topicUser-data-rotulo'>{rotulo}</p>
                    {topicData}
            </div>

            <Footer />

        </div>

    )
}




export {
    TopicByUser
}