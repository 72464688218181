import '../../scss/razaDetail/razaRandom.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getRazaRandom } from '../../api/razaAPI'



function RazaRandom() {
    const navigate = useNavigate()

    const [dataRandom, setDataRandom] = useState(null)




    // useEffect para hacer llamada a Razas Random
    useEffect(() => {
        setDataRandom(null)

        razasRandom()
        async function razasRandom() {
            const getRandom = await getRazaRandom()
            
            const getRandomMap = getRandom.data.map((data) => 
                <div id="getRandom-div" key={data._id}>
                    <img className='getRandom-div-img' src={data.img[0]} onClick={() => {navigate(`/raza/${data._id}`); toUp();} }/>
                </div>
            )
            setDataRandom(getRandomMap)
        }
    }, [])


    // Funcion para volver a la parte superior de la pagina al pulsar una raza random
    function toUp() {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Opcional: para un desplazamiento suave
            })            
        }, 150);
    }




    return (

        <div id="razaRandom-body">

            <p id='razaRandom-rotulo'> Otras razas que te podrian interesar </p>

            <div id="razaRandom-list">
                {dataRandom}
            </div>            

        </div>

    )
}


export {
    RazaRandom
}