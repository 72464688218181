import '../../scss/userPanel/userPanel-userData.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { decodeToken, getUserByID, userUpdate, getUserByProperty } from '../../api/userAPI'
import { getCookie } from '../../funciones/token'
import { validImageTypes } from '../../funciones/resizeIMG'
import { uploadFileUser } from '../../funciones/uploadMulter'
import { deleteFile } from '../../funciones/deleteMulter'
import { API_URL } from '../../api/API'
import Swal from 'sweetalert2'
import sha256 from 'js-sha256'






function UserPanelUserData() {

    const [reboot, setReboot] = useState(true)
    const [userData, setUserData] = useState([])
    const [dataID, setDataID] = useState('')

    const [changeUsername, setChangeUsername] = useState('')
    const [changeEmail, setChangeEmail] = useState('')

    const [originalUsername, setOriginalUsername] = useState('')
    const [originalEmail, setOriginalEmail] = useState('')




    // Expresiones regulares para poder validar los datos nuevos que el usuario quiera cambiar
    const regexUsername = /^[A-Za-z0-9]+$/
    const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#%&_])[a-zA-Z0-9@#%&_]+$/




    
    // useEffect para buscar los datos del usuario logueado
    useEffect(() => {

        findUserData()
        async function findUserData() {
            const token = getCookie('token')
            const tokenDecode = await decodeToken()

            if (tokenDecode.data.id == null || tokenDecode.data.id == undefined) {
                return
            }

            const findUserByID = await getUserByID(tokenDecode.data.id)

            setUserData(findUserByID.data)

            setChangeUsername(findUserByID.data.username)
            setChangeEmail(findUserByID.data.email)
            setDataID(tokenDecode.data.id)

            setOriginalUsername(findUserByID.data.username)
            setOriginalEmail(findUserByID.data.email)
        }
        
        setReboot(false)
    },[reboot])





    // Funciones para poder modificar los inputs teniendo un value ya puesto
    async function newUsername(e) {
        setChangeUsername(e.target.value)
    }

    async function newEmail(e) {
        setChangeEmail(e.target.value)
    }




    // Funcion para modificar los datos del usuario
    async function userUpdateData(e) {
        const inputUsername = document.getElementById('userInput-username')
        const inputEmail = document.getElementById('userInput-email')
        const inputPassword = document.getElementById('userInput-password')
        const inputRepeatPassword = document.getElementById('userInput-RepeatPassword')

        let inputIMG = document.getElementById('userInput-img')


        // Comprobar que no se use un username o email ya en uso
        const findUser = await getUserByProperty('username', inputUsername.value)
        const findEmail = await getUserByProperty('email', inputEmail.value)

        if (originalUsername != inputUsername.value && (findUser.data.length == undefined || findUser.data.length == null || findUser.data.length > 0)) {
            Swal.fire({
                text: "Su nuevo nombre de usuario ya esta en uso",
                icon: "error"
            })
            inputPassword.value = null
            inputRepeatPassword.value = null
            return
        }

        if (originalEmail != inputEmail.value && (findEmail.data.length == undefined || findEmail.data.length == null || findEmail.data.length > 0)) {
            Swal.fire({
                text: "Su nuevo email no es valido",
                icon: "error"
            })
            inputPassword.value = null
            inputRepeatPassword.value = null
            return
        }


        // Condicional para las comprobaciones para la imagen de perfil
        if (inputIMG.files[0] != undefined && inputIMG.files[0] != null) {

            if (validImageTypes(inputIMG.files[0].type)) {
                // Condicional para que cuando no haya cargada una imagen, no ejecute la funcion para evitar errores
                if (inputIMG != undefined && inputIMG != null) {
                    // Primero eliminar la imagen anterior con un try catch por si es el primer cambio
                    let filename = userData.img.replace(`${API_URL}/uploads/`, "");
                    filename == 'none.png' ? null : deleteFile(filename)                    

                    // Crear la nueva imagen
                    inputIMG = await uploadFileUser(inputIMG.files[0], inputUsername.value)
                    inputIMG = `${API_URL}${inputIMG.filePath}`
                }

            } else {
                inputIMG.value = ''
                Swal.fire({
                    text: "Solo se pueden usar imagenes para la foto de perfil",
                    icon: "error"
                  })
                return
            }
        } else {
            inputIMG = userData.img
        }



        const arrayNewDataUser = {'username': inputUsername.value,
                                'email': inputEmail.value,
                                'password': sha256(inputPassword.value),
                                'img': inputIMG
        }



        if (inputUsername.value.length === 0 || inputEmail.value.length === 0 || inputPassword.value.length === 0 || inputRepeatPassword.value.length === 0) {
            Swal.fire({
                text: "No se pueden dejar campos vacios",
                icon: "warning"
              })

        } else if (inputUsername.value.length < 4) {
            Swal.fire({
                text: "El nombre de usuario debe contener al menos 4 caracteres",
                icon: "warning"
              })

        } else if (!regexEmail.test(inputEmail.value)) {
            Swal.fire({
                text: "El correo no tiene una estructura adecuada",
                icon: "warning"
              })

        } else if (inputPassword.value != inputRepeatPassword.value) {
            Swal.fire({
                text: "Las contraseñas no coinciden entre ellas",
                icon: "warning"
              })

        } else if (!regexPassword.test(inputPassword.value) || inputPassword.value.length < 8) {
            Swal.fire({
                text: "La contraseña no es valida, debe tener tener al menos 8 caracteres y contener al menos una letra minuscula, una letra mayuscula, un numero y uno de los siguientes simbolos @ # % & _",
                icon: "warning"
              })
        } else {
            const updateUserData = await userUpdate(dataID, arrayNewDataUser)

            Swal.fire({
                html: "Sus datos de usuario han sido actualizados correctamente<br>La pagina se reiniciara con los nuevos datos",
                icon: "success"
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload()
                }
            })
        }
    }








    return (

        <div id="userPanel-userData-body">
            <p id='userPanel-userData-rotulo'>Datos de usuario</p>

            <img src={userData.img} id='userPanel-userData-img' />

            <input type="text" id="userInput-username" value={changeUsername} onChange={newUsername} />
            <input type="text" id="userInput-email" value={changeEmail} onChange={newEmail} />
            <input type="password" id="userInput-password" placeholder='Contraseña' />
            <input type="password" id="userInput-RepeatPassword" placeholder='Repetir contraseña' />
            <input type="file" id="userInput-img" />

            <input type="button" id='userPanel-userData-button' value="Modificar datos" onClick={userUpdateData} />
        </div>

    )
}


export {
    UserPanelUserData
}