import { createBrowserRouter } from "react-router-dom";
import { LoginPage } from "./pages/loginPage.jsx";
import { HomePage } from "./pages/home.jsx";
import { SearchPage } from "./pages/search.jsx";
import { QuizPage } from "./pages/quiz.jsx";
import { QuizPageDetail } from './pages/quizDetail.jsx'
import { GestionPage } from "./pages/gestion.jsx";
import { RazaDetailPage } from './pages/razaDetailPage.jsx'
import { ForumTopicsPage } from "./pages/forumTopicsPage.jsx";
import { ForumDetailPage } from "./pages/forumDetailPage.jsx";
import { UserPanelPage } from "./pages/userPanelPage.jsx";
import { TopicByUser } from "./pages/topicByUser.jsx";
import { UserValidateEmail } from './componentes/login/validateComponent.jsx'
import { NewPass } from "./componentes/login/newPassword.jsx";
import { Contact } from "./pages/contact.jsx";



const router = createBrowserRouter([


        {
          path: "/login",
          element: <LoginPage />
        },

        {
          path: "/",
          element: <HomePage />
        },

        {
          path: "/search",
          element: <SearchPage />
        },

        {
          path: "/quiz",
          element: <QuizPage />
        },

        {
          path: "/quiz/:id",
          element: <QuizPageDetail />
        },

        {
          path: "/gestion",
          element: <GestionPage />
        },

        {
          path: "/raza/:id",
          element: <RazaDetailPage />
        },

        {
          path: "/forum",
          element: <ForumTopicsPage />
        },

        {
          path: "/forum/:id",
          element: <ForumDetailPage />
        },

        {
          path: '/user',
          element: <UserPanelPage />
        },

        {
          path: '/user/:id',
          element: <TopicByUser />
        },

        {
          path: '/validate/:id',
          element: <UserValidateEmail />
        },

        {
          path: '/repass/:id',
          element: <NewPass />
        },

        {
          path: '/contact',
          element: <Contact />
        }
    
    
  ])


export default router;
