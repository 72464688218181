import '../../../scss/gestion/forum/listForum.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { getForum, getForumByID, forumDelete, forumUpdate } from '../../../api/forumAPI'
import { getNotiByProperty, notiDelete } from '../../../api/notiAPI'
import Swal from 'sweetalert2'
import { API_URL } from '../../../api/API'
import { Modal } from '../../modal'
import { deleteFile } from '../../../funciones/deleteMulter'
import { uploadFile } from '../../../funciones/uploadMulter'
import { dateFormat } from '../../../funciones/fecha'



function ListForumGestion() {
    const [reboot, setReboot] = useState(true)
    const [dataForum, setDataForum] = useState('')

    const [final, setFinal] = useState(20)
    const [total, setTotal] = useState(0)

    const [modalTopic, setModalTopic] = useState('')
    const [modalText, setModalText] = useState('')
    const [modalID, setModalID] = useState('')
    const [modalData, setModalData] = useState(null)



    // useEffect para sacar todas las forum, meterlas en un map y mostrarlas en el return
    useEffect(() => {
        if(reboot) {

            getAllForum()
            async function getAllForum() {
                const forum = await getForum()

                // Separar la informacion en bloques de 20 para hacer scroll infinito
                setTotal(forum.data.length)
                const nextItems = forum.data.slice(0, final)

                const forumMap = nextItems.map((data) => 
                    <div id="forumMap-div" key={data._id}>
                        <p id='forumMap-name'>{data.topic}</p>
                        <div id="forumMap-div-buttons">
                            <input className='forumMap-button forumMap-button-update' id={data._id} type="button" value="Modificar" onClick={() => {forumMapUpdate(data)}} />
                            { data.open ? <input type="button" value="Cerrar" className='forumMap-button' id='forumMap-button-close' onClick={async () => { const closeTopic = await forumUpdate(`${data._id}`,{'open': false}); setReboot(true) }} /> : <input type="button" value="Abrir" className='forumMap-button' id='forumMap-button-open' onClick={async () => { const closeTopic = await forumUpdate(`${data._id}`,{'open': true}); setReboot(true) }} /> }
                            <input className='forumMap-button forumMap-button-delete' id={data._id} type="button" value="Eliminar" onClick={ForumMapDelete} />
                        </div>
                    </div>
                )
                setDataForum(forumMap)
                setReboot(false)
            }
        }
    }, [reboot])






    // Funcion de scroll infinito para detectar cuando llega al final de la pagina
    window.addEventListener('scroll', () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight + 800) {
            if (total > final) {
                setFinal(prevFinal => prevFinal + 20)
                setReboot(true)
            }
        }
    })    








    // Funcion para abrir el modal y actualizar los datos de un tema del foro (NO RESPUESTAS)
    async function forumMapUpdate(data){
        setModalID(data._id)
        setModalTopic(data.topic)
        setModalText(data.message)
        setModalData(data)
    }







    // Funcion para eliminar las fotos que haya asociadas al tema del foro
    async function deleteForumIMG() {
        setModalData(null)

        const getTopicIMG = await getForumByID(modalID)
        let updateArrayIMG = getTopicIMG.data.img


        // Ternario para avisar cuando no hay ninguna imagen para eliminar
        if (updateArrayIMG[0] == "" && updateArrayIMG[1] == "" && updateArrayIMG[2] == "") {
            Swal.fire({
                html: `El tema <b>"${getTopicIMG.data.topic}"</b> no contiene ninguna imagen`,
                icon: "warning"
            })
            return
        }


        const { value: imagen } = await Swal.fire({
            title: "Selecciona foto",
            html: `<div style="display: flex; flex-wrap: wrap; justify-content: center; gap: 10px;">
                        ${getTopicIMG.data.img[0] != "" ? `<div style="display:flex; align-items:start;"> 1 <img src="${getTopicIMG.data.img[0]}" width="90px" style="border-radius: 25px; border: 2px solid black;" /> </div>` : ''}
                        ${getTopicIMG.data.img[1] != "" ? `<div style="display:flex; align-items:start;"> 2 <img src="${getTopicIMG.data.img[1]}" width="90px" style="border-radius: 25px; border: 2px solid black;" /> </div>` : ''}
                        ${getTopicIMG.data.img[2] != "" ? `<div style="display:flex; align-items:start;"> 3 <img src="${getTopicIMG.data.img[2]}" width="90px" style="border-radius: 25px; border: 2px solid black;" /> </div>` : ''}
                    </div>`,
            input: "select",
            inputOptions: {
                ...(getTopicIMG.data.img[0] != "" && { 0: "Imagen 1" }),
                ...(getTopicIMG.data.img[1] != "" && { 1: "Imagen 2" }),
                ...(getTopicIMG.data.img[2] != "" && { 2: "Imagen 3" }),
                all: "Todas"
              },
            inputPlaceholder: "Imagen a eliminar",
            showCancelButton: true,
            inputValidator: async (value) => {
              return new Promise( async (resolve) => {

                if (value === "0") { // Eliminar primera foto
                    const filename = getTopicIMG.data.img[0].split('/').pop()
                    const removeFileIMG = await deleteFile(filename) 

                    // Eliminar url del array y actualizar el tema
                    updateArrayIMG[0] = ''
                    const arrayNew = {'img': updateArrayIMG}
                    const updateForumIMG = await forumUpdate(modalID, arrayNew)
                    
                    resolve()

                } else if (value === "1") { // Eliminar segunda foto
                    const filename = getTopicIMG.data.img[1].split('/').pop()
                    const removeFileIMG = await deleteFile(filename) 
                    
                    // Eliminar url del array y actualizar el tema
                    updateArrayIMG[1] = ''
                    const arrayNew = {'img': updateArrayIMG}
                    const updateForumIMG = await forumUpdate(modalID, arrayNew)
                    
                    resolve()                    

                } else if (value === "2") { // Eliminar tercera foto
                    const filename = getTopicIMG.data.img[2].split('/').pop()
                    const removeFileIMG = await deleteFile(filename) 
                    
                    // Eliminar url del array y actualizar el tema
                    updateArrayIMG[2] = ''
                    const arrayNew = {'img': updateArrayIMG}
                    const updateForumIMG = await forumUpdate(modalID, arrayNew)
                    
                    resolve()                    

                } else if (value === "all") { // Eliminar todas las fotos y usar ternarios para evitar que de error en el caso de que no esten las tres fotos disponibles
                    let filename1
                    let filename2
                    let filename3

                    getTopicIMG.data.img[0] != "" ? filename1 = getTopicIMG.data.img[0].split('/').pop() : null
                    getTopicIMG.data.img[1] != "" ? filename2 = getTopicIMG.data.img[1].split('/').pop() : null
                    getTopicIMG.data.img[2] != "" ? filename3 = getTopicIMG.data.img[2].split('/').pop() : null

                    let removeIMG1
                    let removeIMG2
                    let removeIMG3

                    getTopicIMG.data.img[0] != "" ? removeIMG1 = await deleteFile(filename1) : null
                    getTopicIMG.data.img[1] != "" ? removeIMG2 = await deleteFile(filename2) : null
                    getTopicIMG.data.img[2] != "" ? removeIMG3 = await deleteFile(filename3) : null

                    // Eliminar url del array y actualizar el tema
                    updateArrayIMG[0] = ''
                    updateArrayIMG[1] = ''
                    updateArrayIMG[2] = ''

                    const arrayNew = {'img': updateArrayIMG}
                    const updateForumIMG = await forumUpdate(modalID, arrayNew)
                    
                    resolve()                    

                } else { // Algun error ha dado
                    resolve("Error inesperado, recargue la pagina y vuelva a intentarlo");
                }

              })
            }
          })


          if (imagen) { // Mensaje de aviso de que ya estan eliminadas las fotos
            // Ternarios para saber que mensaje ofrecer al usuario
            imagen == "0" ? Swal.fire(`La imagen numero 1 ha sido eliminada`) : null
            imagen == "1" ? Swal.fire(`La imagen numero 2 ha sido eliminada`) : null
            imagen == "2" ? Swal.fire(`La imagen numero 3 ha sido eliminada`) : null
            imagen == "all" ? Swal.fire(`Todas las imagenes han sido eliminadas`) : null

            setReboot(true)
          }
    }











    // Funcion para actualizar los datos y fotos del foro
    async function updateForumButton() {
        const getForumIMG = await getForumByID(modalID)

        const inputModalTopic = document.getElementById('modalForum-form-topic')
        const inputModaltext = document.getElementById('modalForum-form-text')
        let inputModalIMG1 = document.getElementById('modalForum-form-img1')
        let inputModalIMG2 = document.getElementById('modalForum-form-img2')
        let inputModalIMG3 = document.getElementById('modalForum-form-img3')

        let routeFile1 = getForumIMG.data.img[0]
        let routeFile2 = getForumIMG.data.img[1]
        let routeFile3 = getForumIMG.data.img[2]

        // Condicionales para evitar que si no hay una imagen cargada no haga nada con multer
        if (inputModalIMG1.files[0] != null && inputModalIMG1.files[0] != undefined) {
            // Condicional para eliminar la anterior con multer foto en el caso de que ya exista una
            if (routeFile1 != "") {
                const filename = routeFile1.split('/').pop()
                const removeFileIMG = await deleteFile(filename)   
            }

            // Subir la nueva foto
            let postIMG1 = await uploadFile(inputModalIMG1.files[0]);
            routeFile1 = `${API_URL}${postIMG1.filePath}`
        }

        if (inputModalIMG2.files[0] != null && inputModalIMG2.files[0] != undefined) {
            // Condicional para eliminar la anterior con multer foto en el caso de que ya exista una
            if (routeFile2 != "") {
                const filename = routeFile2.split('/').pop()
                const removeFileIMG = await deleteFile(filename)   
            }

            // Subir la nueva foto            
            let postIMG2 = await uploadFile(inputModalIMG2.files[0]);
            routeFile2 = `${API_URL}${postIMG2.filePath}`
        }

        if (inputModalIMG3.files[0] != null && inputModalIMG3.files[0] != undefined) {
            // Condicional para eliminar la anterior con multer foto en el caso de que ya exista una
            if (routeFile3 != "") {
                const filename = routeFile3.split('/').pop()
                const removeFileIMG = await deleteFile(filename)   
            }

            // Subir la nueva foto            
            let postIMG3 = await uploadFile(inputModalIMG3.files[0]);
            routeFile3 = `${API_URL}${postIMG3.filePath}`
        }


        const arrayUpdateForum = {'topic': inputModalTopic.value,
                                'message': inputModaltext.value, 
                                'img': [routeFile1, routeFile2, routeFile3],
                                'dateUpdate': Date.now(),
                                'dateStringUpdate': await dateFormat(Date.now()) }

        
        // Condicional para que se pueda actualizar correctamente
        if (inputModalTopic.value == "" || inputModaltext.value == "") { // Todos los campos rellenados 
            Swal.fire({
                html: `No se pueden dejar campos vacios`,
                icon: "warning"
            })

        } else if (inputModalTopic.value.length < 10 || inputModaltext.value < 15) { // Campos demasiado cortos
            Swal.fire({
                html: `El texto de los campos es demasiado corto<br><br>Nombre del tema: min 10 caracteres<br>Mensaje del tema: min 15 caracteres`,
                icon: "warning"
            })        

        } else if (inputModalTopic.value.length > 9 || inputModaltext.value > 14) { // Todo correcto
            const updateTopicForum = await forumUpdate(modalID, arrayUpdateForum)

            Swal.fire({
                html: `El tema <b>"${getForumIMG.data.topic}"</b> ha sido actualizado correctamente`,
                icon: "success"
            })  

            setModalData(null)
            setReboot(true)

        } else { // Error inesperado
            Swal.fire({
                html: `Error inesperado, recargue la pagina y vuelvalo a intentar`,
                icon: "error"
            })  
        }
    }









    // Funcion para eliminar una forum al completo
    async function ForumMapDelete(e) {
        const buttonID = e.target.id
        const nameForum = await getForumByID(buttonID)

        // Sacar las rutas de las IMG para eliminar tambien
        let arrayIMGResponse = nameForum.data.response
        let arrayIMGTopic = nameForum.data.img
                

        Swal.fire({
            title: `Se va a eliminar el tema ${nameForum.data.topic}`,
            text: "Esta accion se se puede revertir",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Eliminar"
        }).then((result) => {
            if (result.isConfirmed) {

                // Funcion para eliminar un tema creado
                removeForum(buttonID)
                async function removeForum(buttonID) {
                    // Funcion map para eliminar las imagenes de multer y condicional para que no de error si no hay foto
                    // Doble metodo map por que hay dos profundidades de datos
                    const deleteIMGFromResponse = arrayIMGResponse.map( async (data1) => {

                        const deleteIMGFromMap = data1.img.map ( async (data2) => {
                            
                            if (data2 != '') {
                                const filename = data2.split('/').pop()
                                const removeFileIMG = await deleteFile(filename)
                            }  
                        })
                    })

                    
                    // Nueva funcion map para eliminar la imagenes principales del tema y no las de las respuestas
                    const deleteIMGFromTopic = arrayIMGTopic.map( async (data3) => {
                        if (data3 != '') {
                            const filename = data3.split('/').pop()
                            const removeFileIMG = await deleteFile(filename)
                        }  
                    })

                    // Buscar las notificaciones asociadas y eliminarlas
                    const getNoti = await getNotiByProperty('topicID', buttonID)
                    const getNotiMap = getNoti.data.map(async data => await notiDelete(data._id))

                    // Eliminar la forum de MongoBD
                    const forumDeleteByID = await forumDelete(buttonID) 
                }


                Swal.fire({
                    title: "Eliminado correctamente",
                    text: `El tema ${nameForum.data.topic} ha sido eliminado`,
                    icon: "success"
                })

                
                
            }
        })
        
        setDataForum(null)
        setReboot(true)        
    }








    return (

        <div id="listForumGestion-body">

                {dataForum}


                {modalData && 
                    <Modal isOpen={true}>

                        <div id="modalForum-body">
                            <div id="modalForum-form">
                                <input type="text" id="modalForum-form-topic" value={modalTopic} onChange={(e) => {setModalTopic(e.target.value)}} />
                                <textarea type="text" id="modalForum-form-text" value={modalText} onChange={(e) => {setModalText(e.target.value)}} rows={10}/>
                                <input type="file" className='modalForum-form-img' id="modalForum-form-img1" />
                                <input type="file" className='modalForum-form-img' id="modalForum-form-img2" />
                                <input type="file" className='modalForum-form-img' id="modalForum-form-img3" />
                            </div>

                            <div id="modalForum-buttons">
                                <input type="button" value="Actualizar" onClick={updateForumButton} />
                                <input type="button" value="Eliminar fotos" onClick={deleteForumIMG} />
                                <input type="button" value="Cerrar" onClick={() => setModalData(null)} />
                            </div>
                        </div>

                    </Modal>
                }

        </div>

    )
}



export {
    ListForumGestion
}