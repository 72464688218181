import '../../../scss/gestion/email/sendEmail.scss'

import React from 'react'
import { useState } from 'react'
import { getUserByProperty, getUserByID } from '../../../api/userAPI'
import { emailCreate } from '../../../api/emailAPI'
import { sendEmailManual } from '../../../api/sendEmailAPI'
import { dateFormat } from '../../../funciones/fecha'
import { decodeToken } from '../../../api/userAPI'
import Swal from 'sweetalert2'




function SendEmail() {
    const [userData, setUserData] = useState(null)
    const [username, setUsername] = useState('')
    const [userID, setUserID] = useState('')
    const [userEmail, setUserEmail] = useState('')






    // Funcion para buscar al usuario con el input search
    async function searchButton(e) {
        const inputSearch = document.getElementById('sendEmail-form-search')

        // Hacer que si el campo esta vacio deje el estado en null y salga de la funcion
        if (inputSearch.value.length == 0) {
            setUserData(null)
            return
        }

        // Buscar en Mongo las coincidencias
        const searchUserByName = await getUserByProperty('username', inputSearch.value)

        // Condicional para mostrar los usuarios que coinciden o un aviso de que no hay nada
        if (searchUserByName.data.length != undefined && searchUserByName.data.length > 0) { // Hay usuarios
            const searchUserMap = searchUserByName.data.map(data => 
                <div id="searchUser-div" key={data._id} onClick={() => {selectUser(data._id, data.username)}}>
                    <img id='searchUser-div-img' src={data.img} />
                    <p id='searchUser-div-username'>{data.username}</p>
                </div>
            )
            setUserData(searchUserMap)

        } else { // No hay usuarios
            setUserData('No hay coincidencias con el nombre')
        }

    }







    // Funcion para seleccionar el usuario con el buscador
    async function selectUser(id, username) {
        const inputSearch = document.getElementById('sendEmail-form-search')
        const getUser = await getUserByID(id)

        inputSearch.value = username

        setUsername(username)
        setUserID(id)
        setUserEmail(getUser.data.email)
        setUserData(null)        
    }








    // Funcion para enviar el email al usuario
    async function sendEmailUser() {
        const inputUser = document.getElementById('sendEmail-form-search')
        const inputText = document.getElementById('sendEmail-form-text')

        const token = await decodeToken()

        const arraySendEmail = {'username': username,
                                'userID': userID,
                                'adminname': token.data.username,
                                'dateString': await dateFormat(Date.now()),
                                'message': inputText.value }


            const arraySendManual = {'email': userEmail,
                                    'username': username,
                                    'message': inputText.value
            }


        // Condicionales de seguridad
        if (inputUser.value.length < 3 || inputText.value.length < 10) { // Campos vacios o demasiado cortos
            Swal.fire({
                html: 'Los campos no estan rellenados de forma correcta',
                icon: 'warning'
            })

        } else if (userID.length == undefined || userID.length < 10 ) { // No ha encontrado al usuario
            Swal.fire({
                html: 'Ha habido algun problema, recargue la pagina y vuelva a intentarlo',
                icon: 'error'
            })

        } else { // Todo correcto
            const sendDataEmailDB = await emailCreate(arraySendEmail)

            const sendingEmailManual = await sendEmailManual(arraySendManual)

            Swal.fire({
                html: 'El email ha sido enviado de forma correcta',
                icon: 'success'
            })

            inputText.value = ''
            inputUser.value = ''
            setUserID('')
            setUsername('')
        }
    }









    return (
        
        <div id="sendEmail-body">
            
            <div id="sendEmail-form">
                <input type="text" id="sendEmail-form-search" placeholder='Buscar usuario' onChange={(e) => {searchButton(e)}} />
                <div id="sendEmail-search"> {userData} </div>
                <textarea id="sendEmail-form-text" placeholder='Escriba su mensaje' />
                <input type="button" value="Enviar email" id='sendEmail-form-button' onClick={sendEmailUser} />
            </div>

        </div>

    )
}




export {
    SendEmail
}