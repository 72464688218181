import dotenv from 'dotenv';
import { API_URL } from "./API.js"
import { getCookie } from '../funciones/token.js';


async function getEmail() { 
  try {
    const post = await fetch(`${API_URL}/email`,
      {
        method: 'GET',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        }
    });

    if (!post.ok) {
      throw new Error(`Error al buscar el email 1 (HTTP ${post.status})`);
    }

    const result = await post.json();
    //console.log('Data buscado:', result);
    return (result)
  } catch (error) {
    console.error('Error al buscar el email 2 :', error);
  }
}





async function getEmailByID(id) { 
  try {
    const post = await fetch(`${API_URL}/email/${id}`,
      {
        method: 'GET',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        }
    });

    if (!post.ok) {
      throw new Error(`Error al buscar el email 1 (HTTP ${post.status})`);
    }

    const result = await post.json();
    //console.log('Data buscado:', result);
    return (result)
  } catch (error) {
    console.error('Error al buscar el email 2 :', error);
  }
}







async function getEmailByProperty(property, value) {
  try {
    const post = await fetch(`${API_URL}/email/find`,
      {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify({ property, value })
    });

    if (!post.ok) {
      throw new Error(`Error al buscar el email 1 (HTTP ${post.status})`);
    }

    const result = await post.json();
    //console.log('Data buscado:', result);
    return (result)
  } catch (error) {
    console.error('Error al buscar el email 2 :', error);
  }
}





async function emailCreate(data) {
  try {
    const response = await fetch(`${API_URL}/email`,
      {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json',
        },
          body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Error al crear los email (HTTP ${response.status})`);
    }

    const result = await response.json();
    //console.log('foro creado:', result);
    return result
  } catch (error) {
    console.error('Error al crear los email:', error);
  }
}






  async function emailDelete(id) {
    try {
      const response = await fetch(`${API_URL}/email/remove/${id}`, {
        method: 'POST',
        headers: { 
          'Authorization': `${getCookie('token')}`,
          'Content-type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`Error al eliminar la email (HTTP ${response.status})`);
      }
  
      const result = await response.json();
      //console.log('data eliminada:', result);
    } catch (error) {
      console.error('Error al eliminar la email:', error);
    }
  }



 
  

  async function emailUpdate(id, data) {
    try {
      const response = fetch(`${API_URL}/email/update/${id}`,
        {
          method: 'POST',
          headers: { 
            'Authorization': `${getCookie('token')}`,
            'Content-type': 'application/json',
          },
            body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        //throw new Error(`Error al actualizar 1 (HTTP ${await response.status})`);
      }
  
      const result = await response.json();
      //console.log('actualizado:', result);
    } catch (error) {
      //console.error('Error al actualizar 2:', error);
    }
  }




  export {
    getEmail,
    getEmailByID,
    getEmailByProperty,
    emailCreate,
    emailDelete,
    emailUpdate
  }
