import '../../scss/home/fact.scss'

import React from 'react'
import { useState, useEffect } from 'react'
import { getOneFact } from '../../api/factAPI.js'



function HomeFact() {
    const [fact, setFact] = useState(null)
    const [reboot, setReboot] = useState(true)
    



    // useEffect para hacer la llamada a la funcion de sacar dato de MongoDB
    useEffect(() => {
        if (reboot) {

            fact()
            async function fact() {
                const getFactData = await getOneFact()
                setFact(getFactData.data.fact)
            }
            // Funcion de tiempo para que cada 60 segundos saque un nuevo dato
            setInterval(() => {
                setReboot(true)
            }, 1000 * 60);
        }

        setReboot(false)
    }, [reboot])






    return (

        <div id="homeFact-body">
            <p id='homeFact-text'>{fact}</p>
        </div>

    )
}



export {
    HomeFact
}