import '../../scss/userPanel/userPanelButtonMenu.scss'

import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { ContextoCompartido } from '../../context'
import { UserPanelMenu } from './userPanelMenu'



function UserPanelButtonMenu() {
    const { showUserPanelMenu } = useContext(ContextoCompartido)
    const { setShowUserPanelMenu } = useContext(ContextoCompartido)
    

    const [userPanel, setUserPanel] = useState('')
    const [backgroundMenu, setBackgroundMenu] = useState('')
    const [rotuloButton, setRotuloButton] = useState('Abrir menu')



    

    // useEffect con un contexto dentro para ocultar el menu al seleccionar una opcion
    useEffect(() => {

        if (showUserPanelMenu) {
            userPanel != '' ? showMenu() : null
            setShowUserPanelMenu(false)
        }

    },[showUserPanelMenu])




    // Funcion para mostrar o esconder el menu
    async function showMenu() {
        userPanel != '' ? setUserPanel('') : setUserPanel(<UserPanelMenu />)
        backgroundMenu != '' ? setBackgroundMenu('') : setBackgroundMenu('showMenu')
        rotuloButton != 'Abrir menu' ? setRotuloButton('Abrir menu') : setRotuloButton('Esconder menu')
    }




    return (
        <div id="userPanelButtonMenu-body" className={backgroundMenu}>
            <div id="userPanelButtonMenu-button">

                <input type="button" value={rotuloButton} id='userPanelPage-buttonMenu' onClick={showMenu} />
                {userPanel}

            </div>
        </div>
    )
}



export {
    UserPanelButtonMenu
}